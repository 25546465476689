import playersInTourny from './dataPlayerTournys';
import confederationQualifiers from './dataConfederationQualifiers';
import teams from './dataTeams';
import matches from './dataMatches';
import qualifyingHistory from './dataQualifyingHistory';
import * as utilities from './utilities';
import players from './dataPlayers';
import clubs from './dataClubs';

/*
 * Please describe the following function here
 * @param theRound
 * @returns {string}
 */

function setAttributesFalse() {
    //set all attributes to false
    for (let theLayerIndex = 1; theLayerIndex <= settings.layerIndices.lastMainLayerIndex; theLayerIndex++) {
        //remove all attributes
        if (settings.globals.layerParameters[theLayerIndex].auxillaryAttributes) {
            for (const attribute of settings.globals.layerParameters[theLayerIndex].auxillaryAttributes) {
                document.querySelector('html').setAttribute(attribute, 'false');
            }
        }
    }
}

function getPointsForASingleTeam(theTeamID) {
    // all matches
    let wins = 0;
    let losses = 0;
    let ties = 0;
    let theMatches = settings.globals.currentMatches.filter(
        (m) => (m.teamOneID === theTeamID || m.teamTwoID === theTeamID) && m.matchCompleted
    );
    for (const aMatch of theMatches) {
        if (aMatch.teamOneID === theTeamID) {
            if (aMatch.teamOneScore > aMatch.teamTwoScore) wins++;
            else if (aMatch.teamOneScore < aMatch.teamTwoScore) losses++;
            else ties++;
        } else {
            if (aMatch.teamOneScore < aMatch.teamTwoScore) wins++;
            else if (aMatch.teamOneScore > aMatch.teamTwoScore) losses++;
            else ties++;
        }
    }
    return {
        wins: wins,
        losses: losses,
        ties: ties,
    };
}

function getRoundName(theRound) {
    let theText = '';
    if (theRound === 1.1) {
        theText = 'Group' + '&nbsp' + 'Stage,' + '&nbsp' + 'Round' + '&nbsp' + '1';
    } else if (theRound === 1.2) {
        theText = 'Group' + '&nbsp' + 'Stage,' + '&nbsp' + 'Round' + '&nbsp' + '2';
    } else if (theRound === 1.3) {
        theText = 'Group' + '&nbsp' + 'Stage,' + '&nbsp' + 'Round' + '&nbsp' + '3';
    } else if (theRound === 2) {
        theText = 'Round' + '&nbsp' + 'Of' + '&nbsp' + 'Sixteen';
    } else if (theRound === 3) {
        theText = 'Qtr-Final';
    } else if (theRound === 4) {
        theText = 'Semi-Final';
    } else if (theRound === 5) {
        theText = 'Final';
    }
    return theText;
}

/**
 * Please describe the following function here
 * @param anEntityID
 * @returns {string}
 */
function getProperEntityName(anEntityID) {
    let theReturnText = '';
    if (anEntityID === 'TBD') theReturnText = 'TBD';
    else {
        let theEntity = settings.fifaEntities.find((o) => o.ID === anEntityID);

        if (theEntity === undefined) {
            utilities.myErrorReport(
                'location 90 ' + 'unable to find fifa entity: ' + anEntityID,
                settings.misc.majorError
            );
            theReturnText = undefined;
        } else {
            if (theEntity.type === 'club') theReturnText = theEntity.displayName;
            else if (theEntity.type === 'geographic') theReturnText = theEntity.name;
        }
    }
    return theReturnText;
}

/**
 * Please describe the following function here
 * @param
 * @returns
 */
function getRegionBounds(whichLayer) {
    if (whichLayer === settings.layerIndices.MWCVideosLayerIndex) return settings.map.regionBounds.worldBounds;
    else if (whichLayer === settings.layerIndices.WWCVideosLayerIndex) return settings.map.regionBounds.worldBounds;
    else if (whichLayer === settings.layerIndices.MEuroVideosLayerIndex) return settings.map.regionBounds.europeBounds;
    else if (whichLayer === settings.layerIndices.greatMPlayerVideosLayerIndex)
        return settings.map.regionBounds.worldBounds;
    else if (whichLayer === settings.layerIndices.greatWPlayerVideosLayerIndex)
        return settings.map.regionBounds.worldBounds;
    else {
        //looking at layer within a tourny
        return settings.map.regionBounds[settings.globals.currentTourny.regionBounds];
    }
}

/**
 * Please describe the following function here
 * @param anEntityID
 * @returns {string}
 */
function getEntityCentroid(anEntityID) {
    let theEntity = settings.fifaEntities.find((o) => o.ID === anEntityID);
    let theReturn = '';
    if (theEntity === undefined) {
        utilities.myErrorReport('location 100 ' + 'unable to find fifa entity', settings.misc.majorError);
    } else {
        theReturn = [theEntity.latitude, theEntity.longitude];
    }
    return theReturn;
}

/**
 * @param theEntityID
 * find the ID of the group that the entity belongs to
 */
function getEntityGroupID(theEntityID) {
    let theTeam = settings.globals.currentTeams.find((o) => o.ID === theEntityID);
    if (theTeam === undefined) {
        return 'Z'; //as a way to indicate entity not in any group
    } else return theTeam.groupID;
}

/**
 * Please describe the following function here
 * @param tournyID
 */

async function mySynchFetch(theCall) {
    let response = await fetch(theCall);
    let theResult = await response.json();
    return theResult;
}
async function pullNecessaryAPIData() {
    let promise = null;
    let theTourny = settings.globals.currentTourny;

    if (theTourny.dataSources.matchesUseAPI) {
        promise = mySynchFetch('./phpGeneratedJSON/' + theTourny.tournyID + 'Matches.json').then((theJson) => {
            settings.globals.currentMatches = theJson[0].matches;
        });
        await promise;
    }

    if (theTourny.dataSources.clubsUseAPI) {
        if (theTourny.playerListReady) {
            promise = mySynchFetch('./phpGeneratedJSON/allClubs.json').then((theJson) => {
                settings.globals.clubs = theJson[0].clubs;
            });

            await promise;
        }
    }
    if (theTourny.dataSources.playersUseAPI) {
        if (theTourny.playerListReady) {
            promise = mySynchFetch('./phpGeneratedJSON/allPlayers.json').then((theJson) => {
                settings.globals.players = theJson[0].players;
            });

            await promise;
        }
    }

    if (theTourny.dataSources.playersInTournyUseAPI) {
        if (theTourny.playerListReady) {
            promise = mySynchFetch('./phpGeneratedJSON/' + theTourny.tournyID + 'PlayersInTourny.json').then(
                (theJson) => {
                    settings.globals.currentPlayersInTourny = theJson[0].players;
                }
            );
            await promise;
        }
    }

    if (theTourny.dataSources.teamsUseAPI) {
        promise = mySynchFetch('./phpGeneratedJSON/' + theTourny.tournyID + 'TeamIDs.json').then((theJson) => {
            settings.globals.currentTeams = theJson[0].teams;
        });
        await promise;
    }
    if (theTourny.dataSources.confederationQualifiersUseAPI) {
        promise = mySynchFetch('./phpGeneratedJSON/' + theTourny.tournyID + 'ConfederationQualifiers.json').then(
            (theJson) => {
                settings.globals.currentTeams = theJson[0].teams;
            }
        );
        await promise;
    }

    if (theTourny.dataSources.qualifyingHistoryUseAPI) {
        promise = mySynchFetch('./phpGeneratedJSON/' + theTourny.tournyID + 'Qualifying.json').then((theJson) => {
            settings.globals.currentTeams = theJson[0].teams;
        });
        await promise;
    }
}

async function pullCorrectData(tournyID) {
    settings.globals.currentTourny = settings.tournys.find((k) => k.tournyID === tournyID);
    settings.globals.currentGroups = [];
    for (const aGroupID of settings.globals.currentTourny.groupIDs) {
        let theGroup = settings.groups.find((k) => k.groupID === aGroupID);
        settings.globals.currentGroups.push(theGroup);
    }

    if (!settings.globals.currentTourny.dataSources.matchesUseAPI) {
        settings.globals.currentMatches = matches.find((k) => k.tournyID === tournyID).matches;
    }

    if (!settings.globals.currentTourny.dataSources.playersUseAPI) {
        settings.globals.players = players;
    }

    if (!settings.globals.currentTourny.dataSources.clubsUseAPI) {
        settings.globals.clubs = clubs;
    }

    if (!settings.globals.currentTourny.dataSources.playersInTournyUseAPI) {
        settings.globals.currentPlayersInTourny = playersInTourny.find((k) => k.tournyID === tournyID).players;
    }

    if (!settings.globals.currentTourny.dataSources.teamsUseAPI) {
        settings.globals.currentTeams = teams.find((k) => k.tournyID === tournyID).teams;
    }

    if (!settings.globals.currentTourny.dataSources.confederationQualifiersUseAPI) {
        settings.globals.currentConfederationQualifiers = confederationQualifiers.find(
            (k) => k.tournyID === tournyID
        ).qualifiers;
    }

    if (!settings.globals.currentTourny.dataSources.qualifyingHistoryUseAPI) {
        //only gets used for certain tournys but should never be undefined
        settings.globals.currentQualifyingHistory = qualifyingHistory.find((k) => k.tournyID === tournyID).matches;
    }

    await pullNecessaryAPIData();
    //settings.globals.currentRankings = rankings.find((k) => k.tournyID === tournyID).rankings;

    if (
        settings.globals.currentMatches === undefined ||
        settings.globals.currentTeams === undefined ||
        settings.globals.currentPlayersInTourny === undefined ||
        settings.globals.currentConfederationQualifiers === undefined ||
        settings.globals.currentQualifyingHistory === undefined ||
        settings.globals.currentTourny === undefined ||
        settings.globals.currentRankings === undefined
    ) {
        utilities.myErrorReport('location 110 ' + 'unable to find basic tourny info', settings.misc.majorError); //should never happen
    }
}

/**
 * Please describe the following function here
 */

function getTournyRankingIndex(entityID) {
    let tempArray = [];
    for (let team of settings.globals.currentTeams) {
        tempArray.push([getWorldRank(team.ID), team.ID]);
    }
    tempArray.sort((a, b) => utilities.mySort(a, b)); // sort by ranking
    let rankIndex = tempArray.findIndex((m) => m[1] === entityID);
    if (rankIndex === undefined) {
        utilities.myErrorReport(
            'location 700, error looking for team ranking index for ' + entityID,
            settings.misc.majorError
        );
        return undefined;
    } else {
        return rankIndex;
    }
}

function getWorldRank(entityID) {
    /* let theRankInfo = settings.globals.currentRankings.find((k) => k.ID === entityID);
    if (theRankInfo === undefined) {
      utilities.myErrorReport(
        'location 690, error looking for team ranking for' + entityID,
        settings.misc.majorError
      );
      return undefined;
    } else {
      return theRankInfo.worldRank;
    } */
}

function getWorldRankPoints(entityID) {
    /*   let theRankInfo = settings.globals.currentRankings.find((k) => k.ID === entityID);
    if (theRankInfo === undefined) {
      utilities.myErrorReport(
        'location 691, error looking for team ranking points for' + entityID,
        settings.misc.majorError
      );
      return undefined;
    } else {
      return theRankInfo.points;
    } */
}

/*
 * Most of the data for this site is either built or retrieved as needed.  However, the group data is an exception.  This function
 * tallies all the results for each of the groups and stores it when the tourny  is loaded.
 * @returns doesn't return anything. It just builds the table for group results.
 */
function tallyResults() {
    for (const theTeam of settings.globals.currentTeams) {
        theTeam.groupStageWins = 0;
        theTeam.groupStageLosses = 0;
        theTeam.groupStageTies = 0;
        theTeam.groupStagePoints = 0;
        theTeam.groupStageGoalsFor = 0;
        theTeam.groupStageGoalsAgainst = 0;
        theTeam.groupStageGoalDifferential = 0;
    }
    for (let k = 0; k < settings.globals.currentMatches.length; k++) {
        if (settings.globals.currentMatches[k].stage < 2) {
            // check that it is a group match
            console.log('match ID: ' + settings.globals.currentMatches[k].matchID);
            console.log('team one ID ' + settings.globals.currentMatches[k].teamOneID);
            console.log('team two ID ' + settings.globals.currentMatches[k].teamTwoID);
            let teamOne = settings.globals.currentTeams.find(
                (o) => o.ID === settings.globals.currentMatches[k].teamOneID
            );
            let teamTwo = settings.globals.currentTeams.find(
                (o) => o.ID === settings.globals.currentMatches[k].teamTwoID
            );
            if (teamOne === undefined || teamTwo === undefined)
                utilities.myErrorReport(
                    'location 120 ' +
                        'team not found when tallying group results, teamOneID: ' +
                        settings.globals.currentMatches[k].teamOneID +
                        'teamTwoID: ' +
                        settings.globals.currentMatches[k].teamTwoID,
                    settings.misc.majorError
                );
            else {
                if (settings.globals.currentMatches[k].matchCompleted) {
                    if (
                        settings.globals.currentMatches[k].teamOneScore ===
                        settings.globals.currentMatches[k].teamTwoScore
                    ) {
                        // tie
                        teamOne.groupStageTies += 1;
                        teamTwo.groupStageTies += 1;
                        teamOne.groupStagePoints += 1;
                        teamTwo.groupStagePoints += 1;
                    } else if (
                        settings.globals.currentMatches[k].teamOneScore >
                        settings.globals.currentMatches[k].teamTwoScore
                    ) {
                        // teamOne won
                        teamOne.groupStageWins += 1;
                        teamTwo.groupStageLosses += 1;
                        teamOne.groupStagePoints += 3;
                    } else {
                        // teamTwo won
                        teamOne.groupStageLosses += 1;
                        teamTwo.groupStageWins += 1;
                        teamTwo.groupStagePoints += 3;
                    }
                    teamOne.groupStageGoalsFor += settings.globals.currentMatches[k].teamOneScore;
                    teamTwo.groupStageGoalsFor += settings.globals.currentMatches[k].teamTwoScore;
                    teamOne.groupStageGoalsAgainst += settings.globals.currentMatches[k].teamTwoScore;
                    //notice the team order reverses here, intentionally
                    teamTwo.groupStageGoalsAgainst += settings.globals.currentMatches[k].teamOneScore;
                    teamOne.groupStageGoalDifferential = teamOne.groupStageGoalsFor - teamOne.groupStageGoalsAgainst;
                    teamTwo.groupStageGoalDifferential = teamTwo.groupStageGoalsFor - teamTwo.groupStageGoalsAgainst;
                }
            }
        }
    }
}

/*
 * This function determines the confederation to which the entity belongs.
 * @param entityID
 * @returns {confederationID}
 */
function getConfederationOfEntity(entityID) {
    let theFifaEntity = settings.fifaEntities.find((q) => q.ID === entityID);
    if (theFifaEntity === undefined) {
        utilities.myErrorReport('location 130 ' + ' entity not found: ' + entityID, settings.misc.majorError);
        return undefined;
    } else {
        //get the confederation
        let theConfederation = settings.confederations.find((q) => q.confederationID === theFifaEntity.confederationID);
        if (theConfederation === undefined) {
            utilities.myErrorReport(
                'location 140 ' + ' confederation not found: ' + entityID + ' ' + theFifaEntity.confederationID,
                settings.misc.majorError
            );
        }
        return theConfederation;
    }
}

/*
 * This function figures out how many players are entering, leaving or playing within each confederation to play on
 * their club team.
 * It tallys all the results for all the confederations at once and doesn't return anything.
 */

function getCorrectTimeFormat(theTime) {
    function convertToTwelveHourClock(theTime) {
        theTime = theTime.split(':'); // convert to array

        // fetch
        let hours = Number(theTime[0]);
        let minutes = Number(theTime[1]);

        // calculate
        let timeValue;

        if (hours > 0 && hours <= 12) {
            timeValue = '' + hours;
        } else if (hours > 12) {
            timeValue = '' + (hours - 12);
        } else if (hours == 0) {
            timeValue = '12';
        }

        timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes; // get minutes
        timeValue += hours >= 12 ? ' PM' : ' AM'; // get AM/PM

        // show
        return timeValue;
    }
    let returnText = theTime;
    if (theTime) {
        if (settings.globals.currentTourny.timeFormat === 'military') {
            //do nothing because the times are in JSON in military format
        } else if (settings.globals.currentTourny.timeFormat === 'USA') {
            returnText = convertToTwelveHourClock(theTime);
            returnText += ' EST';
        } else utilities.myErrorReport('location 1001 timeFormat incorrect ', settings.misc.majorError);
    }
    return returnText;
}

function tallyPlayersComingToFromConfederations() {
    let continueOn = true;
    let temp = '';
    let theClub = '';
    let playersHomeEntityID = '';
    let playersClubEntityID = '';
    let playersHomeConfederation = '';
    let playersClubConfederation = '';
    settings.globals.withinConfederationSwoops = [];
    settings.globals.leavingConfederationSwoops = [];
    settings.globals.enteringConfederationSwoops = [];
    for (const confederation of settings.confederations) {
        confederation.playersLeavingTotal = 0;
        confederation.playersEnteringTotal = 0;
        confederation.playersStayingWithinTotal = 0;
    }

    let k = 0;
    while (k < settings.globals.currentPlayersInTourny.length) {
        continueOn = true; // for each player find the entity where they compete internationally
        let homeEntityID = settings.globals.currentPlayersInTourny[k].playsForID,
            playersHomeConfederation = getConfederationOfEntity(settings.globals.currentPlayersInTourny[k].playsForID);
        if (playersHomeConfederation === undefined) {
            utilities.myErrorReport(
                'location 150 ' +
                    'players home confederation not found: ' +
                    settings.globals.currentPlayersInTourny[k].playerID,
                settings.misc.majorError
            );
            continueOn = false;
        }
        if (continueOn) {
            // continueOn to find the club of the player
            theClub = settings.globals.clubs.find(
                (p) => p.clubID === settings.globals.currentPlayersInTourny[k].clubID
            );
            if (theClub === undefined) {
                //some players might not have a club (especially women)
                continueOn = false;
            }
        }
        if (continueOn) {
            // continueOn to find the entity of the player's club
            playersClubEntityID = theClub.clubLocationID;
            playersClubConfederation = getConfederationOfEntity(theClub.clubLocationID);
            if (playersClubConfederation === undefined) {
                utilities.myErrorReport(
                    'location 170 ' + 'confederation for club not found: ' + theClub.clubLocationID,
                    settings.misc.majorError
                );
                continueOn = false;
            }
        }
        if (continueOn) {
            // tally the result
            if (playersHomeConfederation.confederationID === playersClubConfederation.confederationID) {
                playersHomeConfederation.playersStayingWithinTotal =
                    playersHomeConfederation.playersStayingWithinTotal + 1;
                if (settings.globals.currentPlayersInTourny[k].playsForID !== theClub.clubLocationID) {
                    //player plays within the same confederation but outside home country, we will add a swoopy arrow
                    temp = settings.globals.withinConfederationSwoops.find(
                        (m) =>
                            m[0] === playersHomeConfederation.confederationID &&
                            m[1] === homeEntityID &&
                            m[2] === playersClubEntityID
                    );
                    if (temp === undefined) {
                        settings.globals.withinConfederationSwoops.push([
                            playersHomeConfederation.confederationID,
                            homeEntityID,
                            playersClubEntityID,
                            1, // first entry
                        ]);
                    } else {
                        temp[3] += 1; // increment the counter
                    }
                }
            } else {
                //player plays outside his confederation, we will add a leaving swoopy arrow in the format of from  to
                //player plays outside his confederation, we will add a entering swoopy arrow in the format of from  to
                playersHomeConfederation.playersLeavingTotal = playersHomeConfederation.playersLeavingTotal + 1;
                playersClubConfederation.playersEnteringTotal = playersClubConfederation.playersEnteringTotal + 1;
                temp = settings.globals.leavingConfederationSwoops.find(
                    (m) =>
                        m[0] === playersHomeConfederation.confederationID &&
                        m[1] === homeEntityID &&
                        m[2] === playersClubEntityID
                );
                if (temp === undefined) {
                    settings.globals.leavingConfederationSwoops.push([
                        playersHomeConfederation.confederationID,
                        homeEntityID,
                        playersClubEntityID,
                        1, // first entry
                    ]);
                } else {
                    temp[3] += 1; // increment the counter
                }
                temp = settings.globals.enteringConfederationSwoops.find(
                    (m) =>
                        m[0] === playersClubConfederation.confederationID &&
                        m[1] === homeEntityID &&
                        m[2] === playersClubEntityID
                );
                if (temp === undefined) {
                    settings.globals.enteringConfederationSwoops.push([
                        playersClubConfederation.confederationID,
                        homeEntityID,
                        playersClubEntityID,
                        1, // first entry
                    ]);
                } else {
                    temp[3] += 1; // increment the counter
                }
            }
        }
        k++;
    }
}

/*
 * This function figures out how many players are entering a given entity to play on
 * a club within that entity. The number is calculated as the total number of players on the team
 * minus the number who do not enter from elsewhere.
 * @param baseEntityID is the ID of the country of interest
 * @returns {count}
 */
function countPlayersOnClubsInOtherEntities(baseEntityID) {
    let theEntityIDS = [];
    let theCount = 0;
    let thePlayers = settings.globals.currentPlayersInTourny.filter((m) => m.playsForID === baseEntityID);
    for (let k = 0; k < thePlayers.length; k++) {
        // for each player find their club
        let theClub = settings.globals.clubs.find((p) => p.clubID === thePlayers[k].clubID);
        if (theClub === undefined) {
            //some players may not have a club (especially women)
        } else {
            theEntityIDS.push(theClub.clubLocationID);
        }
    }
    let basePlayers = theEntityIDS.filter((v) => v === baseEntityID);
    if (basePlayers === undefined) {
        utilities.myErrorReport(
            'location 190 ' + 'when searching entities yielded undefined for entity: ' + baseEntityID,
            settings.misc.dataNotFoundOK
        );
    } else {
        theCount = theEntityIDS.length - basePlayers.length;
    }
    return theCount;
}

/*
 * This function figures out how many players are many players play for clubs within that entity.
 * These can be players from outside or within the entity.  There is no limit to this number. For example England typically has
 * has about 80 players.
 * @param entityID is the ID of the country of interest
 * @returns {count}
 */

function countPlayersOnClubsInEntityFromOtherEntities(entityID) {
    let theCount = 0;
    let theClubs = settings.globals.clubs.filter((p) => p.clubLocationID === entityID);
    if (theClubs === undefined) {
        utilities.myErrorReport(
            'location 200 ' + 'undefined encountered when searching for clubs in:' + entityID,
            settings.misc.majorError
        );
    } else {
        let oneClubPlayersInEnity = '';
        theCount = 0;
        for (let k = 0; k < theClubs.length; k++) {
            oneClubPlayersInEnity = settings.globals.currentPlayersInTourny.filter(
                (z) => z.clubID === theClubs[k].clubID
            );
            for (let j = 0; j < oneClubPlayersInEnity.length; j++) {
                if (
                    oneClubPlayersInEnity[j].playsForID !== entityID //don't count players from the country that play for clubs in the country
                )
                    theCount++;
            }
        }
    }
    return theCount;
}

function getProperClubName(theClub) {
    let returnText = null;
    if (theClub) {
        if (settings.globals.currentTourny.tournyID === 'MWC2014') returnText = theClub.clubName2014;
        else if (settings.globals.currentTourny.tournyID === 'MWC2018') returnText = theClub.clubName2018;
        else if (settings.globals.currentTourny.tournyID === 'MEuro2021') {
            if (theClub.clubName2021) returnText = theClub.clubName2021;
            else if (theClub.clubName2018) returnText = theClub.clubName2018;
            else returnText = theClub.clubName2014;
        } else if (settings.globals.currentTourny.tournyID === 'WWC2015') returnText = theClub.clubName;
        else if (settings.globals.currentTourny.tournyID === 'WWC2019') returnText = theClub.clubName;
        else if (settings.globals.currentTourny.tournyID === 'MWC2022') returnText = theClub.clubName;
        else if (settings.globals.currentTourny.tournyID === 'WWC2023') returnText = theClub.clubName;
    }
    return returnText;
}

function chkConfedInTourny(confedID) {
    return (
        confedID === settings.globals.currentTourny.confederationID ||
        0 === settings.globals.currentTourny.confederationID
    ); //0 means world tourny
}

function countQualifiedTeamsFromConfed(desiredID) {
    let count = 0;
    for (const aTeam of settings.globals.currentTeams) {
        let theEntity = settings.fifaEntities.find((k) => k.ID === aTeam.ID);
        if (theEntity === undefined)
            utilities.myErrorReport(
                'location 205 ' + 'undefined encountered when searching for fifa entity:' + aTeam.ID,
                settings.misc.majorError
            );
        else if (theEntity.confederationID === desiredID) count++;
    }
    return count;
}

function getProperPlayerName(thePlayer, theTeamID) {
    if (
        (theTeamID === 'BRA' &&
            (settings.globals.currentTourny.tournyID === 'WWC2019' ||
                settings.globals.currentTourny.tournyID === 'WWC2015')) ||
        (theTeamID === 'POR' && settings.globals.currentTourny.tournyID === 'MEuro2021')
    )
        //this is a hack because the brazilian women didnt have last names in their profiles nor shirtNames
        return thePlayer.firstName;
    else if (thePlayer[settings.globals.currentTourny.nameDisplayType])
        return thePlayer[settings.globals.currentTourny.nameDisplayType];
    else return thePlayer.lastName;
}

function getTeamLogoFromAPI(theTeamID) {
    return settings.fifaEntities.find((k) => k.ID === theTeamID).logoPath;
}

export {
    getRoundName,
    getProperEntityName,
    getEntityCentroid,
    getEntityGroupID,
    getProperClubName,
    getTournyRankingIndex,
    getWorldRank,
    getWorldRankPoints,
    getConfederationOfEntity,
    pullCorrectData,
    tallyResults,
    tallyPlayersComingToFromConfederations,
    countPlayersOnClubsInEntityFromOtherEntities,
    countPlayersOnClubsInOtherEntities,
    getRegionBounds,
    chkConfedInTourny,
    countQualifiedTeamsFromConfed,
    getProperPlayerName,
    getTeamLogoFromAPI,
    getCorrectTimeFormat,
    getPointsForASingleTeam,
    setAttributesFalse,
};
