/**
 * Converts a JavaScript Object Notation (JSON) string into an object.
 * Converts a JavaScript value to a JavaScript Object Notation (JSON) string.
 * @param objectToClone
 * @returns {any}
 */
function deepClone(objectToClone) {
    //doesn't work if the object to be deep cloned has functions
    return JSON.parse(JSON.stringify(objectToClone));
}

function lodashDeepClone(objectToClone) {
    const _ = require('lodash');
    return _.cloneDeep(objectToClone);
}

/**
 * Prints to error message with newline to the console with the seriousness of the error.
 * @param theText
 * @param errorType
 */
function myErrorReport(theText, errorType) {
    console.log('error: ' + theText, errorType);
}

function calculateAge(birthMonth, birthDay, birthYear, referenceMonth, referenceDay, referenceYear) {
    if (birthMonth && birthDay && birthYear) {
        let refDate = new Date(referenceYear, referenceMonth, referenceDay);
        let birthDate = new Date(birthYear, birthMonth, birthDay);
        let diff_ms = refDate.getTime() - birthDate.getTime();
        let age_dt = new Date(diff_ms);
        return Math.abs(age_dt.getUTCFullYear() - 1970); //as of 12/20 need to think why this subtraction is necessary
    }
    else {
        return null
    }
}
/**
 * Sorter.
 * @param a
 * @param b
 * @returns {sorted array}
 */
/* eslint-disable no-unused-vars */
function mySort(a, b) {
    // can sort an array with five
    if (a[0] === b[0]) {
        if (a[1] === b[1] && a.length >= 3) {
            let x = a[2];
            let y = b[2];
            if (a[2] === b[2] && a.length >= 4) {
                let x = a[3];
                let y = b[3];
                if (a[3] === b[3] && a.length >= 5) {
                    let x = a[4];
                    let y = b[4];
                    return x < y ? -1 : x > y ? 1 : 0;
                } else {
                    let x = a[3];
                    let y = b[3];
                    return x < y ? -1 : x > y ? 1 : 0;
                }
            } else {
                let x = a[2];
                let y = b[2];
                return x < y ? -1 : x > y ? 1 : 0;
            }
        } else {
            let x = a[1];
            let y = b[1];
            return x < y ? -1 : x > y ? 1 : 0;
        }
    } else {
        let x = a[0];
        let y = b[0];
        return x < y ? -1 : x > y ? 1 : 0;
    }
}
/* eslint-enable no-unused-vars */

function sortMatchesChrono(whichMatches, reverse) {
    let theEntries = [];
    let returnArray = [];
    for (let i = 0; i < whichMatches.length; i++) {
        theEntries.push([
            whichMatches[i].year,
            whichMatches[i].month,
            whichMatches[i].day,
            whichMatches[i].time,
            whichMatches[i],
        ]);
    }
    if (reverse) theEntries.sort((a, b) => mySort(b, a));
    // put into reverse chrono order
    else theEntries.sort((a, b) => mySort(a, b));
    for (let i = 0; i < theEntries.length; i++) {
        returnArray.push(theEntries[i][4]);
    }
    return returnArray;
}

export { deepClone, lodashDeepClone, myErrorReport, mySort, sortMatchesChrono, calculateAge };
