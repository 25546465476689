import * as utilities from './utilities';
import * as manageData from './manageData';
import * as htmlBuilder from './htmlBuilder';

function getGroupLayerText(entityNeedingTextID) {
    let returnText = '';
    let theGroupID = manageData.getEntityGroupID(entityNeedingTextID);
    if (theGroupID === 'Z') {
        //entity is not in tourny
        returnText = htmlBuilder.buildDidNotQualifyPopUp(manageData.getProperEntityName(entityNeedingTextID));
    } else {
        let theGroup = settings.groups.find((o) => o.groupID === theGroupID);
        if (theGroup === undefined)
            utilities.myErrorReport(
                'location 450 ' + 'search for group yielded undefined: ' + theGroupID,
                settings.misc.majorError
            );
        else {
            returnText = htmlBuilder.buildGroupPopUp(
                manageData.getProperEntityName(entityNeedingTextID),
                theGroup.groupStandings
            );
        }
    }
    return returnText;
}

function showHistoryQualifying(theEntity) {
    let theMessage = 'Did not qualify.';
    if (theEntity.statusObtained === 'Q') {
        theMessage = 'Qualified for ' + settings.globals.currentTourny.shortName + '!';
    }
    return theMessage + '<br><br>' + getQualifyingResultsForEntity(theEntity.ID);
}

function showRoundQualifying(theEntity, theConfed) {
    let theMessage = 'Did not qualify.<br>';
    if (theEntity.statusObtained === 'Q') {
        theMessage = 'Qualified for ' + settings.globals.currentTourny.shortName + '!';
    } else if (theEntity.statusObtained === 'D') {
        if (theEntity.roundReached > 0) {
            theMessage =
                'Advanced To Qualification Round ' + theEntity.roundReached + ' of ' + theConfed.maxRounds + '.';
        }
    }
    return theMessage;
}

function showBasicQualifying(theEntityID) {
    let theMessage = 'Did not qualify.';
    if ('Z' !== manageData.getEntityGroupID(theEntityID)) theMessage = 'Did qualify.';
    return theMessage;
}

/**
 * Please describe the following function here
 * @param entityID
 * @returns {string}
 */
function getQualifyingLayerText(entityID) {
    let returnText = '';
    let theMessage = '';
    let theTeamText = manageData.getProperEntityName(entityID);
    let theConfed = manageData.getConfederationOfEntity(entityID);
    if (theConfed === undefined || theTeamText === undefined) {
        utilities.myErrorReport(
            'location 460 ' +
                theTeamText +
                ' the team or the confederation is not valid when searching in confederation Qualifiers',
            settings.misc.majorError
        );
    } else {
        if (!manageData.chkConfedInTourny(theConfed.confederationID)) {
            theMessage = 'Did not qualify.';
        } else {
            let whichFunction = settings.globals.currentTourny.functionToManageQualifying;
            if (whichFunction === 'showBasicQualifying') {
                theMessage = showBasicQualifying(entityID);
            } else {
                let theEntity = settings.globals.currentConfederationQualifiers.find((o) => o.ID === entityID);
                if (theEntity === undefined) {
                    utilities.myErrorReport(
                        'location 461 ' + entityID + ' when searching in confederation Qualifiers',
                        settings.misc.majorError
                    );
                } else {
                    if (whichFunction === 'showRoundQualifying') {
                        theMessage = showRoundQualifying(theEntity, theConfed);
                    } else if (whichFunction === 'showHistoryQualifying') {
                        theMessage = showHistoryQualifying(theEntity);
                    }
                }
            }
        }
    }
    returnText = htmlBuilder.buildQualifyingPopUp(theTeamText, theConfed.shortName, theMessage);
    return returnText;
}

/**
 * Please describe the following function here
 * @param entityID
 * @param roundsPlayedByTeam
 * @returns {string}
 */
function getTextForLostInPreviousRound(entityID, roundsPlayedByTeam) {
    let theEntityName = manageData.getProperEntityName(entityID);
    let returnText = theEntityName;
    let theMatch;
    let opponentName;
    if (roundsPlayedByTeam === 1) {
        returnText = htmlBuilder.buildDidNotAdvancePopUp(theEntityName);
    } else {
        let theMessage;
        theMatch = settings.globals.currentMatches.find(
            (q) => q.stage === roundsPlayedByTeam && (q.teamOneID === entityID || q.teamTwoID === entityID)
        ); // find the game the team lost in
        if (theMatch === undefined) {
            utilities.myErrorReport(
                'location 470 ' + 'losing team, ' + entityID + ' not found for a round above group stage',
                settings.misc.dataNotFoundOK
            ); // should not happen
        } else {
            if (theMatch.teamOneID === entityID) opponentName = manageData.getProperEntityName(theMatch.teamTwoID);
            else opponentName = manageData.getProperEntityName(theMatch.teamOneID);
            if (roundsPlayedByTeam === 2) theMessage = 'Round Of Sixteen';
            else if (roundsPlayedByTeam === 3) theMessage = 'Qtr-Final';
            else if (roundsPlayedByTeam === 4) theMessage = 'Semi-Final';
        }
        returnText = htmlBuilder.buildLostInPreviousPopUp(theEntityName, theMessage, opponentName);
        returnText += getAllResultsForEntity(entityID, false, true);
    }
    return returnText;
}

/**
 * Please describe the following function here
 * @param entityID
 * @param theRoundOfInterest
 * @returns {string}
 */

function getTextForCurrentRound(entityID, theRoundOfInterest) {
    let returnText = '';
    if (theRoundOfInterest === 1) {
        let theEntityName = manageData.getProperEntityName(entityID);
        let theText;
        // if group stage simply state if the team did or did not advance
        let theTeam = settings.globals.currentTeams.find((o) => o.ID === entityID);
        if (theTeam === undefined) {
            utilities.myErrorReport('location 480 ' + 'could not find team ' + entityID, settings.misc.majorError);
        } else if (theTeam.numberOfRoundsPlayed === 1) {
            returnText = htmlBuilder.buildDidNotAdvancePopUp(theEntityName); // no need to give details here, just state their final status after round 1
        } else {
            returnText = htmlBuilder.buildDidAdvancePopUp(theEntityName); // no need to give details here, just state their final status after round 1
        }
    } else {
        //show results for that round
        let theMatch = settings.globals.currentMatches.find(
            (o) => (o.teamOneID === entityID || o.teamTwoID === entityID) && o.stage === theRoundOfInterest
        );
        if (theMatch === undefined) {
            utilities.myErrorReport('location 490 ' + 'match undefined', settings.misc.majorError);
        } else {
            if (theMatch.teamOneID === entityID) {
                returnText = getSingleResultText(theMatch, theMatch.teamOneID, true);
            } else returnText = getSingleResultText(theMatch, theMatch.teamTwoID, true);
        }
    }
   return returnText;
}

/**
 * Please describe the following function here
 * @param match
 * @param firstTeamID
 * @param highlightOpponent
 * @returns {string}
 */

function getSingleResultText(match, firstTeamID, showRoundName) {
    let firstTeamName = manageData.getProperEntityName(firstTeamID);
    let firstTeamScore = '';
    let opponentScore = '';
    let opponentID = '';
    let returnText = '';
    if (match.teamOneID === firstTeamID) {
        firstTeamScore = match.teamOneScore;
        opponentScore = match.teamTwoScore;
        opponentID = match.teamTwoID;
    } else {
        firstTeamScore = match.teamTwoScore;
        opponentScore = match.teamOneScore;
        opponentID = match.teamOneID;
    }
    let opponentName = manageData.getProperEntityName(opponentID);
    let roundName = '';
    if (showRoundName) roundName = manageData.getRoundName(match.stage);
    if (!match.matchCompleted) {
        returnText = htmlBuilder.buildUpcomingGamePopUp(
            match.year,
            match.month,
            match.day,
            match.time,
            roundName,
            firstTeamName,
            opponentName
        );
    } else {
        let theIDForVideo = null;
        if (match.youTubeID) //|| settings.globals.currentTourny.buildVideoLink) // the other videos are not reliable || match.sportMonksVideoLink 
            theIDForVideo = match.matchID;
        returnText = htmlBuilder.buildSingleMatchPopUp(
            match.year,
            match.month,
            match.day,
            match.time,
            roundName,
            firstTeamName,
            firstTeamScore,
            opponentName,
            opponentScore,
            match.penaltyKickShootout,
            theIDForVideo
        );
    }
    return returnText;
}

function getAllResultsForEntity(entityID, showCountryName, showRoundName) {
    // get the results for all the matches the entity played
    let returnText = '<a name = "scheduleAnchor"></a>';
    if ('Z' === manageData.getEntityGroupID(entityID))
        returnText = htmlBuilder.buildDidNotQualifyPopUp(manageData.getProperEntityName(entityID));
    else {
        let theMatchesFound = settings.globals.currentMatches.filter(function (theMatch) {
            return theMatch.teamOneID === entityID || theMatch.teamTwoID === entityID;
        });
        if (theMatchesFound === undefined) {
            utilities.myErrorReport(
                'location 500 ' + 'matches for a clicked country undefined' + entityID,
                settings.misc.majorError
            );
        } else if (theMatchesFound.length === 0) {
            utilities.myErrorReport(
                'location 510 ' + '0 matches for a clicked country ' + entityID,
                settings.misc.majorError
            );
        } else {
            let theResults = '<a id="hiddenScrollLink" href="#bestDate"></a>';
            theMatchesFound = utilities.sortMatchesChrono(theMatchesFound, false); //true means reverse
            let foundBestDateToDisplay = false;
            for (let i = 0; i < theMatchesFound.length; i++) {
                theResults += htmlBuilder.buildNextLine(
                    getSingleResultText(theMatchesFound[i], entityID, showRoundName)
                );
                if (!foundBestDateToDisplay) {
                    //still searching
                    if (i < theMatchesFound.length - 1) {
                        if (!theMatchesFound[i + 1].matchCompleted) {
                            //if the next in the list has already not yet been completed then show it
                            foundBestDateToDisplay = true; //stop searching
                            theResults += '<div id="bestDate"></div>'; //insert anchor to jump to
                        }
                    }
                }
            }
            if (showCountryName)
                returnText += htmlBuilder.buildFullResultsPopUp(manageData.getProperEntityName(entityID), theResults);
            //include team name
            else returnText += theResults;
        }
    }
    return returnText;
}

function getQualifyingResultsForEntity(entityID) {
    // get the results for all the matches the entity played during qualifying
    let theEntries = [];
    let theMatchesFound = settings.globals.currentQualifyingHistory.filter(function (theMatch) {
        return theMatch.teamOneID === entityID || theMatch.teamTwoID === entityID;
    });
    if (theMatchesFound === undefined) {
        utilities.myErrorReport(
            'location 501 ' + 'qualifying matches for a clicked country undefined' + entityID,
            settings.misc.majorError
        );
    } else if (theMatchesFound.length === 0) {
        utilities.myErrorReport(
            'location 511 ' + '0 matches for a clicked country ' + entityID,
            settings.misc.majorError
        );
    } else {
        let opponentName = '';
        let countryScore = '';
        let opponentScore = '';
        let countryOfInterest = manageData.getProperEntityName(entityID);
        theMatchesFound = utilities.sortMatchesChrono(theMatchesFound, true); //true means reverse
        for (let i = 0; i < theMatchesFound.length; i++) {
            // show the results for the entity that was clicked on then the opponent's
            if (theMatchesFound[i].teamOneID === entityID) {
                opponentName = manageData.getProperEntityName(theMatchesFound[i].teamTwoID);
                countryScore = theMatchesFound[i].teamOneScore;
                opponentScore = theMatchesFound[i].teamTwoScore;
            } else {
                opponentName = manageData.getProperEntityName(theMatchesFound[i].teamOneID);
                countryScore = theMatchesFound[i].teamTwoScore;
                opponentScore = theMatchesFound[i].teamOneScore;
            }
            theEntries.push([
                theMatchesFound[i].year,
                theMatchesFound[i].month,
                theMatchesFound[i].day,
                countryOfInterest,
                countryScore,
                opponentName,
                opponentScore,
            ]);
        }
    }
    theEntries.sort((a, b) => utilities.mySort(b, a)); // put into reverse chrono order
    return htmlBuilder.buildQualifyingHistoryPopUpBody(theEntries);
}

/**
 * Displays the text for results for Group Stage rounds 1,2,3 but not the resutls for the overall group stage
 * @param stageLayer
 * @param entityID
 * @returns {string}
 */
function getPreliminaryRoundText(entityID, stageLayer) {
    let returnText = '';
    let roundOfInterest = 0;
    if ('Z' === manageData.getEntityGroupID(entityID)) {
        // not in tourny
        returnText = htmlBuilder.buildDidNotQualifyPopUp(manageData.getProperEntityName(entityID));
    } else {
        if (stageLayer === settings.layerIndices.groupStageRoundOneLayerIndex) roundOfInterest = 1.1;
        else if (stageLayer === settings.layerIndices.groupStageRoundTwoLayerIndex) roundOfInterest = 1.2;
        else roundOfInterest = 1.3;
        let theMatch = settings.globals.currentMatches.find(
            (o) => (o.teamOneID === entityID || o.teamTwoID === entityID) && o.stage === roundOfInterest
        );
        if (theMatch === undefined) {
            utilities.myErrorReport('location 680 ' + 'match undefined', settings.misc.majorError);
        } else {
            if (theMatch.teamOneID === entityID) {
                returnText = getSingleResultText(theMatch, theMatch.teamOneID, true);
            } else {
                returnText = getSingleResultText(theMatch, theMatch.teamTwoID, true);
            }
        }
    }
    return returnText;
}
/**
 * Please describe the following function here
 * @param roundOfInterest
 * @param entityID
 * @returns {string}
 */
function getTextForRound(entityID, stageLayer) {
    let returnText = '';
    let roundOfInterest = stageLayer - settings.layerIndices.r32LayerIndex + 1; // r32 is round 1, r16 is round 2 and so on
    if ('Z' === manageData.getEntityGroupID(entityID)) {
        // not in tourny
        returnText = htmlBuilder.buildDidNotQualifyPopUp(manageData.getProperEntityName(entityID));
    } else {
        let theTeam = settings.globals.currentTeams.find((o) => o.ID === entityID); // should be just one result
        if (theTeam === undefined) {
            utilities.myErrorReport(
                'location 530 ' + 'team not found when doing results text for for: ' + entityID,
                settings.misc.dataNotFoundOK
            );
        } else if (theTeam.numberOfRoundsPlayed < roundOfInterest) {
            returnText = getTextForLostInPreviousRound(entityID, theTeam.numberOfRoundsPlayed);
        } else {
            // the entity  played in the round of interest, or will play in the round of Interest
            returnText = getTextForCurrentRound(entityID, roundOfInterest);
        }
    }
    return returnText;
}

/**
 * Please describe the following function here
 * @param entityNeedingTextID
 * @returns {string}
 */
function getTalentFlowFromConfederationLayerText(entityNeedingTextID) {
    return htmlBuilder.buildTalentFlowFromConfederationPopUp(
        manageData.getConfederationOfEntity(entityNeedingTextID).shortName,
        manageData.getConfederationOfEntity(entityNeedingTextID).playersLeavingTotal
    );
}

function getTalentFlowToConfederationLayerText(entityNeedingTextID) {
    return htmlBuilder.buildTalentFlowToConfederationPopUp(
        manageData.getConfederationOfEntity(entityNeedingTextID).shortName,
        manageData.getConfederationOfEntity(entityNeedingTextID).playersEnteringTotal
    );
}

/**
 * Please describe the following function here
 * @param entityNeedingTextID
 * @returns {string}
 */
function getTalentFlowToCountryLayerText(entityNeedingTextID) {
    let theCount = manageData.countPlayersOnClubsInEntityFromOtherEntities(entityNeedingTextID);
    let numOfPlayersInEntity = '';
    let nounPart = '';
    let messageA = '';
    let messageB = '';
    if (theCount === 0) {
        nounPart = 'No clubs in ';
        messageA = ' have ' + settings.globals.currentTourny.shortName;
        messageB = ' players from other countries.';

        //
    } else if (theCount === 1) {
        nounPart = 'A Club in ';
        messageA = ' has a ' + settings.globals.currentTourny.shortName;
        messageB = ' player from another country.';
    } else {
        nounPart = 'Clubs in ';
        messageA = ' have ';
        numOfPlayersInEntity = theCount + ' ';
        messageB = settings.globals.currentTourny.shortName + ' players from other countries.';
    }
    return htmlBuilder.buildTalentFlowToCountryPopUp(
        nounPart,
        manageData.getProperEntityName(entityNeedingTextID),
        messageA,
        numOfPlayersInEntity,
        messageB
    );
}

/**
 * Please describe the following function here
 * @param entityNeedingTextID
 * @returns {string}
 */
function getTalentFlowFromCountryLayerText(entityNeedingTextID) {
    let returnText = '';
    if ('Z' === manageData.getEntityGroupID(entityNeedingTextID)) {
        returnText = htmlBuilder.buildDidNotQualifyPopUp(manageData.getProperEntityName(entityNeedingTextID));
    } else {
        let playersFromEntity = settings.globals.currentPlayersInTourny.filter(
            (o) => o.playsForID === entityNeedingTextID
        );
        if (playersFromEntity === undefined) {
            utilities.myErrorReport(
                'location 550 ' + 'undefined when looking for players tourny',
                settings.misc.majorError
            );
        } else if (playersFromEntity.length === 0) {
            utilities.myErrorReport(
                'location 555 ' + 'no players found from the entity even though they are in tourny',
                settings.misc.majorError
            );
        } else {
            let playersFromElsewhere = manageData.countPlayersOnClubsInOtherEntities(entityNeedingTextID);

            let messageA = '';
            let location = '';
            let textOfElsewherePlayers = '';
            let theEntity = settings.fifaEntities.find((m) => m.ID === entityNeedingTextID);
            if (theEntity === undefined)
                utilities.myErrorReport('location 560 ' + 'fifa entity not found', settings.misc.majorError);
            else {
                if (playersFromElsewhere === 0) {
                    messageA = ' ' + settings.globals.currentTourny.shortName + ' players play for<br>clubs in ';
                    textOfElsewherePlayers = 'All';
                    location = manageData.getProperEntityName(entityNeedingTextID);
                } else {
                    messageA =
                        ' ' +
                        settings.globals.currentTourny.shortName +
                        ' players play for<br>clubs in other countries';
                    textOfElsewherePlayers = playersFromElsewhere;
                }
                returnText = htmlBuilder.buildTalentFlowFromCountryPopUp(
                    textOfElsewherePlayers,
                    theEntity.adjective,
                    messageA,
                    location
                );
            }
        }
    }
    return returnText;
}
function getScheduleLayerText(entityNeedingTextID) {
    return getAllResultsForEntity(entityNeedingTextID, true, true);
}

function getLeagueScheduleLayerText(entityNeedingTextID) {
    return getAllResultsForEntity(entityNeedingTextID, true, false);
}

function getLeagueDashboardLayerText(entityNeedingTextID) {
    let returnText = htmlBuilder.buildResultsIntermediateHeader(entityNeedingTextID);
    if (settings.globals.currentTourny.playerListReady) returnText += '<br>' + getTeamRoster(entityNeedingTextID);
    returnText += getAllResultsForEntity(entityNeedingTextID, false, false);
    return returnText;
}

function getDashboardLayerText(entityNeedingTextID) {
    let returnText = '';
    if ('Z' === manageData.getEntityGroupID(entityNeedingTextID))
        returnText = htmlBuilder.buildDidNotQualifyPopUp(manageData.getProperEntityName(entityNeedingTextID));
    else {
        returnText = htmlBuilder.buildResultsIntermediateHeader(entityNeedingTextID);
        if (settings.globals.currentTourny.playerListReady) returnText += '<br>' + getTeamRoster(entityNeedingTextID);
        returnText += getAllResultsForEntity(entityNeedingTextID, false, true);
    }
    return returnText;
}

function getGenericQualificationPopUpText(entityID) {
    if ('Z' === manageData.getEntityGroupID(entityID))
        return htmlBuilder.buildDidNotQualifyPopUp(manageData.getProperEntityName(entityID));
    else return htmlBuilder.buildDidQualifyPopUp(manageData.getProperEntityName(entityID));
}

function getRankingsLayerText(entityID) {
    if ('Z' === manageData.getEntityGroupID(entityID))
        return htmlBuilder.buildDidNotQualifyPopUp(manageData.getProperEntityName(entityID));
    else {
        let theRank = manageData.getWorldRank(entityID);
        let tournyRank = manageData.getTournyRankingIndex(entityID);
        let thePoints = manageData.getWorldRankPoints(entityID);
        if (theRank === undefined) {
            utilities.myErrorReport('location 580 ' + 'could not find rank for ', settings.misc.dataNotFoundOK);
            return undefined;
        } else if (thePoints === undefined) {
            utilities.myErrorReport('location 581 ' + 'could not find points for ', settings.misc.dataNotFoundOK);
            return undefined;
        } else return htmlBuilder.buildRankingsPopUp(manageData.getProperEntityName(entityID), theRank, thePoints);
    }
}

function getGroupStandingsText() {
    for (let m = 0; m < settings.globals.currentTourny.numOfGroups; m++) {
        let teamsInGroup = settings.globals.currentTeams.filter(
            (o) => o.groupID === settings.globals.currentGroups[m].groupID
        );
        if (teamsInGroup.length !== settings.globals.currentTourny.teamsPerGroup) {
            //this covers undefined case as well as 0 case
            utilities.myErrorReport(
                'location 590 ' + 'incorrect number of teams found for group: ' + settings.groups[m].groupID,
                settings.misc.majorError
            );
        } else {
            teamsInGroup.sort((a, b) => (a.groupStagePoints < b.groupStagePoints ? 1 : -1));
            settings.globals.currentGroups[m].groupStandings = htmlBuilder.buildGroupPopUpBody(
                settings.globals.currentGroups[m].groupName,
                teamsInGroup
            );
        }
    }
}

function getFanMarkersText(entityID, cityID) {
    let theCity = settings.fans.find((k) => k.cityID === cityID);
    let returnText = '';
    if (theCity === undefined) utilities.myErrorReport('location 600 ' + 'city not found', settings.misc.majorError);
    else {
        returnText = htmlBuilder.buildFanMarkersPopUp(
            manageData.getProperEntityName(entityID),
            theCity.cityName,
            theCity.numOfFans
        );
    }
    return returnText;
}
/**
 * Please describe the following function here
 * @param baseEntityID
 * @param markerEntityID
 * @returns {string}
 */
function getFromCountryMarkersText(baseEntityID, markerEntityID) {
    let theEntity = settings.fifaEntities.find((m) => m.ID === baseEntityID);
    let returnText = '';
    if (theEntity === undefined) {
        // this shouldn't happen
        utilities.myErrorReport(
            'location 610 ' + 'fifa entity not found: ' + baseEntityID,
            settings.misc.dataNotFoundOK
        );
    } else {
        let location = manageData.getProperEntityName(markerEntityID);
        let adjective = theEntity.adjective;
        let thePlayers = settings.globals.currentPlayersInTourny.filter((o) => o.playsForID === baseEntityID);
        let theEntries = [];
        if (thePlayers === undefined) {
            // this shouldn't happen because all entities that have markers should have at least one player
            utilities.myErrorReport(
                'location 620 ' + 'no players found for marker base entity: ' + baseEntityID,
                settings.misc.majorError
            );
        } else if (thePlayers.length === 0)
            // this shouldn't happen because all entities that have markers should have at least one player
            utilities.myErrorReport(
                'location 630 ' + 'no players found for marker base entity: ' + baseEntityID,
                settings.misc.majorError
            );
        else {
            let listOfEntries = [];
            for (let k = 0; k < thePlayers.length; k++) {
                let theClub = settings.globals.clubs.find(
                    (g) => g.clubID === thePlayers[k].clubID && g.clubLocationID === markerEntityID
                );
                if (theClub === undefined) {
                    // do nothing if the club isn't found
                } else {
                    let foundPlayer = settings.globals.players.find((m) => m.playerID === thePlayers[k].playerID);
                    if (foundPlayer === undefined) {
                        // this should not happen
                        utilities.myErrorReport(
                            'location 640 ' +
                                "undefined player when building players' markers text: " +
                                thePlayers[k].playerID,
                            settings.misc.dataNotFoundOK
                        );
                    } else {
                        listOfEntries.push([manageData.getProperClubName(theClub), manageData.getProperPlayerName(foundPlayer, baseEntityID)]);
                    }
                }
            }
            if (listOfEntries.length > 0) {
                listOfEntries.sort((a, b) => utilities.mySort(a, b));
                let lastClub = '';
                let theClubText = '';

                for (let m = 0; m < listOfEntries.length; m++) {
                    if (lastClub !== listOfEntries[m][0]) {
                        lastClub = listOfEntries[m][0];
                        theClubText = lastClub;
                    } else theClubText = '&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp"';
                    theEntries.push([theClubText, listOfEntries[m][1]]); //second part is player name
                }
            }
        }
        returnText = htmlBuilder.buildFromCountryMarkersPopUp(
            location,
            adjective,
            theEntries,
            settings.globals.currentTourny.shortName
        );
    }
    return returnText;
}

function getToCountryMarkersText(baseEntityID, markerEntityID) {
    let theEntity = settings.fifaEntities.find((o) => o.ID === markerEntityID);
    let returnText = '';
    if (theEntity === undefined) {
        // this shouldn't happen
        utilities.myErrorReport(
            'location 650 ' + 'fifa entity not found: ' + markerEntityID,
            settings.misc.dataNotFoundOK
        );
    } else {
        let location = manageData.getProperEntityName(baseEntityID);
        let adjective = theEntity.adjective;
        let thePlayers = settings.globals.currentPlayersInTourny.filter((o) => o.playsForID === markerEntityID);
        let theEntries = [];
        if (thePlayers.length === 0) {
            // this should not happen because not all markers should have players
            utilities.myErrorReport(
                'location 660 ' + 'marker encountered without a player: ' + markerEntityID,
                settings.misc.dataNotFoundOK
            );
        } else {
            let listOfEntries = [];
            for (let k = 0; k < thePlayers.length; k++) {
                let theClub = settings.globals.clubs.find(
                    (g) => g.clubID === thePlayers[k].clubID && g.clubLocationID === baseEntityID
                ); //markerEntity and clubEntity must be right
                if (theClub === undefined) {
                    // this can and usually does  happen because we are looking for clubs in a certain place
                    //
                } else {
                    let thePlayer = settings.globals.players.find((m) => m.playerID === thePlayers[k].playerID);
                    if (thePlayer === undefined) {
                        // this should not happen
                        utilities.myErrorReport(
                            'location 670 ' + 'player not found for playerID: ' + thePlayers[k].playerID,
                            settings.misc.dataNotFoundOK
                        );
                    } else {
                        listOfEntries.push([
                            manageData.getProperClubName(theClub),
                            manageData.getProperPlayerName(thePlayer, markerEntityID),
                        ]);
                    }
                }
            }
            if (listOfEntries.length > 0) {
                listOfEntries.sort((a, b) => utilities.mySort(a, b));
                let lastClub = '';
                let theClubText = '';
                for (let m = 0; m < listOfEntries.length; m++) {
                    if (lastClub !== listOfEntries[m][0]) {
                        lastClub = listOfEntries[m][0];
                        theClubText = lastClub;
                    } else theClubText = '&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp"';

                    theEntries.push([theClubText, listOfEntries[m][1]]); //second part is player name
                }
            }
        }
        returnText = htmlBuilder.buildToCountryMarkersPopUp(
            location,
            adjective,
            theEntries,
            settings.globals.currentTourny.shortName
        );
    }
    return returnText;
}

function getTeamRoster(teamID) {
    let returnText = '';
    let thePlayers = settings.globals.currentPlayersInTourny.filter((m) => m.playsForID === teamID);
    let len = thePlayers.length;
    if (len === 0) {
        utilities.myErrorReport(
            'location 11 ' + 'no players not found when building players text',
            settings.misc.majorError
        );
    } else {
        let playersSortedByNumber = [];
        let totalCaps = 0;
        let totalYears = 0;
        let totalPlayers = 0;
        let age = 0;
        for (let k = 0; k < len; k++) {
            let thePlayer = settings.globals.players.find((z) => z.playerID === thePlayers[k].playerID);
            if (thePlayer === undefined) {
                utilities.myErrorReport(
                    'location 21 ' + 'player not found when searching by ID',
                    thePlayers[k].playerID,
                    settings.misc.majorError
                );
            } else {
                //note thePlayer is not the same as thePlayers
                let theClub = settings.globals.clubs.find((m) => m.clubID === thePlayers[k].clubID);
                let theClubName = manageData.getProperClubName(theClub);
                age = utilities.calculateAge(
                    thePlayer.birthMonth,
                    thePlayer.birthDay,
                    thePlayer.birthYear,
                    settings.globals.currentTourny.startMonth,
                    settings.globals.currentTourny.startDay,
                    settings.globals.currentTourny.startYear
                );
                playersSortedByNumber.push([
                    thePlayers[k].jerseyNumber,
                    manageData.getProperPlayerName(thePlayer, teamID),
                    thePlayers[k].position,
                    theClubName,
                    thePlayers[k].caps,
                    thePlayers[k].goals,
                    age,
                    thePlayer.nationality
                ]);
                totalCaps += thePlayers[k].caps;
                if (age !== null) {
                    totalYears += age;
                    totalPlayers++;
                }
            }
        }
        playersSortedByNumber.sort((a, b) => utilities.mySort(a, b));
        if (playersSortedByNumber.length !== 23) {
            utilities.myErrorReport(
                'location 31 ' + 'wrong number of players when making popup on players level, continue',
                settings.misc.dataNotFoundOK // okay
            );
        }
        let youngestNameAge = playersSortedByNumber.reduce((min, prev) => (prev[6] < min[6] ? prev : min)); //calculate youngest
        let oldestNameAge = playersSortedByNumber.reduce((max, prev) => (prev[6] > max[6] ? prev : max)); //calculate oldest
        returnText = htmlBuilder.buildTeamRoster(
            settings.globals.currentTourny.includeNumber,
            settings.globals.currentTourny.includeAge,
            settings.globals.currentTourny.includeCaps,
            settings.globals.currentTourny.includeClub,
            settings.globals.currentTourny.includePosition,
            settings.globals.currentTourny.showNationality,
            Math.round(totalYears / totalPlayers), //averageAge
            Math.round(totalCaps / playersSortedByNumber.length), //average # of caps
            oldestNameAge[1],
            oldestNameAge[6],
            youngestNameAge[1],
            youngestNameAge[6],
            playersSortedByNumber
        );
    }
    return returnText;
}

function getVideosLayerText(entityID, type) {
    let theHost = settings.hosts.find((k) => k.hostType === type);
    let theEntities = theHost.hostYears.filter((m) => m.hostID === entityID);
    if (theEntities.length === 0)
        return htmlBuilder.buildNoHostPopUp(manageData.getProperEntityName(entityID), theHost.hostTypeFullName);
    else {
        theEntities.sort((a, b) => (a.year < b.year ? 1 : -1));
        return htmlBuilder.buildHostPopUp(theEntities, theHost.hostTypeFullName);
    }
}

function getMWCVideosLayerText(entityID) {
    return getVideosLayerText(entityID, 'MWC');
}

function getMEuroVideosLayerText(entityID) {
    return getVideosLayerText(entityID, 'MEuro');
}

function getWWCVideosLayerText(entityID) {
    return getVideosLayerText(entityID, 'WWC');
}

function getGreatPlayerVideosText(entityID, gender) {
    let theEntities = settings.greatPlayers.filter((m) => m.playedForID === entityID && m.gender === gender);
    if (theEntities.length === 0) return htmlBuilder.buildNoGreatPlayerPopUp(manageData.getProperEntityName(entityID));
    else {
        return htmlBuilder.buildGreatPlayerPopUp(
            theEntities,
            manageData.getProperEntityName(theEntities[0].playedForID)
        );
    }
}

function getGreatMPlayerVideosLayerText(entityID) {
    return getGreatPlayerVideosText(entityID, 'M');
}

function getGreatWPlayerVideosLayerText(entityID) {
    return getGreatPlayerVideosText(entityID, 'W');
}

function getLeagueSingleResultText(theEntityID, notUsed, theRoundID) {
    let theMatch = null;
    if (settings.globals.currentTourny.hasRoundIDs) {
        theMatch = settings.globals.currentMatches.find(
            (k) => (k.teamOneID === theEntityID || k.teamTwoID === theEntityID) && k.sportMonksRoundID === theRoundID
        );
    } else {
        theMatch = settings.globals.currentMatches.find(
            (k) => (k.teamOneID === theEntityID || k.teamTwoID === theEntityID) && k.playDateRoundID === theRoundID
        );
    }
    if (theMatch) return getSingleResultText(theMatch, theEntityID, false);
    else return htmlBuilder.noMatchOnGivenDay(theEntityID);
}

export {
    getQualifyingLayerText,
    getScheduleLayerText,
    getLeagueScheduleLayerText,
    getGroupLayerText,
    getRankingsLayerText,
    getDashboardLayerText,
    getLeagueDashboardLayerText,
    getTextForRound,
    getTalentFlowFromCountryLayerText,
    getTalentFlowToCountryLayerText,
    getTalentFlowFromConfederationLayerText,
    getTalentFlowToConfederationLayerText,
    getFanMarkersText,
    getFromCountryMarkersText,
    getToCountryMarkersText,
    getPreliminaryRoundText,
    getMWCVideosLayerText,
    getWWCVideosLayerText,
    getMEuroVideosLayerText,
    getGreatMPlayerVideosLayerText,
    getGreatWPlayerVideosLayerText,
    getGroupStandingsText,
    getLeagueSingleResultText,
};
