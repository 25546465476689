//import * as yt from 'youtube-search-without-api-key';
import fifaEntities from './dataFIFAEntities';

function displayVideo(videoURL, largeFormat) {
    let theWidth = 'width=480,';
    let theHeight = 'height=340';
    if (largeFormat) {
        theWidth = 'width=496,';
        theHeight = 'height=467';
    }
    window.open(
        videoURL,
        '"_blank"', //careful here with comma, single quote, and double quotes
        '"toolbar=no,' + //single double quote here
            'scrollbars=yes,' +
            'resizable=yes,' +
            'menubar=no,' +
            'statusbar=no,' +
            'titlebar=no,' +
            theWidth +
            theHeight +
            '"' //single double quote here
    );
}
function buildVideoLink(theMatch) {
    let returnLink = '';
    let teamOne = fifaEntities.find((k) => k.ID === theMatch.homeTeamID);
    let teamTwo = fifaEntities.find((k) => k.ID === theMatch.visitorTeamID);
    if (teamOne && teamTwo) {
        returnLink += 'https://www.mlssoccer.com/video/highlights-';
        returnLink += teamOne.name;
        returnLink += '-vs-';
        returnLink += teamTwo.name;
        returnLink += '-';
        returnLink += settings.months[theMatch.month].name;
        returnLink += '-';
        returnLink += theMatch.day.toString().padStart(2, '0');
        returnLink += '-';
        returnLink += theMatch.year.toString();
        returnLink = returnLink.replaceAll(' ', '-'); //replace space in team names by dash
    }
    return returnLink;
}

function handleVideo(whichMatchID) {
    let openTheVideo = false;
    let theVideoURL = '';
    let theMatch = settings.globals.currentMatches.find((m) => m.matchID === whichMatchID);
    if (theMatch === undefined) {
        utilities.myErrorReport('location 80 ' + 'undefined when searching for match number:' + whichMatchID);
    } else {
        if (settings.globals.currentTourny.buildVideoLink) {
            let theLink = buildVideoLink(theMatch);
            if (theLink) displayVideo(theLink, true);
        } else {
            if (theMatch.youTubeID) {
                theVideoURL = 'https://www.youtube.com/watch?v=' + theMatch.youTubeID;
                openTheVideo = true;
            } else if (theMatch.sportMonksVideoLink) {
                theVideoURL = theMatch.sportMonksVideoLink;
                openTheVideo = true;  //the sportMonks Videos are not reliable, blocked elsewhere
            }
            if (openTheVideo) {
                displayVideo(theVideoURL, false);
            }
        }
    }
}

export { handleVideo };
