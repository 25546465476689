// https://fontawesome.com/icons
// import '@fortawesome/fontawesome-free/js/all';

import 'leaflet/dist/leaflet'; //necessary
import 'leaflet-defaulticon-compatibility';
import './leaflet-timeline-slider';

import hosts from './dataHosts';
import greatPlayers from './dataGreatPlayers';
//import rankings from './dataRankings';
import * as manageLayers from './manageLayers';
import * as manageLayerSetUp from './manageLayerSetUp';
import * as manageLayerText from './manageLayerText';
import * as dataCheck from './dataCheck';
import confederations from './dataConfederations';
import dataPolygonsFirst from './dataPolygonsFirst';
import fifaEntities from './dataFIFAEntities';
import fans from './dataFans';

//import clubs from './dataClubs';
import groups from './dataGroups';
import tournys from './dataTournys';
import * as manageData from './manageData';
import * as htmlBuilder from './htmlBuilder';
import * as firebaseHelpers from './firebase/helpers';
import '../scss/styles.scss';
import settings from './dataSettings';
window.settings = settings;

/* fetch(
    'https://soccer.sportmonks.com/api/v2.0/standings/season/19137?api_token=tcGqLh4yseyepPvsmlDHSF1jfO2PU567lMZDwZ5JBF5IsZPNn8jZbcadrIAw'
)
    .then((response) => {
        // console.log(response);
        return response.json();
    })
    .then((result) => {
        //(result);
    }); */

function setUpMap() {
    settings.globals.map = new L.Map('map', {
        zoomDelta: settings.map.zoomDelta,
        zoomSnap: settings.map.zoomSnap,
        zoomControl: false,
        attributionControl: false,
    }).setView([0, 0], 1); //this view is just a placeholder

    settings.globals.map.on('popupopen', (e) => {
        if (e.popup.myOnOpenPopUpFunc) {
            // if there is a function that needs running
            e.popup.myOnOpenPopUpFunc(e.popup.entityID); //then run it
        }
    });

    settings.globals.map.setMaxZoom(settings.map.maxZoom);
}
/**
 * Please describe the following function here
 * @param whichTourny
 */
function initializeNonChangingGlobals() {
    settings.polygons = dataPolygonsFirst; //these don't change based on tourny
    //settings.globals.clubs = clubs; //these don't change based on tourny
    settings.groups = groups; //these don't change based on tourny
    settings.fifaEntities = fifaEntities; //these don't change based on tourny
    settings.fans = fans; //these don't change based on tourny
    settings.confederations = confederations; //the tally's do change and get re-intialized (see tally)
    settings.tournys = tournys; //these don't change based on tourny
    settings.hosts = hosts; //these don't change based on tourny
    settings.greatPlayers = greatPlayers; //these don't change based on tourny
    manageLayerSetUp.setUpLayerParameters();
}

async function initializeTourny(whichTourny) {
    let theTourny = settings.tournys.find((k) => k.tournyID === whichTourny);
    if (theTourny) {
        await manageData.pullCorrectData(whichTourny); //this comes first because everything else depends on it
        
        htmlBuilder.dumpLayers();
        htmlBuilder.initializeMenuAndSideBar(); //some menu items are tourny dependent

        settings.globals.currentTeamID = settings.globals.currentTourny.startEntityID;
        settings.globals.currentFromEntityID = settings.globals.currentTourny.startEntityID;
        manageData.tallyResults();
        manageData.tallyPlayersComingToFromConfederations();//this should be not always be called because for certain tournaments this is irrelevant

        manageLayerText.getGroupStandingsText();
        htmlBuilder.addHeaderActiveClass();
        htmlBuilder.addSideBarActiveClass();

        //some layers on tourny dependent, create all each time the tourny changes for simplicity
        manageLayers.createLayers();

        // event listeners:  these listeners get re-installed because the menu gets rebuilt for each tourny
        document.querySelector('.hamburger').addEventListener('click', function (e) {
            let state = document.querySelector('html').getAttribute('data-mobile_nav_open') === 'true';
            document.querySelector('html').setAttribute('data-mobile_nav_open', !state);
        });

        document.querySelector('.tourny_selectorMenu').addEventListener('click', function (e) {
            const tournyID = e.target.getAttribute('data-tournyID');
            initializeTourny(tournyID);
            //}, 300);
        });
        setTimeout(function () {
            /*  if (theTourny.requiresLogIn && !settings.globals.firebase.activeSubscription) {
                document.querySelector('[data-logIn_layer]').click();
            } else */
            document.querySelector('[data-start_layer]').click();
        }, 100);
    }
}

dataCheck.doBigDataChk();
const map = document.getElementById('map');

if (map) {
    firebaseHelpers.initFirebase();
    setUpMap();
    initializeNonChangingGlobals();
    initializeTourny(settings.misc.startTourny);
}
