import * as utilities from './utilities';
import * as manageData from './manageData';
import * as manageVideo from './manageVideo';
import 'leaflet/dist/leaflet';
//import * as firebaseHelpers from './firebase/helpers';
import { times } from 'lodash';
//import { WatchIgnorePlugin } from 'webpack';

const leftBar = document.getElementById('LeftBar');
const menu = document.getElementById('LeftBarContainer'); //is this needed (dec 2020)

function initializeMenuAndSideBar() {
    const header = document.getElementById('Header');
    if (header) {
        header.innerHTML = buildHeader();
    }
}

function buildManageAccount() {
    let returnText =
        '<div class="generalTextDisplay">' +
        '<div id="mySpinner">' +
        '</div>' +
        'You are being redirected to your account portal.<br>' +
        '</div>';
    leftBar.innerHTML = returnText;
}

function buildNewsLetter() {
    let returnText =
        '<div class="generalTextDisplay leftDisplay">' +
        'Along with futbol fans from around the world, you will receive our newsletter with news about our site and about futbol.' +
        '<br><br>' +
        'You will be able to unsubscribe at any time.' +
        '<br><br>' +
        '<a class="textLinks" target=”_blank” href="' +
        settings.misc.newsLetterLink +
        '">Join</a>' +
        '</div>';
    leftBar.innerHTML = returnText;
}
// all of this is necessary to make sure that the bracket is built with the correct team on top and
// the correct team on the bottom (by paying attention to who won in the previous round)
function getBracketInfo(slot, row, infoNeeded) {
    let teamNeeded = null;
    let theCurrentMatch = null;
    let thePreviousMatch = null;
    let textNeeded = null;
    let theTeamID = null;
    let theAltText = null;
    if (row % 2 === 1) teamNeeded = 'teamOne';
    theCurrentMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === slot);
    if (theCurrentMatch === undefined) utilities.myErrorReport('location 1 ' + 'match not for round: ', slot);
    else {
        if (
            //round of 16
            slot === 'r16-1' ||
            slot === 'r16-2' ||
            slot === 'r16-3' ||
            slot === 'r16-4' ||
            slot === 'r16-5' ||
            slot === 'r16-6' ||
            slot === 'r16-7' ||
            slot === 'r16-8'
        ) {
            if (teamNeeded === 'teamOne') {
                theTeamID = theCurrentMatch.teamOneID;
                theAltText = theCurrentMatch.teamOneAltText;
            } else {
                theTeamID = theCurrentMatch.teamTwoID;
                theAltText = theCurrentMatch.teamTwoAltText;
            }
        } else {
            //subsequent rounds
            if (slot === 'r8-1' && teamNeeded === 'teamOne')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r16-1');
            else if (slot === 'r8-1')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r16-2');
            else if (slot === 'r8-2' && teamNeeded === 'teamOne')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r16-3');
            else if (slot === 'r8-2')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r16-4');
            else if (slot === 'r8-3' && teamNeeded === 'teamOne')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r16-5');
            else if (slot === 'r8-3')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r16-6');
            else if (slot === 'r8-4' && teamNeeded === 'teamOne')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r16-7');
            else if (slot === 'r8-4')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r16-8');
            else if (slot === 'r4-1' && teamNeeded === 'teamOne')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r8-1');
            else if (slot === 'r4-1')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r8-2');
            else if (slot === 'r4-2' && teamNeeded === 'teamOne')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r8-3');
            else if (slot === 'r4-2')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r8-4');
            else if (slot === 'r2' && teamNeeded === 'teamOne')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r4-1');
            else if (slot === 'r2')
                thePreviousMatch = settings.globals.currentMatches.find((k) => k.bracketSlot === 'r4-2');

            if (thePreviousMatch === undefined) utilities.myErrorReport('location 2 ' + 'match not for round: ', slot);
            else if (!thePreviousMatch.matchCompleted) theTeamID = 'TBD';
            else {
                /* if (!thePreviousMatch.matchCompleted) //this looks redundant, revisit later
                    theTeamID = 'TBD'; */
                if (thePreviousMatch.teamOneScore > thePreviousMatch.teamTwoScore) {
                    theTeamID = thePreviousMatch.teamOneID;
                } else {
                    theTeamID = thePreviousMatch.teamTwoID;
                }
            }
        }

        if (infoNeeded === 'name') {
            if (theTeamID === 'TBD')
                if (theAltText) textNeeded = theAltText;
                else textNeeded = 'TBD';
            else textNeeded = manageData.getProperEntityName(theTeamID);
        }

        if (infoNeeded === 'score') {
            if (theTeamID === 'TBD') textNeeded = '';
            else if (theCurrentMatch.matchCompleted) {
                if (theCurrentMatch.teamOneID === theTeamID) textNeeded = theCurrentMatch.teamOneScore;
                else textNeeded = theCurrentMatch.teamTwoScore;
                if (theCurrentMatch.penaltyKickShootout) textNeeded += '*';
            } else textNeeded = '';
        }

        if (infoNeeded === 'flag') {
            if (theTeamID === 'TBD') textNeeded = '';
            else textNeeded = '<div class="bracketFlag"><img  src=' + getIconSource(theTeamID) + '></div > ';
        }
    }
    return textNeeded;
}

function getIconSource(theTeamID) {
    if (!settings.globals.currentTourny.dataSources.flagsUseAPI) return './img/countryFlags/' + theTeamID + '.png';
    else return manageData.getTeamLogoFromAPI(theTeamID);
}

function getBracketPiece(row, column) {
    let returnText = null;
    let bracketSlot = null;
    let columnMod = column % 4;
    if (row === 1 || row === 2) bracketSlot = 'r16-1';
    if (row === 5 || row === 6) bracketSlot = 'r16-2';
    if (row === 9 || row === 10) bracketSlot = 'r16-3';
    if (row === 13 || row === 14) bracketSlot = 'r16-4';
    if (row === 17 || row === 18) bracketSlot = 'r16-5';
    if (row === 21 || row === 22) bracketSlot = 'r16-6';
    if (row === 25 || row === 26) bracketSlot = 'r16-7';
    if (row === 29 || row === 30) bracketSlot = 'r16-8';

    if (row === 3 || row === 4) bracketSlot = 'r8-1';
    if (row === 11 || row === 12) bracketSlot = 'r8-2';
    if (row === 19 || row === 20) bracketSlot = 'r8-3';
    if (row === 27 || row === 28) bracketSlot = 'r8-4';

    if (row === 7 || row === 8) bracketSlot = 'r4-1';
    if (row === 23 || row === 24) bracketSlot = 'r4-2';

    if (row === 15 || row === 16) bracketSlot = 'r2';

    if (columnMod === 1) returnText = getBracketInfo(bracketSlot, row, 'flag');
    else if (columnMod === 2) returnText = getBracketInfo(bracketSlot, row, 'name');
    else returnText = getBracketInfo(bracketSlot, row, 'score');
    return returnText;
}

function buildBracket() {
    let returnText = '<div class="wrapper">';
    for (let m = 1; m < 31; m++) {
        let rowMod4 = m % 4;
        let rowMod8 = m % 8;
        let rowMod16 = m % 16;
        let rowMod32 = m % 32;
        for (let k = 1; k < 16; k++) {
            let content = '';
            let classAdd = '';
            if ((k === 1 || k === 2 || k === 3) && (rowMod4 === 1 || rowMod4 === 2)) content = getBracketPiece(m, k);
            else if ((k === 5 || k === 6 || k === 7) && (rowMod8 === 3 || rowMod8 === 4))
                content = getBracketPiece(m, k);
            else if ((k === 9 || k === 10 || k === 11) && (rowMod16 === 7 || rowMod16 === 8))
                content = getBracketPiece(m, k);
            else if ((k === 13 || k === 14 || k === 15) && (rowMod32 === 15 || rowMod32 === 16))
                content = getBracketPiece(m, k);

            if (k === 4 && rowMod8 === 3) classAdd += 'myBorderBottom ';
            else if (k === 8 && rowMod16 === 7) classAdd += 'myBorderBottom ';
            else if (k === 12 && m === 15) classAdd = 'myBorderBottom ';

            if (k === 3 && rowMod8 > 1 && rowMod8 < 6) classAdd += 'myBorderRight ';

            if (k === 7 && rowMod16 > 3 && rowMod16 < 12) classAdd += 'myBorderRight ';

            if (k === 11 && rowMod32 > 7 && rowMod32 < 24) classAdd += 'myBorderRight ';

            if (k === 3 && rowMod8 === 2) classAdd += 'myBorderTop ';
            if (k === 3 && rowMod8 === 5) classAdd += 'myBorderBottom ';
            if (k === 7 && rowMod16 === 4) classAdd += 'myBorderTop ';
            if (k === 7 && rowMod16 === 11) classAdd += 'myBorderBottom ';
            if (k === 11 && rowMod32 === 8) classAdd += 'myBorderTop ';
            if (k === 11 && rowMod32 === 23) classAdd += 'myBorderBottom ';

            returnText += '<div class="box ' + classAdd + '">' + content + '</div>';
        }
    }

    returnText += '</div>';
    leftBar.innerHTML = returnText;
}

function buildAbout() {
    let returnText =
        '<div class="generalTextDisplay leftDisplay">' +
        'The team at OneWorld-Futbol is proud to share with you our ambitious new project.' +
        '<br><br>' +
        'From amazing maps to data visualizations, OneWorld-Futbol makes the Beautiful Game come alive.' +
        '<br><br>' +
        'Color and geography combine to make schedules, results, rosters, and groups spring into view.' +
        '<br><br>' +
        'Use the Talent Flow feature to explore the worldwide migration of players. ' +
        'Watch videos of the best teams and stars in action. ' +
        '<br><br>' +
        'Please share with your friends,' +
        ' send us ' +
        '<a class="textLinks" href="#" onclick = "document.querySelector(\'[data-sendEmail_layer]\').click()">Feedback, </a>' +
        'and of course, feel free to ' +
        '<a class="textLinks" href="#" onclick = "document.querySelector(\'[data-coffee_layer]\').click()">Buy Us A Coffee.</a>' +
        '<br><br>' +
        'Mark Lawton' +
        '<br>' +
        'President, OneWorld-Futbol' +
        '<br>' +
        'Portland, Oregon, United States' +
        '<br><br>' +
        '<a class="textLinks" href="#" onclick = "document.querySelector(\'[data-privacy_layer]\').click()">Privacy Policy</a>' +
        ' and <a class="textLinks" href="#" onclick = "document.querySelector(\'[data-terms_layer]\').click()">Terms Of Use</a>' +
        '</div>';
    leftBar.innerHTML = returnText;
}

function buildComingSoon() {
    let returnText = '<div class="generalTextDisplay">';
    returnText += 'The players for World Cup 2022 have not yet';
    returnText += '< br > ' + 'been announced by their countries.';
    returnText += '<br><br>';
    returnText += 'To see how this feature works, switch to the 2018 World Cup.';
    returnText += '</div>';
    leftBar.innerHTML = returnText;
}

function buildEmailThankYou() {
    let returnText = '<div class="generalTextDisplay">';
    returnText += 'Thank You.' + '</br>We are looking forward to reading your message.';
    returnText += '</div>';
    leftBar.innerHTML = returnText;
}

function buildLogIn() {
    let returnText = '<div class="generalTextDisplay">';
    returnText += 'Thank You.' + '</br>You are signed in<br>with a subscription.';
    returnText += '</div>';
    leftBar.innerHTML = returnText;
}

function buildLogOut() {
    let returnText = '<div class="generalTextDisplay">';
    returnText += 'Thank You.' + '</br>You are signed out.';
    returnText += '</div>';
    leftBar.innerHTML = returnText;
}

/**
 * Wraps a string in a span with a class that highlight the element
 * @param theTextToWrap
 * @returns {string}
 */
function wrapInHighlight(theTextToWrap) {
    return '<span class="highlight">' + theTextToWrap + '</span>';
}

/**
 * Please describe the following function here
 * @param theText
 * @returns {string}
 */
function myBold(theText) {
    return '<strong>' + theText + '</strong>';
}

function buildDidNotQualifyPopUp(theTeamName) {
    return myBold(theTeamName) + ' did not qualify.';
}

function buildDidQualifyPopUp(theTeamName) {
    return myBold(theTeamName) + ' Qualified!';
}

function buildQualifyingPopUp(entityName, theConfedText, message) {
    return myBold(theConfedText) + '<br>' + myBold(entityName) + '<br>' + message;
}

function buildUpcomingGamePopUp(theYear, theMonth, theDay, theTime, theRound, firstTeamName, secondTeamName) {
    return (
        myBold(buildDateForMatch(null, theMonth, theDay, theTime, false)) + // don't include year
        theRound +
        '<br>' +
        firstTeamName +
        ' vs ' +
        secondTeamName
    );
}

function buildDidAdvancePopUp(theEntityName) {
    return myBold(theEntityName) + '<br>Advanced to Round of Sixteen.<br>';
}

function buildDidNotAdvancePopUp(theEntityName) {
    return myBold(theEntityName) + '<br>Did not advance to Round of Sixteen.<br>';
}

function buildSingleMatchPopUp(
    theYear,
    theMonth,
    theDay,
    theTime,
    theRoundText,
    firstTeamName,
    firstTeamScore,
    opponentName,
    opponentScore,
    pkShootOut,
    matchIDForVideo
) {
    let videoButtonCodeOpen = null;
    let videoButtonCodeClose = null;
    let returnText = '';
    returnText +=
        myBold(buildDateForMatch(null, theMonth, theDay, null, false)) + // don't include year or time
        theRoundText;

    if (matchIDForVideo) {
        videoButtonCodeOpen =
            '<div class="universalCameraContainer">' + '<a href="#" class="universalCamera" onclick="handleVideo(';
        videoButtonCodeClose = ')"></a>' + '</div>';
        returnText += videoButtonCodeOpen + matchIDForVideo + videoButtonCodeClose;
    }

    returnText +=
        '<br>' + firstTeamName + '&nbsp' + firstTeamScore + '&nbsp-&nbsp' + opponentName + '&nbsp' + opponentScore;
    if (pkShootOut) returnText += '<br>*penalty kicks';
    return returnText;
}

function buildNextLine(resultsSoFar) {
    return '<br>' + resultsSoFar + '<br>';
}

function buildFullResultsPopUp(teamName, theResults) {
    return myBold(teamName) + '<br>' + theResults;
}

function buildLostInPreviousPopUp(theEntityName, theMessage, opponentName) {
    return myBold(theEntityName) + ' lost in:' + '<br>' + theMessage + ' to ' + opponentName + '.<br>';
}

function buildTalentFlowToCountryPopUp(nounPart, teamName, messageA, playersInEntity, messageB) {
    return (
        '<div class="super_title">' +
        nounPart +
        myBold(teamName) +
        messageA +
        '<br>' +
        myBold(playersInEntity) +
        messageB +
        '<div>'
    );
}

function buildTalentFlowFromConfederationPopUp(confederationName, playersLeaving) {
    if (playersLeaving === 0) {
        return (
            'There are no ' +
            settings.globals.currentTourny.shortName +
            ' players from ' +
            myBold(confederationName) +
            ' leaving the confederation.'
        );
    } else
        return (
            'To play on their club team, ' +
            myBold(playersLeaving) +
            '&nbsp' +
            settings.globals.currentTourny.shortName +
            '<br>players leave the ' +
            myBold(confederationName) +
            ' confederaration.'
        );
}

function buildTalentFlowToConfederationPopUp(confederationName, playersEntering) {
    if (playersEntering === 0) {
        return (
            'There are no ' +
            settings.globals.currentTourny.shortName +
            ' players entering the<br>' +
            myBold(confederationName) +
            ' confederation to play on a club team.'
        );
    } else
        return (
            'To play on their club team, ' +
            myBold(playersEntering) +
            '&nbsp' +
            settings.globals.currentTourny.shortName +
            '<br>players enter the ' +
            myBold(confederationName) +
            ' confederaration.'
        );
}

function buildRankingsPopUp(teamName, ranking, points) {
    return (
        'At the beginning of the tourny, ' +
        myBold(teamName) +
        ' was ranked number ' +
        myBold(ranking) +
        ' in the world with ' +
        points +
        ' points. '
    );
}

/**
 * Please describe the following function here
 * @param theClub
 * @returns {string|*}
 */
function buildTalentFlowFromCountryPopUp(numOfPlayers, adjective, messageA, location) {
    return '<div class="super_title">' + numOfPlayers + '&nbsp' + myBold(adjective) + messageA + location + '.<div>';
}

function buildIndividualPlayerPopUp(
    clubLocation,
    clubName,
    playerName,
    age,
    position,
    jerseyNumber,
    caps,
    goals,
    height,
    weight,
    nationality
) {
    let returnText = myBold(playerName) + '<br>';
    if (age) {
        returnText += 'Age: ' + age + '<br>';
    }
    if (position) {
        returnText += 'Position: ' + position + '<br>';
    }
    if (jerseyNumber) {
        returnText += 'Jersey: ' + jerseyNumber + '<br>';
    }
    if (caps) {
        returnText += 'Caps: ' + caps + '<br>';
    }
    if (goals) {
        returnText += 'Goals: ' + goals + '<br>';
    }
    if (height) {
        returnText += 'Height: ' + height + '<br>';
    }
    if (weight) {
        returnText += 'Weight: ' + weight + '<br>';
    }
    if (nationality) {
        returnText += 'Nationality: ' + nationality + '<br>';
    }
    if (clubName) {
        returnText += 'Club in ' + myBold(clubLocation) + ': ' + clubName;
    }
    return returnText;
}

/**
 * Please describe the following function here
 * @param theMatch
 * @param whichTeam
 * @returns {string|*}
 */
function showBlankOrScore(theMatch, whichTeam) {
    let returnText = '';
    if (theMatch.matchCompleted) {
        if (whichTeam === 1) returnText = theMatch.teamOneScore;
        else if (whichTeam === 2) returnText = theMatch.teamTwoScore;
        if (theMatch.penaltyKickShootout) returnText += '*';
    }
    return returnText;
}

/**
 * Please describe the following function here
 * @returns {string}
 */
function buildSideBarText() {
    let sideText = '';
    let openOfMatch = '';
    let closeOfMatch = '';
    let openOfButton = '';
    let closeOfButton = '';
    let dateTimeCode = '';
    let scoreCode = '';
    let allTogetherCode = '';
    let videoButtonCodeOpen = '<div class="actions">' + '<a href="#" class="camera" onclick="handleVideo(';
    let videoButtonCodeClose = ')"></a>' + '</div>';

    let theDateOfGame = '';
    let gamesPerRound = (settings.globals.currentTourny.numOfGroups * settings.globals.currentTourny.teamsPerGroup) / 2;
    settings.globals.currentMatches = utilities.sortMatchesChrono(settings.globals.currentMatches, false); //true means reverse

    //need to calculate where the anchor to scroll to should go.
    let theK = 0;
    let stillSearching = true;
    let firstDayWithoutCompletedMatch = 0;
    let firstMonthWithoutCompletedMatch = 0;
    let firstYearWithoutCompletedMatch = 0;
    //go through looking for first match that hasn't ended

    for (let k = 0; k < settings.globals.currentMatches.length && stillSearching; k++) {
        if (stillSearching) {
            if (!settings.globals.currentMatches[k].matchCompleted) {
                // found an incompleted match
                stillSearching = false;

                firstDayWithoutCompletedMatch = settings.globals.currentMatches[k].day;
                firstMonthWithoutCompletedMatch = settings.globals.currentMatches[k].month;
                firstYearWithoutCompletedMatch = settings.globals.currentMatches[k].year;
            }
        }
    }

    //now go through the same list and find the  k of the first match (could be a completed match) that has
    stillSearching = true;
    for (let k = 0; k < settings.globals.currentMatches.length && stillSearching; k++) {
        if (
            settings.globals.currentMatches[k].day === firstDayWithoutCompletedMatch &&
            settings.globals.currentMatches[k].month === firstMonthWithoutCompletedMatch &&
            settings.globals.currentMatches[k].year === firstYearWithoutCompletedMatch
        ) {
            theK = k;
            stillSearching = false;
        }
    }
    sideText += '<a id="hiddenScrollLink2" href="#bestDate2"></a>';

    sideText += '<ul>';
    for (let k = 0; k < settings.globals.currentMatches.length; k++) {
        if (k === theK) sideText += '<a name = "bestDate2"></a>'; //this is the anchor that will auto scroll to
        if (settings.globals.currentTourny.scheduleFormat === 'threeRoundsToSixteenKnockOut') {
            if (k === 0) sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Group Stage, Round 1</li>';
            else if (k === gamesPerRound)
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Group Stage, Round 2</li>';
            else if (k === 2 * gamesPerRound)
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Group Stage, Round 3</li>';
            else if (k === 3 * gamesPerRound)
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Round Of Sixteen</li>';
            else if (k === 3 * gamesPerRound + 8)
                // qtr final
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Qtr-Finals</li>';
            else if (k === 3 * gamesPerRound + 8 + 4)
                //semi final
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Semi-Finals</li>';
            else if (k === 3 * gamesPerRound + 8 + 4 + 2)
                //final
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Final</li>';
        } else if (settings.globals.currentTourny.scheduleFormat === 'leagueToRoundOfTwelveWithByes') {
            if (k === 0) sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Regular Season</li>';
            else if (k === settings.globals.currentTourny.regularSeasonMatches)
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Round Of Twelve</li>';
            else if (k === settings.globals.currentTourny.regularSeasonMatches + 6)
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Qtr-Finals</li>';
            else if (k === settings.globals.currentTourny.regularSeasonMatches + 6 + 4)
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Semi-Finals</li>';
            else if (k === settings.globals.currentTourny.regularSeasonMatches + 6 + 4 + 2)
                sideText += '<li class="nav-item LeftBarTitle"><i class=""></i>Final</li>';
        }
        let theTimeOfGame = null;
        if (!settings.globals.currentMatches[k].matchCompleted)
            // show the time for upcoming games
            theTimeOfGame = settings.globals.currentMatches[k].time;
        theDateOfGame = buildDateForMatch(
            null, //don't include year
            settings.globals.currentMatches[k].month,
            settings.globals.currentMatches[k].day,
            theTimeOfGame,
            true
        );

        dateTimeCode = '<div class="date">' + theDateOfGame + '</div>';
        if (
            k > 0 &&
            settings.globals.currentMatches[k].month === settings.globals.currentMatches[k - 1].month &&
            settings.globals.currentMatches[k].day === settings.globals.currentMatches[k - 1].day
        ) {
            if (settings.globals.currentMatches[k].matchCompleted)
                //for same day game, if already played don't show time
                theDateOfGame = '';
            //leave blank
            else theDateOfGame = manageData.getCorrectTimeFormat(theTimeOfGame); //otherwise just show time (but not date)
            dateTimeCode = '<div class="date"><div class="day">' + theDateOfGame + '</div></div>';
        }

        openOfMatch = '<div class="match">'; //open match;
        closeOfMatch = '</div>';

        scoreCode = '<div>' + '<div class="club">'; //open x //open club one

        if (settings.globals.currentMatches[k].teamOneID === 'TBD')
            if (settings.globals.currentMatches[k].teamOneAltText)
                scoreCode += settings.globals.currentMatches[k].teamOneAltText;
            else scoreCode += 'TBD';
        else scoreCode += manageData.getProperEntityName(settings.globals.currentMatches[k].teamOneID);

        scoreCode += '</div>'; //close club one
        //open flag one
        if (settings.globals.currentMatches[k].teamOneID !== 'TBD') {
            scoreCode += '<div class="flag">';
            //'<img src="https://api.fifa.com/api/v1/picture/s-fwc2018-1/' +

            scoreCode +=
                '<img src=' +
                getIconSource(settings.globals.currentMatches[k].teamOneID) +
                ' > ' + //close img one
                '</div>'; //close flag one
        }
        scoreCode +=
            '<div class="score">' + //open score one
            showBlankOrScore(settings.globals.currentMatches[k], 1) +
            '</div>' + //close score one
            '</div>' + //close  x
            '<div>' + //open y
            '<div class="club">'; //open club 2
        if (settings.globals.currentMatches[k].teamTwoID === 'TBD')
            if (settings.globals.currentMatches[k].teamTwoAltText)
                scoreCode += settings.globals.currentMatches[k].teamTwoAltText;
            else scoreCode += 'TBD';
        else scoreCode += manageData.getProperEntityName(settings.globals.currentMatches[k].teamTwoID);
        scoreCode += '</div>'; //close club 2
        if (settings.globals.currentMatches[k].teamTwoID !== 'TBD') {
            scoreCode += '<div class="flag">'; //open flag 2
            scoreCode +=
                '<img src=' + //open img 2
                getIconSource(settings.globals.currentMatches[k].teamTwoID) +
                '>'; //close img 2
            scoreCode += '</div>'; //close flag 2
        }
        scoreCode +=
            '<div class="score">' + //open score 2
            showBlankOrScore(settings.globals.currentMatches[k], 2) +
            '</div>';
        +(
            //close score 2
            '</div>'
        ); // close y

        openOfButton = '<div class="sideBarFormat">';
        closeOfButton = '</div>';

        allTogetherCode = openOfButton + dateTimeCode + openOfMatch + scoreCode + closeOfMatch + closeOfButton;
        if (settings.globals.currentMatches[k].matchCompleted && settings.globals.currentMatches[k].youTubeID) {
            //||settings.globals.currentMatches[k].sportMonksVideoLink)

            //|| settings.globals.currentTourny.buildVideoLink)
            // if there is a video add it
            allTogetherCode += videoButtonCodeOpen + settings.globals.currentMatches[k].matchID + videoButtonCodeClose; //+
            //'</a>';
        }

        sideText += '<li class="nav-item">';
        sideText += allTogetherCode;
        sideText += '</li>';
    }
    sideText += '</ul>';
    leftBar.innerHTML = sideText;
    setTimeout(function () {
        document.getElementById('hiddenScrollLink2').click(); //scroll
    }, 200);
}

function buildSideBarPicsForCurrentTeam() {
    buildSideBarPics(settings.globals.currentTeamID);
}

function buildSideBarPicsForCurrentFromCountry() {
    buildSideBarPics(settings.globals.currentFromEntityID);
}

/**
 * Please describe the following function here
 * @param teamID
 * @returns {string}
 */
function buildSideBarPics(teamID) {
    let thePlayers = settings.globals.currentPlayersInTourny.filter((m) => m.playsForID === teamID);
    let len = thePlayers.length;
    if (len === 0) {
        utilities.myErrorReport('location 10 ' + 'no players  found when making pics', settings.misc.majorError);
    } else {
        let sideText = '';
        let playersSortedByName = [];
        sideText += '<div  class="players_cont"><div style="touch-action: pan-y;" class="players">';
        for (let k = 0; k < len; k++) {
            let thePlayer = settings.globals.players.find((z) => z.playerID === thePlayers[k].playerID);
            if (thePlayer === undefined) {
                utilities.myErrorReport(
                    'location 20 ' + 'player not found when searching by ID: ' + thePlayers[k].playerID,
                    settings.misc.majorError
                );
            } else {
                playersSortedByName.push([
                    manageData.getProperPlayerName(thePlayer, teamID),
                    thePlayer.playerID,
                    thePlayer.imagePath,
                ]);
            }
        }
        playersSortedByName.sort((a, b) => utilities.mySort(a, b));
        for (let k = 0; k < playersSortedByName.length; k++) {
            sideText += '<a class="sideBarFormat';
            if (k === 0) sideText += ' active ';
            sideText += ' player player-' + (k + 1) + '"data-playerID="' + playersSortedByName[k][1]; //PlayerID is stored here
            if (playersSortedByName[k][2]) {
                //if there is a URL to the pic
                sideText +=
                    '" href="#"><div class="img" style="background-image: url(\'' + //this is where the pic is added. it is in the [k][2]
                    playersSortedByName[k][2] +
                    "') " +
                    '">' +
                    '</div >';
            } else {
                sideText +=
                    '" href="#"><div class="img" style="background-image: url(\'' +
                    settings.globals.currentTourny.picURL +
                    playersSortedByName[k][1].substr(6, playersSortedByName[k][1].length - 6) + //this is a hack to remove the word legacy from the id
                    settings.globals.currentTourny.picsSuffix +
                    "') " +
                    '">' +
                    '</div >';
            }
            sideText += '<div class="player-name">' + playersSortedByName[k][0] + '</div></a>';
        }
        sideText += '</div></div>';
        leftBar.innerHTML = '';
        leftBar.innerHTML +=
            '<div class="players_team_title"><h2>' + manageData.getProperEntityName(teamID) + '</h2></div>';
        leftBar.innerHTML += sideText;
        addSidePicsClickHandler(teamID);
    }
}

/**
 * Please describe the following function here
 */
function addHeaderActiveClass() {
    let headerDiv = document.getElementById('HeaderTable');
    let current = headerDiv.getElementsByClassName('active');
    let currentDropdowns = headerDiv.getElementsByClassName('dropdown_main');

    function clearActives() {
        while (current.length > 0) {
            //keep checking length because the array of active elements gets updated after each remove
            current[0].classList.remove('active'); //make everything inactive before adding one back
        }
    }

    function clearAddedNames() {
        for (let k = 0; k < currentDropdowns.length; k++) {
            if (currentDropdowns[k].classList.contains('tourny_selectorBase')) {
                // do nothing, i.e don't clear the tourny
            } else currentDropdowns[k].setAttribute('data-addname', ''); // clear it //clear everything before adding one back
        }
    }

    let headerTable = document.getElementById('HeaderTable');
    if (headerTable) {
        let btns = headerTable.getElementsByClassName('nav-item');
        for (let i = 0; i < btns.length; i++) {
            btns[i].addEventListener('click', function (e) {
                e.preventDefault();

                let baseName = '';
                let menuSelected = e.target.innerHTML; // the menu that was clicked
                if (this.classList.contains('dropdown')) {
                    if (e.target.classList.contains('dropdown-item')) {
                        //handle dropdowns like this...

                        baseName = this.children[0].getAttribute('data-basename');
                        for (let k = 0; k < currentDropdowns.length; k++) {
                            if (currentDropdowns[k].classList.contains('tourny_selectorBase')) {
                                // do nothing, i.e don't clear the tourny
                            } else currentDropdowns[k].setAttribute('data-addname', ''); // clear it //clear everything before adding one back
                        }
                        clearAddedNames();
                        this.children[0].setAttribute('data-addname', menuSelected);
                        clearActives();
                        this.classList.add('active');
                    }
                } else {
                    clearAddedNames();
                    clearActives();
                    this.classList.add('active'); // not a dropdown
                }
                if (
                    !this.classList.contains('dropdown') ||
                    (this.classList.contains('dropdown') && e.target.classList.contains('dropdown-item'))
                ) {
                    let navOpen = document.querySelector('html').getAttribute('data-mobile_nav_open');
                    let theText = '';
                    if (navOpen === 'false') {
                        theText = 'OneWorld-Futbol';
                    } else {
                        if (baseName === '')
                            // just add the menu selected
                            theText += menuSelected;
                        // add the base name and the menu selected
                        else theText += baseName + ': ' + menuSelected;
                    }
                    document.getElementById('siteTitleLineThree').innerHTML = theText;

                    //document.querySelector('.world_cup_selectorBase').setAttribute('data-addname', theText);
                }
                document.getElementById('siteTitleLineFour').innerHTML = settings.globals.currentTourny.menuName;
                //only bounce out of mobile menu if choosing a legit menu-item
                document.querySelector('html').setAttribute('data-mobile_nav_open', 'false');
            });
        }
    }
}

/**
 * Please describe the following function here
 */
function addSideBarActiveClass() {
    if (leftBar) {
        let btnsLeftBar = leftBar.getElementsByClassName('nav-item');
        for (let i = 0; i < btnsLeftBar.length; i++) {
            btnsLeftBar[i].addEventListener('click', function () {
                // let temp = leftBar;
                let activeButtons = leftBar.getElementsByClassName('active');
                while (
                    activeButtons.length > 0 //keep checking length because the array of active elements gets update after each remove
                )
                    activeButtons[0].classList.remove('active');
                this.classList.add('active');
            });
        }
    }
}

/**
 * Please describe the following function here
 * @param baseEntityID
 * @param destinationEntityID
 */
function addPicsGroupActiveClass(baseEntityID, destinationEntityID) {
    let thePlayers = settings.globals.currentPlayersInTourny.filter((o) => o.playsForID === baseEntityID);
    if (thePlayers.length === 0) {
        utilities.myErrorReport(
            'location 40 ' + 'no players found for marker base entity: ' + baseEntityID,
            settings.misc.majorError
        );
    } else {
        if (leftBar) {
            let btnsLeftBar = leftBar.getElementsByClassName('player');
            for (let i = 0; i < btnsLeftBar.length; i++) {
                btnsLeftBar[i].classList.remove('active'); //highlight the button, could be several
                for (let k = 0; k < thePlayers.length; k++) {
                    let theClub = settings.globals.clubs.find(
                        (g) => g.clubID === thePlayers[k].clubID && g.clubLocationID === destinationEntityID
                    );
                    if (theClub === undefined) {
                        // do nothing if the club isn't found
                    } else {
                        //if (thePlayers[k].playerID === parseInt(btnsLeftBar[i].getAttribute('data-playerID'))) {
                        if (thePlayers[k].playerID === btnsLeftBar[i].getAttribute('data-playerID')) {
                            btnsLeftBar[i].classList.add('active'); //highlight the button, could be several
                        }
                    }
                }
            }
        }
    }
}

/**
 * Please describe the following function here
 */
function addSidePicsClickHandler(teamID) {
    if (leftBar) {
        let picBtnsLeftBar = leftBar.getElementsByClassName('player');
        for (let i = 0; i < picBtnsLeftBar.length; i++) {
            picBtnsLeftBar[i].addEventListener('click', function () {
                let activeButtons = leftBar.getElementsByClassName('active');
                // note activeButtons gets dynamically changed with each removal.  so that is why i have to check the length over and over
                while (activeButtons.length > 0) {
                    activeButtons[0].classList.remove('active');
                }
                this.classList.add('active');
                activeButtons = leftBar.getElementsByClassName('active');
                //let playerID = parseInt(this.getAttribute('data-playerID'));
                let playerID = this.getAttribute('data-playerID');
                let thePlayerInTourny = settings.globals.currentPlayersInTourny.find((m) => m.playerID === playerID);
                if (thePlayerInTourny === undefined) {
                    utilities.myErrorReport(
                        'location 50 ' + 'player not found when clicking on pic: ',
                        playerID,
                        settings.misc.majorError
                    );
                } else {
                    let player = settings.globals.players.find((k) => k.playerID === thePlayerInTourny.playerID);
                    let playerClub = settings.globals.clubs.find((k) => k.clubID === thePlayerInTourny.clubID);
                    if (player === undefined)
                        utilities.myErrorReport(
                            'location 60 ' + 'undefined not found when clicking on pic',
                            settings.misc.majorError
                        );
                    else {
                        let theLocationToUse = '';
                        if (playerClub === undefined) theLocationToUse = teamID;
                        else theLocationToUse = playerClub.clubLocationID;
                        let entityOfClub = settings.fifaEntities.find((m) => m.ID === theLocationToUse);
                        if (entityOfClub === undefined)
                            utilities.myErrorReport(
                                'location 70 ' + 'undefined entity when clicking on pic',
                                settings.misc.majorError
                            );
                        else {
                            let theNationality = null;
                            if (settings.globals.currentTourny.showNationality) theNationality = player.nationality;
                            console.log(thePlayerInTourny);
                            L.popup(settings.generalPopUpStyle)
                                .setLatLng([entityOfClub.latitude, entityOfClub.longitude])
                                .setContent(
                                    buildIndividualPlayerPopUp(
                                        manageData.getProperEntityName(theLocationToUse),
                                        manageData.getProperClubName(playerClub),
                                        player.name,
                                        utilities.calculateAge(
                                            player.birthMonth,
                                            player.birthDay,
                                            player.birthYear,
                                            settings.globals.currentTourny.startMonth,
                                            settings.globals.currentTourny.startDay,
                                            settings.globals.currentTourny.startYear
                                        ),
                                        thePlayerInTourny.position,
                                        thePlayerInTourny.jerseyNumber,
                                        thePlayerInTourny.caps,
                                        thePlayerInTourny.goals,
                                        player.height,
                                        player.weight,
                                        theNationality
                                    )
                                )
                                .openOn(settings.globals.map);
                        }
                    }
                }
            });
        }
    }
}

function replaceUserDependentMenu() {
    //document.getElementById('userDependentMenu').innerHTML = buildUserDependentMenu();
}

function buildHeader() {
    let tournyID = settings.globals.currentTourny.tournyID;
    let text = '';
    const theIndex = settings.tournys.findIndex((v) => v.tournyID === tournyID);

    text += '<div class="nonChangingPartOfHeader">'; //open div nonChangingPartOfHeader
    text += '<div id="siteTitleLineOne" class="siteTitle siteTitleShowOppositeOfHamburger">OneWorld-Futbol</div>';
    text += '<div id="siteTitleLineThree" class="siteTitle siteTitleShowLikeHamburger">Schedule</div>';
    text +=
        '<div id="siteTitleLineFour" class="siteTitle siteTitleShowLikeHamburger">' +
        settings.globals.currentTourny.menuName +
        '</div>';
    text += '<div id="HeaderTable2">'; //open div id HeaderTable2
    text += '</div>'; //close id HeaderTable2
    text += '</div>'; //close div nonChangingPartOfHeader

    text += '<div class="changingPartOfHeader">'; //open div changingPartOfHeader
    text += '<ul id = "HeaderTable">'; //open ul id HeaderTable

    //following needs to be changed to go with startUpLayer as defined in tourny so that the correct menu item gets highlighted

    text += '<li class="nav-item dropdown">'; //open li nav-item dropdown
    text +=
        '<div id="tournySelector" class="dropdown_main tourny_selectorBase" data-basename="Tournament" data-addname="';
    text += settings.globals.currentTourny.menuName;
    text += '">';
    text += '</div>';
    text += '<div class="dropdown-menu tourny_selectorMenu" data-loading="false" data-index="0">';
    settings.tournys.sort(function (a, b) {
        //reverse alpha sort (most recent date first)
        var x = a.menuName.toLowerCase();
        var y = b.menuName.toLowerCase();
        if (x < y) {
            return 1;
        }
        if (x > y) {
            return -1;
        }
        return 0;
    });
    for (let k = 0; k < settings.tournys.length; k++) {
        text +=
            '<a class="dropdown-item" href="/" data-tournyID="' +
            settings.tournys[k].tournyID +
            '">' +
            settings.tournys[k].menuName +
            '</a>';
    }

    text += '</div>'; // close div dropdown-menu tourny_selectorMenu
    text += '</li>'; //close li nav-item dropdown
    text += '<li class="nav-item"  onclick="switchModes(settings.layerIndices.aboutLayerIndex)">About</li>';
    if (settings.globals.currentTourny.useLeagueDashboard) {
        if (!settings.globals.currentTourny.playerListReady)
            text +=
                '<li class="nav-item"  onclick="switchModes(settings.layerIndices.wideLeagueDashboardLayerIndex)">Dashboard</li>';
        else
            text +=
                '<li class="nav-item"  onclick="switchModes(settings.layerIndices.leagueDashboardLayerIndex)">Dashboard</li>';
        text +=
            '<li class="nav-item" data-start_layer onclick="switchModes(settings.layerIndices.leagueScheduleLayerIndex)">Schedule</li>';
    } else {
        if (!settings.globals.currentTourny.playerListReady)
            text +=
                '<li class="nav-item"  onclick="switchModes(settings.layerIndices.wideDashboardLayerIndex)">Dashboard</li>';
        else
            text +=
                '<li class="nav-item"  onclick="switchModes(settings.layerIndices.dashboardLayerIndex)">Dashboard</li>';
        text +=
            '<li class="nav-item" data-start_layer onclick="switchModes(settings.layerIndices.scheduleLayerIndex)">Schedule</li>';
    }
    text += '<li class="nav-item dropdown">'; //open li nav-item dropdown
    text += '<div class="dropdown_main" data-basename="Results" data-addname=""></div>';
    text += '<div class="dropdown-menu">'; //open div dropdown-menu
    if (tournyID === 'MLS2022' || tournyID === 'EPL2022') {
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.leagueResultsLayerIndex)">All</a>';
        //text += '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.leagueSingleTeamResultsLayerIndex)">By Team</a>';
        /*         text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.sportMonksWidgetLayerIndex)">Full Details</a>';
 */
    }
    if (tournyID !== 'MLS2022' && tournyID !== 'EPL2022') {
        text += '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.r2LayerIndex)">Final</a>';
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.r4LayerIndex)">Semi-Finals</a>';
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.r8LayerIndex)">Qtr-Finals</a>';
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.r16LayerIndex)">Round Of Sixteen</a>';
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.r32LayerIndex)">Group Stage, Overall</a>';
    }
    if (tournyID !== 'MLS2022' && tournyID !== 'EPL2022') {
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.groupStageRoundThreeLayerIndex)">Group Stage, Round 3</a>';
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.groupStageRoundTwoLayerIndex)">Group Stage, Round 2</a>';
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.groupStageRoundOneLayerIndex)">Group Stage, Round 1</a>';
    }
    /* text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.resultsLayerIndex)">By Team</a>';
     */
    if (tournyID === 'MWC2018' || tournyID === 'MEuro2021' || tournyID === 'MWC2022' || tournyID === 'WWC2023')
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.newBracketLayerIndex)">Bracket</a>';
    else if (tournyID !== 'MLS2022' && tournyID !== 'EPL2022')
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.bracketLayerIndex)" >Bracket</a>';
    text += '</div>'; //close div dropdown-menu
    text += '</li>'; //close li nav-item dropdown
    if (tournyID !== 'MLS2022' && tournyID !== 'EPL2022') {
        text += '<li class="nav-item dropdown">'; //open li nav-item dropdown
        text += '<div class="dropdown_main" data-basename="Groups" data-addname=""></div>';
        text += '<div class="dropdown-menu">'; //open div dropdown-menu
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.groupLeadersLayerIndex)" >Group Leaders</a>';
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.groupTopTwoLayerIndex)" >Leaders and Second Place</a>';
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.groupsLayerIndex)" >All Teams</a>';
        text += '</div>'; //close div dropdown-menu
        text += '</li>'; //close li nav-item dropdown
    }
    if (tournyID !== 'MLS2022' && tournyID !== 'EPL2022')
        text +=
            '<li class="nav-item" onclick="switchModes(settings.layerIndices.qualifyingLayerIndex)">Qualifying</li>';
    if (
        tournyID !== 'WWC2019' && // no talent flow menu for women this year because most are not on clubs
        tournyID !== 'WWC2015' && // no talent flow menu for women this year because most are not on clubs
        tournyID !== 'MLS2022' &&
        tournyID !== 'EPL2022'
    ) {
        text += '<li class="nav-item dropdown">'; //open li nav-item dropdown
        text += '<div class="dropdown_main" data-basename="Talent Flow" data-addname=""></div>';
        text += '<div class="dropdown-menu">'; //open div dropdown-menu
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.talentFlowFromCountryLayerIndex)" >From Country</a>';
        text +=
            '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.talentFlowToCountryLayerIndex)">To Country</a>';
        if (tournyID !== 'MEuro2021')
            text +=
                '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.talentFlowFromConfederationLayerIndex)">From Confederation</a>';
        if (tournyID !== 'MEuro2021')
            text +=
                '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.talentFlowToConfederationLayerIndex)">To Confederation</a>';
        text += '</div>'; //close div dropdown-menu
        text += '</li>'; //close li nav-item dropdown
    }

    text += '<li class="nav-item dropdown">'; //open li nav-item dropdown
    text += '<div class="dropdown_main" data-basename="Videos" data-addname=""></div>';
    text += '<div class="dropdown-menu dropdown-menu-right">'; //open div dropdown-menu
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.MWCVideosLayerIndex)" >World Cup, 1930 - 2018</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.WWCVideosLayerIndex)" >Womens WC, 1990 - 2019</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.MEuroVideosLayerIndex)" >Euro, 1960 - 2020</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.greatMPlayerVideosLayerIndex)" >Great Players, Men</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.greatWPlayerVideosLayerIndex)" >Great Players, Women</a>';
    text += '</div>'; //close div dropdown-menu';
    text += '</li>'; //close li nav-item dropdown

    text += '<li class="nav-item dropdown">'; //open li nav-item dropdown
    text += '<div class="dropdown_main" data-basename="Insights" data-addname=""></div>';
    text += '<div class="dropdown-menu dropdown-menu-right">'; //open div dropdown-menu
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.MRankingsLayerIndex)" >Rankings</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.MWCHistoryLayerIndex)" >World Cup History</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.MWCFinalistsLayerIndex)" >World Cup Finals</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.MEuroHistoryLayerIndex)" >Euro History</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.MEuroFinalistsLayerIndex)" >Euro Finals</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.CopaAmericaHistoryLayerIndex)" >Copa America History</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.CopaAmericaFinalistsLayerIndex)" >Copa America Finals</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.WWCHistoryLayerIndex)" >Womens WC History</a>';
    text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.WWCFinalistsLayerIndex)" >Womens WC Finals</a>';
    /* text +=
        '<a class="dropdown-item" href="/" onclick="switchModes(settings.layerIndices.MBigFiveLayerIndex)" >Big Five</a>';
    */
    text += '</div>'; //close div dropdown-menu';
    text += '</li>'; //close li nav-item dropdown

    text += '<li class="nav-item dropdown">'; //open li nav-item dropdown
    text += '<div class="dropdown_main" data-basename="More" data-addname=""></div>';
    text += '<div class="dropdown-menu dropdown-menu-right">'; //open div dropdown-menu
    text +=
        '<a class="dropdown-item" data-coffee_layer href="/" onclick = "switchModes(settings.layerIndices.buyMeCoffeeLayerIndex)">Buy Us A Coffee</a>';
    text +=
        '<a class="dropdown-item"  data-sendEmail_layer href="/" onclick="switchModes(settings.layerIndices.contactLayerIndex)">Contact Us</a>';
    /*   text +=
        '<a class="dropdown-item" data-subscribe_layer href="/" onclick="switchModes(settings.layerIndices.subscribeLayerIndex)">Subscribe</a>';
   */
    // text +=
    //     '<a class="dropdown-item" data-logIn_layer href = "/" onclick = "switchModes(settings.layerIndices.logInLayerIndex)" >Log In</a > ';
    // text +=
    //     '<a class="dropdown-item"  href="/" onclick="switchModes(settings.layerIndices.logOutLayerIndex)">Log Out</a>';
    text +=
        '<a class="dropdown-item" data-terms_layer href="/" onclick="switchModes(settings.layerIndices.termsLayerIndex)">Terms Of Use</a>';
    text +=
        '<a class="dropdown-item" data-privacy_layer href="/" onclick="switchModes(settings.layerIndices.privacyLayerIndex)">Privacy Policy</a>';

    text += '</div>'; //close div dropdown-menu';
    text += '</li>'; //close li nav-item dropdown

    text += '</ul>'; //close ul id HeaderTable
    text += '</div>'; //close changingPartOfHeader
    text += '<div class="hamburger">'; //open div hamburger
    text += '<div class="menu_text">'; //open div menu_text
    text += '<span class="menu1"></span>'; //was 'menu', no text needed the 'X' is sufficeint
    text += '<span class="menu2"></span>'; //was 'close', no text needed the 'X' is sufficeint
    text += '</div>'; //close menu_text
    text += '<div class="nav_burger">'; //open div nav_burger
    text += '<div></div>';
    text += '<div></div>';
    text += '<div></div>';
    text += '</div>'; //close nav_burger
    text += '</div>'; // close hamburger
    return text;
}
/**
 * Please describe the following function here
 * @returns {string}
 * this builds all the code for the menu bar
 */

function buildDateForMatchOLD(year, month, day, time, twoLine) {
    let theReturnText = ''; //; '<div class="date">';
    if (time) {
        theReturnText += manageData.getCorrectTimeFormat(time);
        if (twoLine) theReturnText += '<br>';
        else theReturnText += ',&nbsp';
    }
    if (month) theReturnText += settings.months[month].shortName + '&nbsp';
    if (day) theReturnText += day + '&nbsp';
    if (year) theReturnText += year;
    return theReturnText;
}

function buildDateForMatch(year, month, day, time, twoLine) {
    let theReturnText = '';
    if (twoLine) {
        if (month) theReturnText += settings.months[month].shortName + '&nbsp';
        if (day) theReturnText += day + '&nbsp';
        if (year) theReturnText += year;
        theReturnText += '<br>';
        if (time) {
            theReturnText += manageData.getCorrectTimeFormat(time);
        }
    } else {
        if (time) {
            theReturnText += manageData.getCorrectTimeFormat(time);
            theReturnText += ',&nbsp';
        }
        if (month) theReturnText += settings.months[month].shortName + '&nbsp';
        if (day) theReturnText += day + '&nbsp';
        if (year) theReturnText += year;
    }
    return theReturnText;
}

function buildGroupPopUp(theEntityDisplayName, theGroupStandings) {
    return myBold(theEntityDisplayName) + theGroupStandings;
}

/**
 * Please describe the following function here
 */
function buildGroupPopUpBody(groupName, teamsInGroup) {
    let html = '';
    let i = 0;
    let len = teamsInGroup.length;
    let first_char = ['W', 'L', 'T', 'P', 'GF', 'GA', 'GD'];

    html += '<div class="group_table">';
    html += '<div>';
    html += '<div class="first">' + groupName + '</div>';
    for (i = 0; i < len; i++) {
        html += '<div class="firstColumn">' + manageData.getProperEntityName(teamsInGroup[i].ID) + '</div>';
    }
    html += '</div>';
    html += '<div>';
    html += '<div>';
    html += '<div class="first">' + first_char[0] + '</div>';
    for (i = 0; i < len; i++) {
        html += '<div>' + teamsInGroup[i].groupStageWins + '</div>';
    }
    html += '</div>';
    html += '<div>';
    html += '<div class="first">' + first_char[1] + '</div>';
    for (i = 0; i < len; i++) {
        html += '<div>' + teamsInGroup[i].groupStageLosses + '</div>';
    }
    html += '</div>';
    html += '<div>';
    html += '<div class="first">' + first_char[2] + '</div>';
    for (i = 0; i < len; i++) {
        html += '<div>' + teamsInGroup[i].groupStageTies + '</div>';
    }
    html += '</div>';
    html += '<div>';
    html += '<div class="first">' + first_char[3] + '</div>';
    for (i = 0; i < len; i++) {
        html += '<div>' + teamsInGroup[i].groupStagePoints + '</div>';
    }
    html += '</div>';
    html += '<div>';
    html += '<div class="first">' + first_char[4] + '</div>';
    for (i = 0; i < len; i++) {
        html += '<div>' + teamsInGroup[i].groupStageGoalsFor + '</div>';
    }
    html += '</div>';
    html += '<div>';
    html += '<div class="first">' + first_char[5] + '</div>';
    for (i = 0; i < len; i++) {
        html += '<div>' + teamsInGroup[i].groupStageGoalsAgainst + '</div>';
    }
    html += '</div>';
    html += '<div>';
    html += '<div class="first">' + first_char[6] + '</div>';
    for (i = 0; i < len; i++) {
        html += '<div>' + teamsInGroup[i].groupStageGoalDifferential + '</div>';
    }
    html += '</div>';
    html += '</div>';
    html += '</div>';

    return html;
}

function buildFromCountryMarkersPopUpTitle(location, adjective, tournyName) {
    return (
        '<div class="super_title"><strong>Clubs</strong>' +
        ' in ' +
        location +
        ' with ' +
        myBold(adjective) +
        '&nbsp' +
        '<br>' +
        tournyName +
        ' Players:<div>'
    );
}
function buildFromCountryMarkersPopUpBody(entries) {
    let returnText = '<table>';
    for (let theEntry of entries) {
        returnText += '<tr><td>' + myBold(theEntry[0]) + '</td><td>' + theEntry[1] + '</td></tr>';
    }
    returnText += '</table>';
    return returnText;
}

function buildFromCountryMarkersPopUp(location, adjective, entries, tournyName) {
    return (
        buildFromCountryMarkersPopUpTitle(location, adjective, tournyName) +
        '<br>' +
        buildFromCountryMarkersPopUpBody(entries)
    );
}

function buildToCountryMarkersPopUpTitle(location, adjective, tournyName) {
    return (
        '<div class="super_title">' +
        myBold(adjective) +
        '&nbsp' +
        tournyName +
        ' Players on ' +
        myBold('Clubs') +
        '<br>' +
        ' in ' +
        location +
        ':' +
        '<div>'
    );
}

function buildQualifyingHistoryPopUpBody(entries) {
    let returnText = '<table class="qualifyHistoryTable">';
    for (let theEntry of entries) {
        returnText +=
            '<tr>' +
            '<td>' +
            buildDateForMatch(null, theEntry[1], theEntry[2], null, false) + // don't include year or time
            '</td>' +
            '<td>' +
            theEntry[3] +
            '</td>' +
            '<td>' +
            theEntry[4] +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' +
            '</td>' +
            '<td>' +
            theEntry[5] +
            '</td>' +
            '<td>' +
            theEntry[6] +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' +
            '<br />' +
            '</td>' +
            '</tr>';
    }
    return (returnText += '</table>');
}

function buildToCountryMarkersPopUpBody(entries) {
    let returnText = '';
    for (let theEntry of entries) {
        returnText += '<tr><td>' + theEntry[1] + '</td><td>' + myBold(theEntry[0]) + '</td></tr>';
    }
    return returnText;
}

function buildToCountryMarkersPopUp(location, adjective, entries, tournyName) {
    return (
        '<table>' +
        buildToCountryMarkersPopUpTitle(location, adjective, tournyName) +
        '<br>' +
        buildToCountryMarkersPopUpBody(entries) +
        '</table>'
    );
}
/**
 * Please describe the following function here
 * @param baseEntityID
 * @param markerEntityID
 * @returns {string}
 */

function buildFanMarkersPopUp(countryName, theCityName, fanCount) {
    return theCityName + ', ' + myBold(countryName) + '<br>' + fanCount + ' Fans using this site!';
}

function handleCoffee() {
    window.open(settings.misc.coffeeLink, '_blank');
}

function handlePrivacy() {
    window.open(settings.misc.privacyLink, '_blank');
}

function handleTerms() {
    window.open(settings.misc.termsLink, '_blank');
}

function handleSendEmail() {
    window.open(settings.misc.emailLink, '_blank');
}

/**
 * For simplicity sake, all layers are dumped from the map,
 * each time a new layer is displayed.
 * That way, I don't have to keep track of which layers are on display.
 * (Sometimes more than one layer is on display.)
 */
function dumpLayers() {
    //remove all layers
    settings.globals.map.eachLayer(function (layer) {
        settings.globals.map.removeLayer(layer);
    });

    //remove all legends, add back as necessary
    for (const legendControl of settings.globals.legendControls) {
        if (legendControl) settings.globals.map.removeControl(legendControl);
    }

    for (const sliderControl of settings.globals.sliderControls) {
        if (sliderControl) settings.globals.map.removeControl(sliderControl);
    }

    manageData.setAttributesFalse();
}

function bringInDataViz() {
    leftBar.innerHTML = '';
}

function buildPrivacy() {
    let returnText = '<div class="generalTextDisplay">';
    returnText += 'Thank you for reading our Privacy Policy.';
    returnText += '</div>';
    leftBar.innerHTML = returnText;
}

function buildTerms() {
    let returnText = '<div class="generalTextDisplay">';
    returnText += 'Thank you for reading our Terms Of Use.';
    returnText += '</div>';
    leftBar.innerHTML = returnText;
}

function buildSubscribe() {
    let returnText =
        '<div class="generalTextDisplay">' +
        '<div id="mySpinner">' +
        '</div>' +
        '<div id="myFirebaseUI-Container">' +
        'For Advanced Features' +
        '<section id="firebaseui-auth-container">' +
        '</section>' +
        '</div>' +
        '<div>' +
        '<div id="subscribe">' +
        'Advanced Features' +
        '<br>' +
        '<div id="bulletList">' +
        '• Major League Soccer<br>' +
        '• English Premeire League<br>' +
        '• World Cup 2022<br>' +
        '• Womens World Cup 2023<br>' +
        '• Talent Flow<br>' +
        '• Videos<br>' +
        '• Insights<br><br>' +
        '<div>' +
        '<section class="products">' +
        '</section > ' +
        '</div>' +
        '<div>' +
        '<p>' +
        '</p > ' +
        '</div>' +
        '<template id="product">' +
        '<div class="product">' +
        '<div class="description">description' +
        '</div > ' +
        '<form class="product-form">' +
        '<select id="price" name="price">' +
        '</select > ' +
        '<button id="mySubscribeButton" type="submit">Subscribe' +
        '</button>' +
        '</form>' +
        '</div>' +
        '</template>' +
        '<link' +
        'type="text/css"' +
        'rel = "stylesheet"' +
        'href="https://cdn.firebase.com/libs/firebaseui/3.5.2/firebaseui.css"' +
        '/>';
    '</div>' + '</div>';
    leftBar.innerHTML = returnText;
}

function buildCoffee() {
    let returnText = '<div class="generalTextDisplay">';
    returnText += 'Thank you for the coffee!';
    returnText += '</div>';
    leftBar.innerHTML = returnText;
}

function addLayerAndExtras(whichLayerIndex) {
    //if necessary buildLeftBar
    if (settings.globals.layerParameters[whichLayerIndex].leftBarFunction) {
        settings.globals.layerParameters[whichLayerIndex].leftBarFunction();
    } else leftBar.innerHTML = '';

    //if necessary run a function for that layer
    if (settings.globals.layerParameters[whichLayerIndex].auxillaryFunction) {
        settings.globals.layerParameters[whichLayerIndex].auxillaryFunction();
    }

    //if necessary add an attribute
    if (settings.globals.layerParameters[whichLayerIndex].auxillaryAttributes) {
        for (const attribute of settings.globals.layerParameters[whichLayerIndex].auxillaryAttributes) {
            document.querySelector('html').setAttribute(attribute, 'true');
        }
    }

    //add the layer
    settings.globals.layers[whichLayerIndex].addTo(settings.globals.map);

    //if necessary add labels
    if (settings.globals.layerParameters[whichLayerIndex].labels) {
        settings.globals.layers[settings.globals.layerParameters[whichLayerIndex].labels].addTo(settings.globals.map);
    }
    //if necessary add a slider
    if (settings.globals.layerParameters[whichLayerIndex].sliderControlIndex) {
        settings.globals.sliderControls[settings.globals.layerParameters[whichLayerIndex].sliderControlIndex].addTo(
            settings.globals.map
        );
    }

    //if necessary add an auxillary layer
    if (settings.globals.layerParameters[whichLayerIndex].auxillaryLayerIndex) {
        settings.globals.map.addLayer(
            settings.globals.layers[settings.globals.layerParameters[whichLayerIndex].auxillaryLayerIndex]
        );
    }

    //if necessary add legend
    let addTheLegend = false;
    if (settings.globals.layerParameters[whichLayerIndex].legendControlIndex) {
        if (
            settings.globals.layerParameters[whichLayerIndex].legendControlIndex ===
                settings.legendControlIndices.confederationIndex ||
            settings.globals.layerParameters[whichLayerIndex].legendControlIndex ===
                settings.legendControlIndices.qualifyingIndex
        ) {
            if (settings.globals.currentTourny.confederationID === 0) {
                //for qualifying or confederation legends, only add for a worldwide tourny
                addTheLegend = true;
            }
        } else addTheLegend = true;
    }
    if (addTheLegend)
        settings.globals.legendControls[settings.globals.layerParameters[whichLayerIndex].legendControlIndex].addTo(
            settings.globals.map
        );

    const mapDiv = document.getElementById('map');
    const resizeObserver = new ResizeObserver(() => {
        settings.globals.map.invalidateSize();
    });
    resizeObserver.observe(mapDiv);
    setTimeout(() => {
        settings.globals.map.fitBounds(manageData.getRegionBounds(whichLayerIndex));
    }, 200);

    /*  DOESNT seem to work 
   settings.globals.map.eachLayer(function (layer) {
        //iterate over map rather than clusters
        if (layer.getChildCount()) {
            // if layer is markerCluster
            if (layer._childCount >= 1) {
                layer.spiderfy();
            }
        }
    }); */
}

function buildLegend(entries, title) {
    let returnText = title + '<br>';
    for (const entry of entries) {
        if (entry.altShape === 'dot')
            returnText +=
                '<span i style="background: ' +
                entry.color +
                '"class="legendDot"></span><span>' +
                entry.text +
                '</span><br>';
        else if (entry.altShape === 'circle')
            returnText +=
                '<span i style="background: ' +
                '#FFFFFF' +
                '"class="legendCircle"></span><span>' +
                entry.text +
                '</span><br>';
        else {
            returnText += '<i style="background: ' + entry.color + '"></i><span>' + entry.text;
            returnText += '<div class="legendPlainText">' + ' ' + entry.textPartTwo + '</div>';
            returnText += '</br>';
        }
    }
    return returnText;
}

function buildNoGreatPlayerPopUp(hostName) {
    return myBold(hostName) + ':<br>' + 'no players included yet.';
}

function buildNoHostPopUp(hostName, typeOfHost) {
    return myBold(hostName) + '<br>has never hosted a ' + typeOfHost + '.';
}

function buildHostPopUp(hostArray, typeOfHost) {
    let firstEntry = true;
    let theReturnText = '';
    for (const entry of hostArray) {
        if (firstEntry) {
            if (hostArray.length === 1) theReturnText += '<div>Host: ' + myBold(entry.hostOriginalName) + '</div>';
            else
                theReturnText +=
                    '<div>Host: ' + myBold(entry.hostOriginalName) + ' ' + hostArray.length + ' videos</div>';
            firstEntry = false;
        }
        theReturnText += '<div>';
        theReturnText += myBold(typeOfHost + ' Final, ') + myBold(entry.year) + '</div>';
        theReturnText +=
            '<div>' +
            entry.firstPlaceOriginalName +
            ' ' +
            entry.firstPlaceScore +
            ' - ' +
            entry.secondPlaceOriginalName +
            ' ' +
            entry.secondPlaceScore +
            '</div>';
        if (entry.pks === true) theReturnText += '<div>*Game won on penalty kicks.<br><br></div>';
        else theReturnText += '<div><br></div>';
        if (entry.youTubeID) {
            let videoLink = settings.misc.embedCode + entry.youTubeID;
            theReturnText +=
                '<div><iframe width="305" height="162" src=' +
                '"' +
                videoLink +
                '"' +
                ' frameborder="0" allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>' +
                '</iframe>' +
                '</div>';
        }
        theReturnText += '<div><br></div>';
    }
    return theReturnText;
}

function buildGreatPlayerPopUp(playerArray, playedForCountry) {
    let theReturnText = '<div>' + myBold(playedForCountry);
    if (playerArray.length > 1) theReturnText += myBold(', ' + playerArray.length + ' videos');
    theReturnText += '<br></div>';
    let theName = '';
    for (const entry of playerArray) {
        theReturnText += '<div>';
        if (entry.lastName) theName = entry.firstName + ' ' + entry.lastName;
        else theName = entry.firstName;
        theReturnText += myBold(theName) + ',&nbspBirthday: ';
        theReturnText += entry.birthDay + '&nbsp';
        theReturnText += settings.months[entry.birthMonth].shortName + '&nbsp';
        theReturnText += entry.birthYear;
        theReturnText += '</div>';
        if (entry.peak) theReturnText += '<div>Peak ' + entry.peak + '</div>';
        theReturnText += '<br><br>';
        let gotVideo = false;
        let videoLink = null;
        if (entry.youTubeID) {
            videoLink = settings.misc.embedCode + entry.youTubeID;
            gotVideo = true;
        } else if (entry.altVideo) {
            videoLink = settings.misc.embedCode + entry.altVideo;
            gotVideo = true;
        }
        if (gotVideo) {
            theReturnText +=
                '<div><iframe width="289" height="162" src=' +
                videoLink +
                ' frameborder="0" allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
        }
        theReturnText += '<div><br><br></div>';
    }
    return theReturnText;
}

function buildTeamRoster(
    includeNumber,
    includeAge,
    includeCaps,
    includeClub,
    includePosition,
    includeNationality,
    averageAge,
    averageCaps,
    oldestName,
    oldestAge,
    youngestName,
    youngestAge,
    playerArray
) {
    let html = myBold('Players');
    if (includeCaps) html += '<div>' + 'Average Caps: ' + averageCaps + '</div>';
    html += '<div>' + 'Average Age: ' + averageAge + '</div>';
    html += '<div>' + 'Oldest: ' + oldestName + ', ' + oldestAge + '</div>';
    html += '<div>' + 'Youngest: ' + youngestName + ', ' + youngestAge + '</div>';
    let i = 0;
    let len = playerArray.length;
    let first_char = ['#', 'Name', 'Pos', 'Club', 'Caps', 'Goals', 'Age', 'Nationality'];

    html += '<div class="roster_table">';
    html += '<div>';

    if (includeNumber) {
        html += '<div>';
        html += '<div class="first roster_jerseyNumber">' + first_char[0] + '</div>';
        for (i = 0; i < len; i++) {
            let theNumber = null;
            if (playerArray[i][0]) theNumber = playerArray[i][0];
            else theNumber = '&nbsp';
            html += '<div class="roster_jerseyNumber">' + theNumber + '</div>';
        }
        html += '</div>';
    }

    html += '<div>';
    html += '<div class="first roster_name">' + first_char[1] + '</div>';
    for (i = 0; i < len; i++) {
        html += '<div class="roster_name">' + playerArray[i][1] + '</div>';
    }
    html += '</div>';

    if (includePosition) {
        html += '<div>';
        html += '<div class="first rosterPosition">' + first_char[2] + '</div>';
        for (i = 0; i < len; i++) {
            html += '<div class="rosterPosition">' + playerArray[i][2] + '</div>';
        }
        html += '</div>';
    }

    if (includeAge) {
        html += '<div>';
        html += '<div class="first rosterAge">' + first_char[6] + '</div>';
        for (i = 0; i < len; i++) {
            let theValue = '';
            if (playerArray[i][6]) theValue = playerArray[i][6];
            html += '<div class="rosterAge">' + theValue + '</div>';
        }
        html += '</div>';
    }

    if (includeCaps) {
        html += '<div>';
        html += '<div class="first">' + first_char[4] + '</div>';
        for (i = 0; i < len; i++) {
            html += '<div>' + playerArray[i][4] + '</div>';
        }
        html += '</div>';
    }
    if (includeClub) {
        html += '<div>';
        html += '<div class="first rosterClubName">' + first_char[3] + '</div>';
        let clubName = '';
        for (i = 0; i < len; i++) {
            if (playerArray[i][3]) clubName = playerArray[i][3];
            else clubName = '&nbsp';
            html += '<div class="rosterClubName">' + clubName + '</div>';
        }
        html += '</div>';
    }
    if (includeNationality) {
        html += '<div>';
        html += '<div class="first">' + first_char[7] + '</div>';
        let nationality = '';
        for (i = 0; i < len; i++) {
            if (playerArray[i][7]) nationality = playerArray[i][7];
            else nationality = '&nbsp';
            html += '<div class="rosterClubName">' + nationality + '</div>';
        }
        html += '</div>';
    }

    html += '</div>';
    html += '</div>';

    return html;
}

function buildResultsIntermediateHeader(entityID) {
    let returnText = myBold(manageData.getProperEntityName(entityID));
    if (settings.globals.currentTourny.includeWinLostTieRecord) {
        let theResults = manageData.getPointsForASingleTeam(entityID);
        returnText += '<br>Wins: ' + theResults.wins + ', Losses: ' + theResults.losses + ', Ties: ' + theResults.ties;
        returnText += '<br>Points: ' + (3 * theResults.wins + theResults.ties);
    }
    /*     let theMatches = settings.globals.currentMatches.filter(
        (k) => (k.youTubeID || k.sportMonksVideoLink) && ((k.teamOneID === entityID)
       || (k.teamTwoID === entityID)) );
    let showTheVideoLink = false;
    if (theMatches.length > 0)
        //at least one match has a video
        showTheVideoLink = true;
    else if (settings.globals.currentTourny.buildVideoLink) {
        theMatches = settings.globals.currentMatches.filter((k) => ((k.matchCompleted && ((k.teamOneID === entityID)
       || (k.teamTwoID === entityID)))));
        if (theMatches.length > 0)
            showTheVideoLink = true;
    } */
    if (settings.globals.currentTourny.playerListReady) {
        //if there isn't a players list then the schedule (with or without videos will be seen directly, no need for the link)
        /* if (showTheVideoLink)
            returnText += '<br><a href="#scheduleAnchor"><div class="standardColor">' + myBold('Videos') + '</div></a>';
        //add a link to the schedule that will include those games with a video
        else returnText += '<br><a href="#scheduleAnchor">Schedule</a>'; */
        returnText += '<br><a href="#scheduleAnchor">' + myBold('Schedule') + '</a>';
    }
    if (settings.globals.currentTourny.historicalInfoAvailable) {
        returnText += '<br>';
        let theHostType = settings.hosts.find((k) => k.hostType === settings.globals.currentTourny.typeOfTourny);

        let first = true;
        let text = null;
        for (let theYear of theHostType.hostYears) {
            if (theYear.year < settings.globals.currentTourny.startYear) {
                //only include previous championships
                if (theYear.firstPlaceID === entityID) {
                    if (first) {
                        text = theYear.year;
                        first = false;
                    } else text += ', ' + theYear.year;
                }
            }
        }
        returnText += myBold('Previous ' + settings.globals.currentTourny.shortName + ' Champions: ');

        if (text !== null) {
            returnText += '<br>' + text + '<br><br>';
        } else returnText += '0<br>';

        let count = 0; //don't include current year
        for (let theYear of theHostType.hostYears) {
            if (theYear.year < settings.globals.currentTourny.startYear) {
                let result = theYear.participants.filter((m) => m.ID === entityID);
                count += result.length;
            }
        }

        returnText += myBold('Previous Appearances: ') + count;
        returnText += '<br>';
    }

    returnText += '<br>';
    if (settings.globals.currentTourny.showGroupInfo) {
        let groupID = manageData.getEntityGroupID(entityID);
        returnText += 'In ' + myBold('Group ' + groupID) + ' with:<br>';
        let teamsInGroup = settings.globals.currentTeams.filter((o) => o.groupID === groupID); // not error checking here because this would be caught on start up
        let fullNames = [];
        for (let theTeam of teamsInGroup)
            if (theTeam.ID !== entityID) fullNames.push(manageData.getProperEntityName(theTeam.ID));
        fullNames.sort();
        for (let i = 0; i < fullNames.length; i++) {
            if (i < fullNames.length - 1) returnText += fullNames[i] + ', ';
            else returnText += fullNames[i];
        }
        returnText += '<br>';
    }
    return returnText;
}

/*
 * @param whichLayerIndex
 * This function is the beginning of all steps necessary
 * to switch from one layer (or multiple layers) to others.
 */

function switchModes(whichLayerIndex) {
    if (settings.misc.enableSecurity) {
        let checkSecurity = false;
        if (
            settings.globals.currentTourny.loginRequired &&
            settings.globals.layerParameters[whichLayerIndex].loginRequired === 'tournamentDependent'
        )
            checkSecurity = true;
        else if (settings.globals.layerParameters[whichLayerIndex].loginRequired === 'always') checkSecurity = true;
        if (checkSecurity) {
            if (settings.globals.firebase.activeSubscription) {
                if (whichLayerIndex === settings.layerIndices.subscribeLayerIndex)
                    whichLayerIndex = settings.layerIndices.logInLayerIndex; //if already subscribed but the user chose subscription, just show them that they are already subscribed
                else; // there is a subscription, doNothing, just proceed
            } else {
                //take them through the subsciption process
                settings.globals.firebase.callBackLayerIndex = whichLayerIndex;
                whichLayerIndex = settings.layerIndices.subscribeLayerIndex; //force into subscription system
            }
        }
    }
    dumpLayers();
    addLayerAndExtras(whichLayerIndex);
}

function updateLegendTitle(entityName) {
    document.getElementById('versusField').innerHTML = 'Versus ' + entityName;
}

function updateLegendDate(theDate) {
    document.getElementById('dateGoesHere').innerHTML = theDate;
}
function noMatchOnGivenDay(whichTeamID) {
    return myBold(manageData.getProperEntityName(whichTeamID)) + ' did not play on selected date.';
}

window.handleVideo = manageVideo.handleVideo;
window.switchModes = switchModes;

export {
    updateLegendTitle,
    switchModes,
    handleCoffee,
    handlePrivacy,
    handleTerms,
    handleSendEmail,
    buildAbout,
    buildManageAccount,
    buildNewsLetter,
    buildTeamRoster,
    buildRankingsPopUp,
    buildUpcomingGamePopUp,
    //
    buildHostPopUp,
    buildNoHostPopUp,
    //
    buildNoGreatPlayerPopUp,
    buildGreatPlayerPopUp,
    //
    buildQualifyingHistoryPopUpBody,
    buildDidQualifyPopUp,
    buildDidNotQualifyPopUp,
    buildQualifyingPopUp,
    //
    buildLostInPreviousPopUp,
    buildDidAdvancePopUp,
    buildDidNotAdvancePopUp,
    //
    buildSingleMatchPopUp,
    buildNextLine,
    buildFullResultsPopUp,
    //
    buildTalentFlowToCountryPopUp,
    buildToCountryMarkersPopUp,
    //
    buildTalentFlowFromConfederationPopUp,
    buildTalentFlowToConfederationPopUp,
    //
    buildGroupPopUp,
    buildGroupPopUpBody,
    //
    buildTalentFlowFromCountryPopUp,
    buildFromCountryMarkersPopUp,
    //
    buildFanMarkersPopUp,
    //
    myBold,
    //
    addSideBarActiveClass,
    addHeaderActiveClass,
    addPicsGroupActiveClass,
    //
    buildSideBarPicsForCurrentFromCountry,
    buildSideBarPicsForCurrentTeam,
    //
    buildLegend,
    //
    dumpLayers,
    //
    initializeMenuAndSideBar,
    //
    buildLogOut,
    buildLogIn,
    buildComingSoon,
    buildEmailThankYou,
    buildCoffee,
    buildTerms,
    buildPrivacy,
    buildSubscribe,
    bringInDataViz,
    buildBracket,
    //
    buildSideBarText,
    buildResultsIntermediateHeader,
    replaceUserDependentMenu,
    updateLegendDate,
    noMatchOnGivenDay,
};
