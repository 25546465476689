import * as manageLayerEvents from './manageLayerEvents';
import * as manageLayers from './manageLayers';
import * as manageLayerText from './manageLayerText';
import * as manageLabels from './manageLabels';
import * as manageLayerColors from './manageLayerColors';
import * as htmlBuilder from './htmlBuilder';
import * as firebaseHelpers from './firebase/helpers';
import { isNull } from 'lodash';

function setUpLayerParameters() {
    //start with settings for label/marker layers

    /*   (settings.globals.layerParameters[settings.layerIndices.leagueSingleTeamResultsLabelsLayerIndex] = {
        popUpTextFunc: manageLayerText.getLeagueScheduleLayerText,
        popUpFunc: manageLabels.updateLeagueSingleTeamResultsMarkers,
        colorFunc: manageLabels.getGroupClass, //the basic layer has no conference colors, they get updated by the popup funciton
        markerSizeFunc: manageLabels.getBasicSize,
    }), */
    (settings.globals.layerParameters[settings.layerIndices.leagueResultsLabelsLayerIndex] = {
        popUpTextFunc: manageLayerText.getLeagueSingleResultText,
        popUpFunc: null,
        colorFunc: manageLabels.getLeagueRoundColor, //the basic layer has no conference colors, they get updated by the popup funciton
        markerSizeFunc: manageLabels.getBasicSize,
    }),
        (settings.globals.layerParameters[settings.layerIndices.leagueScheduleLabelsLayerIndex] = {
            popUpTextFunc: manageLayerText.getLeagueScheduleLayerText,
            popUpFunc: manageLabels.autoScrollToBestDate,
            colorFunc: manageLabels.getGroupClass,
            markerSizeFunc: manageLabels.getBasicSize,
        }),
        (settings.globals.layerParameters[settings.layerIndices.leagueDashboardLabelsLayerIndex] = {
            popUpTextFunc: manageLayerText.getLeagueDashboardLayerText,
            popUpFunc: manageLayers.updateLeagueDashboardSideBarPics,
            colorFunc: manageLabels.getGroupClass,
            markerSizeFunc: manageLabels.sizeByNumberOfLeaguePoints,
        });

    settings.globals.layerParameters[settings.layerIndices.scheduleLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-schedule_layer'],
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getBasicLayerColor,
        textFunc: manageLayerText.getScheduleLayerText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.qualifiedOrNotIndex,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildSideBarText,
    };

    settings.globals.layerParameters[settings.layerIndices.leagueScheduleLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-schedule_layer'],
        auxillaryLayerIndex: settings.layerIndices.leagueScheduleLabelsLayerIndex,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getBasicLayerColor,
        textFunc: null,
        onEachFunc: null,
        labels: null,
        legendControlIndex: settings.legendControlIndices.leagueScheduleIndex,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildSideBarText,
    };

    settings.globals.layerParameters[settings.layerIndices.groupsLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getGroupLayerColor,
        textFunc: manageLayerText.getGroupLayerText,
        onEachFunc: manageLayerEvents.groupsLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.groupsIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.groupTopTwoLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getGroupTopTwoLayerColor,
        textFunc: manageLayerText.getGroupLayerText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature, //no opacity change
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.groupTopTwoIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.groupLeadersLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getGroupLeadersLayerColor,
        textFunc: manageLayerText.getGroupLayerText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.groupLeadersIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.dashboardLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-dashboard_layer'],
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getDashboardLayerColor,
        textFunc: manageLayerText.getDashboardLayerText,
        onEachFunc: manageLayerEvents.dashboardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.winLoseTieVersusIndex,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildSideBarPicsForCurrentTeam,
    };

    settings.globals.layerParameters[settings.layerIndices.leagueDashboardLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-dashboard_layer'],
        auxillaryLayerIndex: settings.layerIndices.leagueDashboardLabelsLayerIndex,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getBasicLayerColor,
        textFunc: null,
        onEachFunc: null,
        labels: null,
        legendControlIndex: settings.legendControlIndices.conferencesIndex,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildSideBarPicsForCurrentTeam,
    };

    /* settings.globals.layerParameters[settings.layerIndices.leagueSingleTeamResultsLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: settings.layerIndices.leagueSingleTeamResultsLabelsLayerIndex,
        loginRequired: false,
        colorFunc: manageLayerColors.getBasicLayerColor,
        textFunc: null,
        onEachFunc: null,
        labels: null,
        legendControlIndex: settings.legendControlIndices.winLoseTieLeagueIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    }; */

    settings.globals.layerParameters[settings.layerIndices.leagueResultsLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: settings.layerIndices.leagueResultsLabelsLayerIndex,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getBasicLayerColor,
        textFunc: null,
        onEachFunc: null,
        labels: null,
        legendControlIndex: settings.legendControlIndices.winLoseTieLeagueIndex,
        sliderControlIndex: settings.sliderControlIndices.leagueResultsSliderIndex,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.wideDashboardLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getDashboardLayerColor,
        textFunc: manageLayerText.getDashboardLayerText,
        onEachFunc: manageLayerEvents.wideDashboardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.winLoseTieVersusIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.bracketLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: settings.layerIndices.bracketImageLayerIndex,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getSolidColor,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.newBracketLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildBracket,
    };

    settings.globals.layerParameters[settings.layerIndices.aboutLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildAbout,
    };

    settings.globals.layerParameters[settings.layerIndices.comingSoonLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildComingSoon,
    };

    settings.globals.layerParameters[settings.layerIndices.newsLetterLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildNewsLetter,
    };

    settings.globals.layerParameters[settings.layerIndices.manageAccountLayerIndex] = {
        auxillaryFunction: firebaseHelpers.manageAccount,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildManageAccount,
    };

    settings.globals.layerParameters[settings.layerIndices.buyMeCoffeeLayerIndex] = {
        auxillaryFunction: htmlBuilder.handleCoffee,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildCoffee,
    };

    settings.globals.layerParameters[settings.layerIndices.privacyLayerIndex] = {
        auxillaryFunction: htmlBuilder.handlePrivacy,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildPrivacy,
    };

    settings.globals.layerParameters[settings.layerIndices.termsLayerIndex] = {
        auxillaryFunction: htmlBuilder.handleTerms,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildTerms,
    };

    settings.globals.layerParameters[settings.layerIndices.subscribeLayerIndex] = {
        auxillaryFunction: firebaseHelpers.handleLogInAndSubscribe,
        auxillaryAttributes: ['data-leftBar100', 'data-leftBar100-Opaque'],
        auxillaryLayerIndex: null,
        loginRequired: true,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildSubscribe,
    };

    settings.globals.layerParameters[settings.layerIndices.logInLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildLogIn,
    };

    settings.globals.layerParameters[settings.layerIndices.logOutLayerIndex] = {
        auxillaryFunction: firebaseHelpers.mySignOut,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildLogOut,
    };

    settings.globals.layerParameters[settings.layerIndices.contactLayerIndex] = {
        auxillaryFunction: htmlBuilder.handleSendEmail,
        auxillaryAttributes: ['data-leftBar100'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.doNothing_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildEmailThankYou,
    };

    settings.globals.layerParameters[settings.layerIndices.r32LayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getColorsForRound,
        textFunc: manageLayerText.getTextForRound,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.advancedOrNotTo16Index,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.r16LayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getColorsForRound,
        textFunc: manageLayerText.getTextForRound,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.advancedOrNotTo8Index,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.r8LayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getColorsForRound,
        textFunc: manageLayerText.getTextForRound,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.advancedOrNotTo4Index,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.r4LayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getColorsForRound,
        textFunc: manageLayerText.getTextForRound,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.advancedOrNotTo2Index,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.r2LayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getColorsForRound,
        textFunc: manageLayerText.getTextForRound,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.championIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.qualifyingLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getQualifyingLayerColor,
        textFunc: manageLayerText.getQualifyingLayerText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.qualifyingIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.talentFlowFromCountryLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-talentFlowFromCountry_layer'],
        auxillaryLayerIndex: settings.layerIndices.fromCountryMarkersLayerIndex,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getTalentFlowFromCountryLayerColor,
        textFunc: manageLayerText.getTalentFlowFromCountryLayerText,
        onEachFunc: manageLayerEvents.talentFlowFromCountry_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.talentFlowFromCountryIndex,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.buildSideBarPicsForCurrentFromCountry,
    };

    settings.globals.layerParameters[settings.layerIndices.talentFlowToCountryLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: settings.layerIndices.toCountryMarkersLayerIndex,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getTalentFlowToCountryLayerColor,
        textFunc: manageLayerText.getTalentFlowToCountryLayerText,
        onEachFunc: manageLayerEvents.talentFlowToCountry_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.talentFlowToCountryIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.talentFlowFromConfederationLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: settings.layerIndices.talentFlowFromConfederationMarkersLayerIndex,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getTalentFlowByConfederationColor,
        textFunc: manageLayerText.getTalentFlowFromConfederationLayerText,
        onEachFunc: manageLayerEvents.talentFlowFromConfederation_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.confederationIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.talentFlowToConfederationLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: settings.layerIndices.talentFlowToConfederationMarkersLayerIndex,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getTalentFlowByConfederationColor,
        textFunc: manageLayerText.getTalentFlowToConfederationLayerText,
        onEachFunc: manageLayerEvents.talentFlowToConfederation_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.confederationIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.groupStageRoundOneLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getColorsForPreliminaryRound,
        textFunc: manageLayerText.getPreliminaryRoundText,
        onEachFunc: manageLayerEvents.groupsLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.winLoseTieBasicIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.groupStageRoundTwoLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getColorsForPreliminaryRound,
        textFunc: manageLayerText.getPreliminaryRoundText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.winLoseTieBasicIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.groupStageRoundThreeLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getColorsForPreliminaryRound,
        textFunc: manageLayerText.getPreliminaryRoundText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.basicLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.winLoseTieBasicIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.MWCHistoryLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishMWCHistory'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.sportMonksWidgetLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-mySportMonksWidget'],
        auxillaryLayerIndex: null,
        loginRequired: 'tournamentDependent',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.WWCHistoryLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishWWCHistory'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.CopaAmericaHistoryLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishCopaAmericaHistory'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.MEuroHistoryLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishMEuroHistory'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.MWCFinalistsLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishMWCFinalists'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.MRankingsLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishMRankings'],
        auxillaryLayerIndex: null,
        loginRequired: false,
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.MBigFiveLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishMBigFive'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.WWCFinalistsLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishWWCFinalists'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.CopaAmericaFinalistsLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishCopaAmericaFinalists'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.MEuroFinalistsLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: ['data-leftBar100', 'data-flourishMEuroFinalists'],
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getNoColorNeeded,
        textFunc: null,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: null,
        legendControlIndex: null,
        sliderControlIndex: null,
        leftBarFunction: htmlBuilder.bringInDataViz,
    };

    settings.globals.layerParameters[settings.layerIndices.MWCVideosLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getMWCVideosLayerColor,
        textFunc: manageLayerText.getMWCVideosLayerText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.MWCVideosLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.tournyHostIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.WWCVideosLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getWWCVideosLayerColor,
        textFunc: manageLayerText.getWWCVideosLayerText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.WWCVideosLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.tournyHostIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.MEuroVideosLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getMEuroVideosLayerColor,
        textFunc: manageLayerText.getMEuroVideosLayerText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.MEuroVideosLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.tournyHostIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.greatMPlayerVideosLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getGreatMPlayerVideosLayerColor,
        textFunc: manageLayerText.getGreatMPlayerVideosLayerText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.greatMPlayersLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.greatPlayerIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };

    settings.globals.layerParameters[settings.layerIndices.greatWPlayerVideosLayerIndex] = {
        auxillaryFunction: null,
        auxillaryAttributes: null,
        auxillaryLayerIndex: null,
        loginRequired: 'always',
        colorFunc: manageLayerColors.getGreatWPlayerVideosLayerColor,
        textFunc: manageLayerText.getGreatWPlayerVideosLayerText,
        onEachFunc: manageLayerEvents.standardLayer_OnEachFeature,
        labels: settings.layerIndices.greatWPlayersLabelsLayerIndex,
        legendControlIndex: settings.legendControlIndices.greatPlayerIndex,
        sliderControlIndex: null,
        leftBarFunction: null,
    };
}

export { setUpLayerParameters };
