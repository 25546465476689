import * as manageLayers from './manageLayers';
import * as manageLayerColors from './manageLayerColors';

function doNothing_OnEachFeature() {
    // do nothing
}

function groupsLayer_OnEachFeature(feature, layer) {
    layer.on({
        click: manageLayers.doGroupsLayer,
        mouseover: manageLayerColors.highlightFeature,
        mouseout: manageLayerColors.unHighlightFeature,
    });
}

/**
 * Please describe the following function here
 * @param feature
 * @param layer
 */
function dashboardLayer_OnEachFeature(feature, layer) {
    //no reset
    layer.on({
        click: manageLayers.doDashboardLayer,
        mouseover: manageLayerColors.highlightFeature,
        mouseout: manageLayerColors.unHighlightFeature,
    });
}

function wideDashboardLayer_OnEachFeature(feature, layer) {
    //no reset
    layer.on({
        click: manageLayers.doWideDashboardLayer,
        mouseover: manageLayerColors.highlightFeature,
        mouseout: manageLayerColors.unHighlightFeature,
    });
}

/**
 * Please describe the following function here
 * @param feature
 * @param layer
 */
function talentFlowToCountry_OnEachFeature(feature, layer) {
    layer.on({
        click: manageLayers.doTalentFlowToCountry,
        mouseover: manageLayerColors.highlightFeature,
        mouseout: manageLayerColors.unHighlightFeature,
    });
}

/**
 * Please describe the following function here
 * @param feature
 * @param layer
 */
function talentFlowFromCountry_OnEachFeature(feature, layer) {
    //no reset
    layer.on({
        click: manageLayers.doTalentFlowFromCountry,
        mouseover: manageLayerColors.highlightFeature,
        mouseout: manageLayerColors.unHighlightFeature,
    });
}

/**
 * Please describe the following function here
 * @param feature
 * @param layer
 */
function talentFlowFromConfederation_OnEachFeature(feature, layer) {
    layer.on({
        click: manageLayers.doTalentFlowFromConfederation,
        mouseover: manageLayerColors.highlightFeature,
        mouseout: manageLayerColors.unHighlightFeature,
    });
}

function talentFlowToConfederation_OnEachFeature(feature, layer) {
    layer.on({
        click: manageLayers.doTalentFlowToConfederation,
        mouseover: manageLayerColors.highlightFeature,
        mouseout: manageLayerColors.unHighlightFeature,
    });
}

/**
 * Please describe the following function here
 * @param feature
 * @param layer
 */
function standardLayer_OnEachFeature(feature, layer) {
    layer.on({
        mouseover: manageLayerColors.highlightFeature,
        mouseout: manageLayerColors.unHighlightFeature,
    });
}

/**
 * Please describe the following function here
 * @param e
 */

export {
    doNothing_OnEachFeature,
    groupsLayer_OnEachFeature,
    //resultsLayer_OnEachFeature,
    dashboardLayer_OnEachFeature,
    wideDashboardLayer_OnEachFeature,
    talentFlowToCountry_OnEachFeature,
    talentFlowFromCountry_OnEachFeature,
    talentFlowFromConfederation_OnEachFeature,
    talentFlowToConfederation_OnEachFeature,
    standardLayer_OnEachFeature,
};
