import * as manageLabels from './manageLabels';
import * as utilities from './utilities';

function buildArrayOfUniquePlayDates() {
    let theTitles = [];
    let playDateRoundIDs = [];
    let theMatches = utilities.sortMatchesChrono(settings.globals.currentMatches, false);
    let previousPlayDateRoundID = -1;
    let lastRoundID = -1;
    let aMatch = null;
    for (let m = 0; m < theMatches.length; m++) {
        // use this loop to get all the unique play dates
         aMatch = theMatches[m];
        if (settings.globals.currentTourny.hasRoundIDs) {
            if (aMatch.sportMonksRoundID !== lastRoundID) {
                theTitles.push(''); // dont show labels, but the titles need to exist
                playDateRoundIDs.push(aMatch.sportMonksRoundID);
                lastRoundID = aMatch.sportMonksRoundID;
            }
        } else {
            // if there are not rounds look for unique playDates
            if (aMatch.playDateRoundID !== previousPlayDateRoundID) {
                theTitles.push(''); // dont show labels, but the titles need to exist
                playDateRoundIDs.push(aMatch.playDateRoundID);
                previousPlayDateRoundID = aMatch.playDateRoundID;
            }
        }
    }
    return {
        theTitles: theTitles,
        playDateRoundIDs: playDateRoundIDs,
    };
}

function buildSliders() {//as of 8/22 there is only one slider
    console.log("buyilding slsiders");
    const { theTitles, playDateRoundIDs } = buildArrayOfUniquePlayDates();
    console.log(theTitles);
    console.log(playDateRoundIDs);
    settings.globals.sliderControls[settings.sliderControlIndices.leagueResultsSliderIndex] = L.control.timelineSlider({
        extraChangeMapParams: { notUsed: 'not used!' },
        changeMap: manageLabels.updateLeagueResultsMarkersAndLegend,
        activeColor: settings.colors.sliderColor,
        timelineItems: theTitles,
        playDateRoundIDs: playDateRoundIDs,
        initializeChange: false, //would be nice to set to true, but causes bug with legend
        inactiveColor: settings.colors.sliderColor,
        labelWidth: '6px',
        position: 'bottomleft',
        thumbHeight: '8px',
        labelFontSize: '10px',
        leftBgPadding: '10px',
        bottomBgPadding: '10px',
        backgroundOpacity: 0,
    });
}
export { buildSliders };
