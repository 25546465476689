import fifaEntities from './dataFIFAEntities';
import fans from './dataFans';
import matches from './dataMatches';
import teams from './dataTeams';
import players from './dataPlayers';
import clubs from './dataClubs';
import groups from './dataGroups';

import rankings from './dataRankings';

import playersInTourny from './dataPlayerTournys';
import confederationQualifiers from './dataConfederationQualifiers';
import tournys from './dataTournys';
import confederations from './dataConfederations';
import dataPolygonsFirst from './dataPolygonsFirst';
import hosts from './dataHosts';

function doBigDataChk() {
    if (settings.misc.chkDataIntegrity) {
        let j = 0;
        let k = 0;

        console.log('check p1: each club should have a unique id');
        let myList = [];
        for (k = 0; k < clubs.length; k++) myList.push(clubs[k].clubID);
        let temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (clubs.length != temp.length) {
            console.log('....error: clubs has duplicates ');
        }

        console.log('check p2: each confederationQualifier should have a unique tourny id');
        myList = [];
        for (k = 0; k < confederationQualifiers.length; k++) myList.push(confederationQualifiers[k].tournyID);
        temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (confederationQualifiers.length != temp.length) {
            console.log("....error: confederationQualifiers (tourny ID's) has duplicates ");
        }

        console.log('check p3: each country in a tourny should have a unique country id');
        for (j = 0; j < confederationQualifiers.length; j++) {
            myList = [];
            for (k = 0; k < confederationQualifiers[j].qualifiers.length; k++)
                myList.push(confederationQualifiers[j].qualifiers[k].ID);
            temp = myList.filter((v, i, a) => a.indexOf(v) === i);
            if (confederationQualifiers[j].qualifiers.length != temp.length) {
                console.log(
                    '....error: confederationQualifiers in a tourny has duplicates ',
                    confederationQualifiers[j].tournyID
                );
            }
        }

        console.log('check p4: each confederation should have a unique id');
        myList = [];
        for (k = 0; k < confederations.length; k++) myList.push(confederations[k].confederationID);
        temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (confederations.length != temp.length) {
            console.log('....error: confederations has duplicates ');
        }

        console.log('check p5a: each fifaEntity should have a unique id');
        myList = [];
        for (k = 0; k < fifaEntities.length; k++) myList.push(fifaEntities[k].ID);
        temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (fifaEntities.length != temp.length) {
            console.log('....error: fifaEntities has duplicates ');
        }

        /* console.log("check p5b: each fifaEntity should have a unique levelTwoID (if it is not null)");  LEVEL TWO ID IS NOT USED (as of 10/2020)
	  myList = [];
	  fifaEntities.sort();
	  for (k = 0; k < fifaEntities.length; k++) {
		if (fifaEntities[k].levelTwoID !== null)	myList.push(fifaEntities[k].levelTwoID)
	  }
	  temp  = myList.filter((v, i, a) => a.indexOf(v) === i);
	  if (fifaEntities.length != temp.length) {
		console.log("....error: fifaEntities has levelTwoID duplicates ")
	  } */

        console.log('check p6: each group should have a unique id');
        myList = [];
        for (k = 0; k < groups.length; k++) myList.push(groups[k].groupID);
        temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (groups.length != temp.length) {
            console.log('....error: groups has duplicates ');
        }

        console.log('check p7: each match should have a unique tourny id');
        myList = [];
        for (k = 0; k < matches.length; k++) myList.push(matches[k].tournyID);
        temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (matches.length != temp.length) {
            console.log("....error: matches (tourny ID's) has duplicates ");
        }

        console.log('check p8: each match in a tourny should have a unique id');
        for (j = 0; j < matches.length; j++) {
            myList = [];
            for (k = 0; k < matches[j].matches.length; k++) myList.push(matches[j].matches[k].matchID);
            temp = myList.filter((v, i, a) => a.indexOf(v) === i);
            if (matches[j].matches.length != temp.length) {
                console.log('....error: matches in a give tourny has duplicates ', matches[j].tournyID);
            }
        }

        console.log('check p9: each player should have a unique id');
        myList = [];
        for (k = 0; k < players.length; k++) myList.push(players[k].playerID);
        temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (players.length != temp.length) {
            console.log('....error: players has duplicates ');
        }

        console.log('check p10: each playerTourny should have a unique tourny id');
        myList = [];
        for (k = 0; k < playersInTourny.length; k++) myList.push(playersInTourny[k].tournyID);
        temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (playersInTourny.length != temp.length) {
            console.log("....error: playersInTourny (tourny ID's) has duplicates ");
        }

        console.log('check p11: each player in a tourny should have a unique id');
        for (j = 0; j < playersInTourny.length; j++) {
            myList = [];
            for (k = 0; k < playersInTourny[j].players.length; k++) myList.push(playersInTourny[j].players[k].playerID);
            temp = myList.filter((v, i, a) => a.indexOf(v) === i);
            if (playersInTourny[j].players.length != temp.length) {
                console.log('....error: players in a give tourny has duplicates ', playersInTourny[j].tournyID);
            }
        }

        console.log('check p12: each polygons.features should have a unique id');
        myList = [];
        for (k = 0; k < dataPolygonsFirst.features.length; k++) myList.push(dataPolygonsFirst.features[k].id);
        temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (dataPolygonsFirst.features.length != temp.length) {
            console.log('....error: polygons.features has duplicates ');
        }

        console.log('check p13: each team should have a unique tourny id');
        myList = [];
        for (k = 0; k < teams.length; k++) myList.push(teams[k].tournyID);
        temp = myList.filter((v, i, a) => a.indexOf(v) === i);
        if (teams.length != temp.length) {
            console.log("....error: playerTournys (tourny ID's) has duplicates ");
        }

        console.log('check p14: each team in a tourny should have a unique id');
        for (j = 0; j < teams.length; j++) {
            myList = [];
            for (k = 0; k < teams[j].teams.length; k++) myList.push(teams[j].teams[k].ID);
            temp = myList.filter((v, i, a) => a.indexOf(v) === i);
            if (teams[j].teams.length != temp.length) {
                console.log('....error: teams in a give tourny has duplicates ', teams[j].tournyID);
            }
        }

        // club -->  fifaEntities, secondary check to players
        console.log(
            'check 10: each club should have only one fifaEntity and check to see if any players have a problematic club'
        );
        for (j = 0; j < clubs.length; j++) {
            let entities = fifaEntities.filter((z) => z.ID === clubs[j].clubLocationID);
            if (entities === undefined) console.log('....error: entities undefined ', clubs[j].id);
            else if (entities.length != 1) {
                let thePlayers = playersInTourny.filter((z) => z.clubID === clubs[j].clubID);
                if (thePlayers.length > 0)
                    //players with that same problematic clubID
                    console.log("....error:  club isn't  a fifa entity AND that club has players!", clubs[j].clubID);
            }
        }

        // confederationQualifier -->  tourny
        console.log('check 20: each confederation qualifier should have  legit tourny');
        for (j = 0; j < confederationQualifiers.length; j++) {
            let theTourny = tournys.filter((z) => z.tournyID === confederationQualifiers[j].tournyID);
            if (theTourny === undefined)
                console.log('....error: theTourny undefined for', confederationQualifiers[j].tournyID);
            else if (theTourny.length != 1) {
                console.log('....error:  not finding exactly one tourny: ', confederationQualifiers[j].tournyID);
            }
        }

        // confederationQualifier  --> fifaEntities
        console.log('check 21: each confederation qualifier should have legit fifa entities');
        for (j = 0; j < confederationQualifiers.length; j++) {
            let theQualifiers = confederationQualifiers[j].qualifiers;
            for (k = 0; k < theQualifiers.length; k++) {
                let theFifaEntity = fifaEntities.filter((z) => z.ID === theQualifiers[k].ID);
                if (theFifaEntity === undefined)
                    console.log('....error: theFifaEntity undefined for', theQualifiers[k].ID);
                else if (theFifaEntity.length != 1) {
                    console.log(
                        '....error: theFifaEntity qualifier not finding exactly one fifa entity: ',
                        theQualifiers[k].ID
                    );
                }
            }
        }

        console.log(
            'check 21a: where appropriate, each confederation qualifier should the right number of qualified teams and total teams. '
        );
        for (j = 0; j < confederationQualifiers.length; j++) {
            let theTournys = tournys.filter((z) => z.tournyID === confederationQualifiers[j].tournyID);
            if (theTournys === undefined)
                console.log('....error: theTourny undefined for', confederationQualifiers[j].tournyID);
            else if (theTournys.length != 1)
                console.log('....error:  not finding exactly one tourny: ', confederationQualifiers[j].tournyID);
            else if (
                theTournys[0].functionToManageQualifying === 'showHistoryQualifying' ||
                theTournys[0].functionToManageQualifying === 'showRoundQualifying'
            ) {
                let qualifiedTeams = confederationQualifiers[j].qualifiers.filter((z) => z.statusObtained === 'Q');
                if (theTournys[0].numOfGroups * theTournys[0].teamsPerGroup !== qualifiedTeams.length)
                    console.log('....error:  wrong number of qualified teams: ', confederationQualifiers[j].tournyID);
                if (theTournys[0].functionToManageQualifying === 'showHistoryQualifying') {
                    let entities = fifaEntities.filter((z) => z.confederationID === theTournys[0].confederationID);
                    if (entities === undefined)
                        console.log('....error:  fifaEntities undefined: ', confederationQualifiers[j].tournyID);
                    else if (entities.length !== confederationQualifiers[j].qualifiers.length)
                        console.log(
                            '....error:  some fifaEntities not accounted for: ',
                            confederationQualifiers[j].tournyID
                        );
                }
                if (theTournys[0].functionToManageQualifying === 'showRoundQualifying') {
                    if (confederationQualifiers[j].qualifiers.length !== fifaEntities.length)
                        console.log(
                            '....error:  some fifaEntities not accounted for: ',
                            confederationQualifiers[j].tournyID
                        );
                }
            }
        }

        // confed -->  fifaEntities
        console.log('check 25: each confed should have some fifa entities');
        for (j = 1; j < 7; j++) {
            // j=== 0 is a place holder to identify tournys not linked to a single confederation
            let entities = fifaEntities.filter((z) => z.confederationID === confederations[j].confederationID);
            if (entities === undefined)
                console.log(
                    '....error: entities undefined ',
                    confederations[j].confederationID,
                    confederations[j].confederationID
                );
            else if (entities.length < 2) {
                console.log('....error: confed w/o at least 2 fifa entities: ', confederations[j].confederationID);
            }
        }

        // fifaEntity -->  confed
        console.log('check 30: each fifaEntity should have exactly one confederation');
        for (j = 0; j < fifaEntities.length; j++) {
            let theConfeds = confederations.filter((z) => z.confederationID === fifaEntities[j].confederationID);
            if (theConfeds === undefined)
                console.log('....error: theConfeds undefined ', fifaEntities[j].confederationID);
            else if (theConfeds.length != 1) {
                console.log(
                    '....error: fifa entity not finding exactly one confederation: ',
                    fifaEntities[j].confederationID
                );
            }
        }

        // fifaEntities -->  dataPolygonsFirst
        console.log('check 31: each fifaEntity should have exactly 1 polygon');
        let foundOneOrMore = false;
        let searchTerm = '';
        for (k = 0; k < fifaEntities.length; k++) {
            let foundPolygons = dataPolygonsFirst.features.filter((m) => m.id === fifaEntities[k].ID);
            if (foundPolygons === undefined) {
                console.log('....error: polygon undefined ', fifaEntities[k].ID);
            } else if (foundPolygons.length != 1) {
                console.log('....error:  fifaEntity not finding exactly one polygon:', fifaEntities[k].ID);
            }
        }

        //no id's in other json files are referred to within groups. so nothing to check

        // matches  --> tourny
        console.log('check 40: each tourny of matches should have legit tourny');
        for (j = 0; j < matches.length; j++) {
            let theTourny = tournys.filter((z) => z.tournyID === matches[j].tournyID);
            if (theTourny === undefined) console.log('....error: theTourny undefined for', matches[j].tournyID);
            else if (theTourny.length != 1) {
                console.log('....error:  not finding exactly one tourny: ', matches[j].tournyID);
            }
        }

        // matches  --> fifaEntities
        console.log('check 41: each match team one should have exactly 1 fifaEntity');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                if (matches[j].matches[k].teamOneID !== 'TBD') {
                    let theFifaEntity = fifaEntities.filter((z) => z.ID === matches[j].matches[k].teamOneID);
                    if (theFifaEntity === undefined)
                        console.log('....error: theFifaEntity undefined for', matches[j].matches[k]);
                    else if (theFifaEntity.length != 1) {
                        console.log(
                            '....error: theFifaEntity match not finding exactly one fifa entity: ',
                            matches[j].matches[k]
                        );
                    }
                }
            }
        }

        // matches --> fifaEntities
        console.log('check 42: each match team two should have exactly 1 fifaEntity');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                if (matches[j].matches[k].teamTwoID !== 'TBD') {
                    let theFifaEntity = fifaEntities.filter((z) => z.ID === matches[j].matches[k].teamTwoID);
                    if (theFifaEntity === undefined)
                        console.log('....error: theFifaEntity undefined for', matches[j].matches[k]);
                    else if (theFifaEntity.length != 1) {
                        console.log(
                            '....error: theFifaEntity match not finding exactly one fifa entity: ',
                            matches[j].matches[k]
                        );
                    }
                }
            }
        }

        //matches: checking the number of games
        console.log('check 43: finals without exactly 1 game');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                let theMatches = matches[j].matches.filter((o) => o.stage === 5);
                if (theMatches === undefined) {
                    console.log(
                        '....error theMatches undefined: looking for stage 5 matches for tourny ',
                        matches[j].tournyID
                    );
                } else if (theMatches.length != 1) {
                    console.log('....error: number of round 5 matches not equal to 1');
                }
            }
        }

        //matches: checking the number of games
        console.log('check 44: semi without exactly 2 games');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                let theMatches = matches[j].matches.filter((o) => o.stage === 4);
                if (theMatches === undefined) {
                    console.log(
                        '....error theMatches undefined: looking for stage 4 matches for tourny ',
                        matches[j].tournyID
                    );
                } else if (theMatches.length != 2) {
                    console.log('....error: number of round 4 matches not equal to 2');
                }
            }
        }

        //matches: checking the number of games
        console.log('check 45: qtr without exactly 4 games');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                let theMatches = matches[j].matches.filter((o) => o.stage === 3);
                if (theMatches === undefined) {
                    console.log(
                        '....error theMatches undefined: looking for stage 3 matches for tourny ',
                        matches[j].tournyID
                    );
                } else if (theMatches.length != 4) {
                    console.log('....error: number of round 3 matches not equal to 4');
                }
            }
        }

        //matches: checking the number of games
        console.log('check 46: r16 without exactly 8 games');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                let theMatches = matches[j].matches.filter((o) => o.stage === 2);
                if (theMatches === undefined) {
                    console.log(
                        '....error theMatches undefined: looking for stage 2 matches for tourny ',
                        matches[j].tournyID
                    );
                } else if (theMatches.length != 8) {
                    console.log('....error: number of round 2 matches not equal to 8');
                }
            }
        }

        //matches: checking the number of games  NOTE: this will change with other tourny formats
        console.log('check 47: first round without exactly 16 games');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                let theMatches = matches[j].matches.filter((o) => o.stage === 1.1);
                if (theMatches === undefined) {
                    console.log(
                        '....error theMatches undefined: looking for stage 5 matches for tourny ',
                        matches[j].tournyID
                    );
                } else {
                    let theTourney = tournys.find((m) => m.tournyID === matches[j].tournyID);
                    if (theMatches.length != (theTourney.numOfGroups * theTourney.teamsPerGroup) / 2) {
                        console.log('....error: number of round 1.3 matches incorrect');
                    }
                }
            }
        }

        //matches: checking the number of games NOTE: this will change with other tourny formats
        console.log('check 48: first round without exactly 16 games');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                let theMatches = matches[j].matches.filter((o) => o.stage === 1.2);
                if (theMatches === undefined) {
                    console.log(
                        '....error theMatches undefined: looking for stage 1.2 matches for tourny ',
                        matches[j].tournyID
                    );
                } else {
                    let theTourney = tournys.find((m) => m.tournyID === matches[j].tournyID);
                    if (theMatches.length != (theTourney.numOfGroups * theTourney.teamsPerGroup) / 2) {
                        console.log('....error: number of round 1.3 matches incorrect');
                    }
                }
            }
        }

        //matches: checking the number of games NOTE: this will change with other tourny formats
        console.log('check 49: first round without exactly 16 games');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                let theMatches = matches[j].matches.filter((o) => o.stage === 1.3);
                if (theMatches === undefined) {
                    console.log(
                        '....error theMatches undefined: looking for stage 1.3 matches for tourny ',
                        matches[j].tournyID
                    );
                } else {
                    let theTourney = tournys.find((m) => m.tournyID === matches[j].tournyID);
                    if (theMatches.length != (theTourney.numOfGroups * theTourney.teamsPerGroup) / 2) {
                        console.log('....error: number of round 1.3 matches incorrect');
                    }
                }
            }
        }

        //matches: no shootouts in first round and no ties in later rounds
        console.log('check 49a:  shootouts and ties');
        for (j = 0; j < matches.length; j++) {
            for (k = 0; k < matches[j].matches.length; k++) {
                if (matches[j].matches[k].matchCompleted) {
                    //only do check on matches that are completed
                    if (
                        matches[j].matches[k].stage === 1.1 ||
                        matches[j].matches[k].stage === 1.2 ||
                        matches[j].matches[k].stage === 1.3
                    ) {
                        //do the check
                        if (matches[j].matches[k].penaltyKickShootout)
                            //there should be no shootouts in first round}
                            console.log('....error group stage match had a shootout in tourny: ', matches[j].tournyID);
                    } else {
                        if (matches[j].matches[k].teamOneScore === matches[j].matches[k].teamTwoScore)
                            console.log('....error tie in later round ', matches[j].tournyID);
                    }
                }
            }
        }

        // players  --> fifaEntity
        console.log('check 50 each player should have legit country');
        for (j = 0; j < players.length; j++) {
            let fifaEntity = fifaEntities.filter((z) => z.ID === players[j].playsForID);
            if (fifaEntity === undefined) console.log('....error: fifaEntity undefined for', players[j].playsForID);
            else if (fifaEntity.length != 1) {
                console.log('....error:  not finding exactly one fifaEntity: ', players[j].playsForID);
            }
        }

        // playersInTourny  --> tourny
        console.log('check 51: each tourny of playersInTourny should have legit tourny');
        for (j = 0; j < playersInTourny.length; j++) {
            let theTourny = tournys.filter((z) => z.tournyID === playersInTourny[j].tournyID);
            if (theTourny === undefined) console.log('....error: theTourny undefined for', playersInTourny[j].tournyID);
            else if (theTourny.length != 1) {
                console.log('....error:  not finding exactly one tourny: ', playersInTourny[j].tournyID);
            }
        }

        // playersInTourny  --> players
        console.log('check 52: each player in a tourny should have exactly 1 player');
        for (j = 0; j < playersInTourny.length; j++) {
            for (k = 0; k < playersInTourny[j].players.length; k++) {
                let thePlayers = players.filter((z) => z.playerID === playersInTourny[j].players[k].playerID);
                if (thePlayers === undefined)
                    console.log('....error: thePlayers undefined ', playersInTourny[j].players[k].playerID);
                else if (thePlayers.length != 1) {
                    console.log(
                        '....error: thePlayers not finding exactly one player: ',
                        playersInTourny[j].players[k].playerID
                    );
                }
            }
        }

        // playersInTourny  --> clubs
        console.log('check 53: each player in tourny should have 0 or 1 club');
        for (j = 0; j < playersInTourny.length; j++) {
            for (k = 0; k < playersInTourny[j].players.length; k++) {
                let theClubs = clubs.filter((z) => z.clubID === playersInTourny[j].players[k].clubID);
                if (theClubs.length > 1) {
                    console.log('....error: theClub not finding 0 or 1  club: ', playersInTourny[j].players[k].clubID);
                }
            }
        }

        // playersInTourny  --> clubs
        console.log('check 54: each player in tourny should have exactly 1 fifaEntity');
        for (j = 0; j < playersInTourny.length; j++) {
            for (k = 0; k < playersInTourny[j].players.length; k++) {
                let theFifaEntity = fifaEntities.filter((z) => z.ID === playersInTourny[j].players[k].playsForID);
                if (theFifaEntity === undefined)
                    console.log('....error: theFifaEntity undefined ', playersInTourny[j].players[k].playsForID);
                else if (theFifaEntity.length != 1) {
                    console.log(
                        '....error: theFifaEntity not finding exactly one fifaEntity: ',
                        playersInTourny[j].players[k].playsForID
                    );
                }
            }
        }

        // polygons -->  fifaEntities   in reality, there are some geographical entities (like greenland	) that are not recognized by Fifa.  for now (2020) these polygons are not on the map
        console.log('check 60: each polygon should have exactly 1 fifaEntity');
        for (k = 0; k < dataPolygonsFirst.features.length; k++) {
            let theEntities = fifaEntities.filter((z) => z.ID === dataPolygonsFirst.features[k].id);
            if (theEntities === undefined) {
                console.log('....error: theEntities undefined ', dataPolygonsFirst.features[k].id);
            } else if (theEntities.length != 1) {
                console.log(
                    '....error: polygon not finding exactly one fifaEntity: ',
                    dataPolygonsFirst.features[k].id
                );
            }
        }

        // teams --> tourny
        console.log('check 70: each tourny of teams should have legit tourny');
        for (j = 0; j < teams.length; j++) {
            let theTournys = tournys.filter((z) => z.tournyID === teams[j].tournyID);
            if (theTournys === undefined)
                console.log('....error: theTourny undefined for', playersInTourny[j].tournyID);
            else if (theTournys.length != 1) {
                console.log('....error:  not finding exactly one tourny: ', playersInTourny[j].tournyID);
            }
        }

        // teams --> fifaEntity
        console.log('check 71: each team should have exactly 1 fifa entity');
        for (j = 0; j < teams.length; j++) {
            for (k = 0; k < teams[j].teams.length; k++) {
                let theTeams = fifaEntities.filter((z) => z.ID === teams[j].teams[k].ID);
                if (theTeams === undefined) console.log('....error: theTeams undefined ', teams[j].teams[k].ID);
                else if (theTeams.length != 1) {
                    console.log('....error: theTeams not finding exactly one fifaEntity: ', teams[j].teams[k].ID);
                }
            }
        }

        // teams --> correct number of teams
        console.log('check 71a: tourny should have the correct number of teams');
        for (j = 0; j < teams.length; j++) {
            let theTournys = tournys.filter((z) => z.tournyID === teams[j].tournyID);
            if (theTournys === undefined) console.log('....error: theTourny undefined ', teams[j].tournyID);
            else if (theTournys.length != 1)
                console.log('....error: theTourny not finding exactly one tourny: ', teams[j].tournyID);
            else if (theTournys[0].numOfGroups * theTournys[0].teamsPerGroup != teams[j].teams.length) {
                console.log('....error: incorrect number of teams ', teams[j].tournyID);
            }
        }

        //teams -->  groups
        console.log('check 72: each team should have exactly 1 group'); //although this changes from tourny to tourny
        for (j = 0; j < teams.length; j++) {
            for (k = 0; k < teams[j].teams.length; k++) {
                let theGroups = groups.filter((z) => z.groupID === teams[j].teams[k].groupID);
                if (theGroups === undefined) console.log('....error: theGroups undefined ', teams[j].teams[k].ID);
                else if (theGroups.length != 1) {
                    console.log('....error: theGroups not finding exactly one fifaEntity: ', teams[j].teams[k].ID);
                }
            }
        }

        // tourny -->  fifaEntities
        console.log('check 80: each tourny should have a unique location');
        for (j = 0; j < tournys.length; j++) {
            let location = fifaEntities.filter((z) => z.ID === tournys[j].locationID);
            if (location === undefined) console.log('....error: location undefined ', tournys[j].locationID);
            else if (location.length != 1) {
                console.log('....error: location not equal to one: ', tournys[j].locationID);
            }
        }

        // tourny -->  fifaEntities
        console.log('check 81: each tourny should have a unique location');
        for (j = 0; j < tournys.length; j++) {
            let startEntity = fifaEntities.filter((z) => z.ID === tournys[j].startEntityID);
            if (startEntity === undefined) console.log('....error: startEntityID undefined ', tournys[j].startEntityID);
            else if (startEntity.length != 1) {
                console.log('....error: startEntityID not equal to one: ', tournys[j].startEntityID);
            }
        }

        //historical data -->  fifaEntities
        console.log('check 83a: each  host country should have a unique location');
        for (j = 0; j < hosts.length; j++) {
            for (k = 0; k < hosts.hostYears; k++) {
                let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].hostID);
                if (fifaID === undefined) console.log('....error: hostID undefined ', hosts[j].hostYears[k].hostID);
                else if (fifaID.length != 1) {
                    console.log('....error: hostID not equal to one: ', hosts[j].hostYears[k].hostID);
                }
            }
        }

        //historical data -->  fifaEntities
        console.log('check 83b: each  host country should have a unique fifaEntities for historical info');
        for (j = 0; j < hosts.length; j++) {
            for (k = 0; k < hosts.hostYears; k++) {
                if (hosts[j].hostYears[k].firstPlaceID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].firstPlaceID);
                    if (fifaID === undefined)
                        console.log('....error: firstPlaceID undefined ', hosts[j].hostYears[k].firstPlaceID);
                    else if (fifaID.length != 1) {
                        console.log('....error: firstPlaceID not equal to one: ', hosts[j].hostYears[k].firstPlaceID);
                    }
                }
                if (hosts[j].hostYears[k].secondPlaceID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].secondPlaceID);
                    if (fifaID === undefined)
                        console.log('....error: secondPlaceID undefined ', hosts[j].hostYears[k].secondPlaceID);
                    else if (fifaID.length != 1) {
                        console.log('....error: secondPlaceID not equal to one: ', hosts[j].hostYears[k].secondPlaceID);
                    }
                }
                if (hosts[j].hostYears[k].thirdPlaceID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].thirdPlaceID);
                    if (fifaID === undefined)
                        console.log('....error: thirdPlaceID undefined ', hosts[j].hostYears[k].thirdPlaceID);
                    else if (fifaID.length != 1) {
                        console.log('....error: thirdPlaceID not equal to one: ', hosts[j].hostYears[k].thirdPlaceID);
                    }
                }
                if (hosts[j].hostYears[k].fourthPlaceID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].fourthPlaceID);
                    if (fifaID === undefined)
                        console.log('....error: firstPlaceID undefined ', hosts[j].hostYears[k].fourthPlaceID);
                    else if (fifaID.length != 1) {
                        console.log('....error: firstPlaceID not equal to one: ', hosts[j].hostYears[k].fourthPlaceID);
                    }
                }
                if (hosts[j].hostYears[k].semiFinalistID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].semiFinalistID);
                    if (fifaID === undefined)
                        console.log('....error: semiFinalistID undefined ', hosts[j].hostYears[k].semiFinalistID);
                    else if (fifaID.length != 1) {
                        console.log(
                            '....error: semiFinalistID not equal to one: ',
                            hosts[j].hostYears[k].semiFinalistID
                        );
                    }
                }
                if (hosts[j].hostYears[k].semiFinalist2ID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].semiFinalist2ID);
                    if (fifaID === undefined)
                        console.log('....error: semiFinalist2ID undefined ', hosts[j].hostYears[k].semiFinalist2ID);
                    else if (fifaID.length != 1) {
                        console.log(
                            '....error: semiFinalist2ID not equal to one: ',
                            hosts[j].hostYears[k].semiFinalist2ID
                        );
                    }
                }
                if (hosts[j].hostYears[k].qtrFinalistOneID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].qtrFinalistOneID);
                    if (fifaID === undefined)
                        console.log('....error: qtrFinalistOneID undefined ', hosts[j].hostYears[k].qtrFinalistOneID);
                    else if (fifaID.length != 1) {
                        console.log(
                            '....error: qtrFinalistOneID not equal to one: ',
                            hosts[j].hostYears[k].qtrFinalistOneID
                        );
                    }
                }
                if (hosts[j].hostYears[k].qtrFinalistTwoID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].qtrFinalistTwoID);
                    if (fifaID === undefined)
                        console.log('....error: qtrFinalistTwoID undefined ', hosts[j].hostYears[k].qtrFinalistTwoID);
                    else if (fifaID.length != 1) {
                        console.log(
                            '....error: qtrFinalistTwoID not equal to one: ',
                            hosts[j].hostYears[k].qtrFinalistTwoID
                        );
                    }
                }
                if (hosts[j].hostYears[k].qtrFinalistThreeID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].qtrFinalistThreeID);
                    if (fifaID === undefined)
                        console.log(
                            '....error: qtrFinalistThreeID undefined ',
                            hosts[j].hostYears[k].qtrFinalistThreeID
                        );
                    else if (fifaID.length != 1) {
                        console.log(
                            '....error: qtrFinalistThreeID not equal to one: ',
                            hosts[j].hostYears[k].qtrFinalistThreeID
                        );
                    }
                }
                if (hosts[j].hostYears[k].qtrFinalistFourID) {
                    //skip those that are null
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].qtrFinalistFourID);
                    if (fifaID === undefined)
                        console.log('....error: qtrFinalistFourID undefined ', hosts[j].hostYears[k].qtrFinalistFourID);
                    else if (fifaID.length != 1) {
                        console.log(
                            '....error: qtrFinalistFourID not equal to one: ',
                            hosts[j].hostYears[k].qtrFinalistFourID
                        );
                    }
                }
            }
        }

        console.log('check 84: each host year should have a participants with valid fifaEntities');
        for (j = 0; j < hosts.length; j++) {
            for (k = 0; k < hosts.hostYears; k++) {
                for (m = 0; m < hosts.hostYears.participants; m++) {
                    let fifaID = fifaEntities.filter((z) => z.ID === hosts[j].hostYears[k].participants[m].ID);
                    if (fifaID === undefined)
                        console.log('....error: participantID undefined ', hosts[j].hostYears[k].participants[m].ID);
                    else if (fifaID.length != 1) {
                        console.log(
                            '....error: participantID not equal to one: ',
                            hosts[j].hostYears[k].participants[m].ID
                        );
                    }
                }
            }
        }
    }
}

export { doBigDataChk };
