import * as manageData from './manageData';
import * as htmlBuilder from './htmlBuilder';

function buildLegends() {
    function buildWinLoseTie() {
        let theLines = [];
        let lineOne = {
            color: settings.colors.winColor,
            text: 'Won',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineTwo = {
            color: settings.colors.loseColor,
            text: 'Lost',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineThree = {
            color: settings.colors.tieColor,
            text: 'Tied',
            textPartTwo: ' ',
            altShape: null,
        };
        theLines.push(lineOne, lineTwo, lineThree);
        return theLines;
    }

    settings.globals.legendControls[settings.legendControlIndices.winLoseTieLeagueIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.winLoseTieLeagueIndex].onAdd = function (theMap) {
        let theLines = buildWinLoseTie();
        let lineFour = {
            color: settings.colors.haventPlayedYetColor,
            text: 'Upcoming match',
            textPartTwo: ' ',
            altShape: null,
        };
        /*    let lineFive = {
            color: settings.circleMarkerStyle.color,
            text: 'click circles',
            textPartTwo: ' ',
            altShape: 'circle',
        }; */
        let lineSix = {
            color: settings.circleMarkerStyle.color,
            text: 'move slider',
            textPartTwo: ' ',
            altShape: 'dot',
        };

        if (!settings.globals.currentTourny.tournyCompleted) theLines.push(lineFour);
        //theLines.push(lineFive);
        theLines.push(lineSix);
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, '<div id="dateGoesHere"></div>');
        return theDiv;
    };

    settings.globals.legendControls[settings.legendControlIndices.winLoseTieBasicIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.winLoseTieBasicIndex].onAdd = function (theMap) {
        let theLines = buildWinLoseTie();
        let lineFour = {
            color: settings.colors.haventPlayedYetColor,
            text: 'Upcoming Match',
            textPartTwo: ' ',
            altShape: null,
        };

        if (!settings.globals.currentTourny.tournyCompleted) theLines.push(lineFour);
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, 'Country');
        return theDiv;
    };

    settings.globals.legendControls[settings.legendControlIndices.winLoseTieVersusIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.winLoseTieVersusIndex].onAdd = function (theMap) {
        let theLines = buildWinLoseTie();
        let lineFour = {
            color: settings.colors.haventPlayedYetColor,
            text: 'Upcoming match',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineBlank = {
            color: settings.colors.oceanColor,
            text: '',
            textPartTwo: ' ',
            altShape: null,
        };
         let lineFive = {
             color: settings.colors.inTournyColor,
             text: 'Qualified',
             textPartTwo: ' ',
             altShape: null,
         };
         let lineSix = {
             color: settings.colors.notInTournyColor,
             text: 'Did not qualify',
             textPartTwo: ' ',
             altShape: null,
         };

        if (!settings.globals.currentTourny.tournyCompleted) theLines.push(lineFour);
        theLines.push(lineBlank);
        theLines.push(lineFive);
        theLines.push(lineSix);
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, '<div id="versusField"></div > ');
        return theDiv;
    };

    settings.globals.legendControls[settings.legendControlIndices.qualifiedOrNotIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.qualifiedOrNotIndex].onAdd = function (theMap) {
        let theLines = [];
        let lineOne = {
            color: settings.colors.inTournyColor,
            text: 'Qualified',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineTwo = {
            color: settings.colors.notInTournyColor,
            text: 'Did not qualify',
            textPartTwo: ' ',
            altShape: null,
        };
        theLines.push(lineOne, lineTwo);
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, 'Country');
        return theDiv;
    };

    function buildFlowLegend(firstThreshold, secondThreshold, title, includeOpenCircle) {
        let theLines = [];
        let lineOne = {
            color: settings.colors.lowLevelChoroplethColor,
            text: ' < ' + firstThreshold,
            textPartTwo: ' ',
            altShape: null,
        };
        let lineTwo = {
            color: settings.colors.medLevelChoroplethColor,
            text: ' ' + firstThreshold + ' - ' + secondThreshold,
            textPartTwo: ' ',
            altShape: null,
        };
        let lineThree = {
            color: settings.colors.highLevelChoroplethColor,
            text: ' >' + secondThreshold,
            textPartTwo: ' ',
            altShape: null,
        };
        let lineFour = {
            color: settings.circleMarkerStyle.color,
            text: 'click circles',
            textPartTwo: ' ',
            altShape: 'dot',
        };
        let lineFive = {
            color: settings.circleMarkerStyle.color,
            text: 'click circles',
            textPartTwo: ' ',
            altShape: 'circle',
        };
        theLines.push(lineOne, lineTwo, lineThree, lineFour);
        if (includeOpenCircle) theLines.push(lineFive);
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, title);
        return theDiv;
    }
    settings.globals.legendControls[settings.legendControlIndices.talentFlowFromCountryIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.talentFlowFromCountryIndex].onAdd = function (
        theMap
    ) {
        return buildFlowLegend(
            settings.misc.talentFlowFromCountryLevelOneThreshold,
            settings.misc.talentFlowFromCountryLevelTwoThreshold,
            'Players Leaving<br>Country',
            true
        );
    };

    settings.globals.legendControls[settings.legendControlIndices.greatPlayerIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.greatPlayerIndex].onAdd = function (theMap) {
        let theLines = [];
        let lineOne = {
            color: settings.colors.greatPlayerEntityColor,
            text: 'Video(s)',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineTwo = {
            color: settings.colors.notGreatPlayerEntityColor,
            text: 'No videos',
            textPartTwo: ' ',
            altShape: null,
        };
        theLines.push(lineOne, lineTwo);
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, 'Great Player');
        return theDiv;
    };

    settings.globals.legendControls[settings.legendControlIndices.championIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.championIndex].onAdd = function (theMap) {
        let theLines = [];
        let lineOne = {
            color: settings.colors.winColor,
            text: 'Champion',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineTwo = {
            color: settings.colors.loseColor,
            text: 'Second Place',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineThree = {
            color: settings.colors.inTournyColor,
            text: 'Eliminated in a previous round',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineFour = {
            color: settings.colors.notInTournyColor,
            text: 'Did not qualify',
            textPartTwo: ' ',
            altShape: null,
        };
        theLines.push(lineOne, lineTwo, lineThree, lineFour);
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, 'Country');
        return theDiv;
    };

    function buildAdvancingLegend(toWhich, includePrevious) {
        let theLines = [];
        let lineOne = {
            color: settings.colors.winColor,
            text: 'Advanced to ' + toWhich,
            textPartTwo: ' ',
            altShape: null,
        };
        let lineTwo = {
            color: settings.colors.loseColor,
            text: 'Did not advance',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineThree = {
            color: settings.colors.haventPlayedYetColor,
            text: 'Upcoming Match',
            textPartTwo: ' ',
            altShape: null,
        };
        if (settings.globals.currentTourny.tournyCompleted) theLines.push(lineOne, lineTwo);
        else theLines.push(lineOne, lineTwo, lineThree);
        if (includePrevious) {
            let lineFour = {
                color: settings.colors.inTournyColor,
                text: 'Eliminated in a previous round',
                textPartTwo: ' ',
                altShape: null,
            };
            theLines.push(lineFour);
        }
        let lineFive = {
            color: settings.colors.notInTournyColor,
            text: 'Did not qualify',
            textPartTwo: ' ',
            altShape: null,
        };
        theLines.push(lineFive);
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, 'Country');
        return theDiv;
    }

    settings.globals.legendControls[settings.legendControlIndices.advancedOrNotTo16Index] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.advancedOrNotTo16Index].onAdd = function (theMap) {
        return buildAdvancingLegend('Round of 16', false);
    };

    settings.globals.legendControls[settings.legendControlIndices.advancedOrNotTo8Index] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.advancedOrNotTo8Index].onAdd = function (theMap) {
        return buildAdvancingLegend('Qtr-Finals', true);
    };

    settings.globals.legendControls[settings.legendControlIndices.advancedOrNotTo4Index] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.advancedOrNotTo4Index].onAdd = function (theMap) {
        return buildAdvancingLegend('Semi-Finals', true);
    };

    settings.globals.legendControls[settings.legendControlIndices.advancedOrNotTo2Index] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.advancedOrNotTo2Index].onAdd = function (theMap) {
        return buildAdvancingLegend('Finals', true);
    };

    settings.globals.legendControls[settings.legendControlIndices.talentFlowToCountryIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.talentFlowToCountryIndex].onAdd = function (theMap) {
        return buildFlowLegend(
            settings.misc.talentFlowToCountryLevelOneThreshold,
            settings.misc.talentFlowToCountryLevelTwoThreshold,
            'Players Entering<br>Country',
            false
        );
    };

    settings.globals.legendControls[settings.legendControlIndices.tournyHostIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.tournyHostIndex].onAdd = function (theMap) {
        let theLines = [];
        let lineOne = {
            color: settings.colors.tournyHostColor,
            text: 'Has video(s) of final',
            textPartTwo: ' ',
            altShape: null,
        };
        let lineTwo = {
            color: settings.colors.notTournyHostColor,
            text: 'Never hosted final',
            textPartTwo: ' ',
            altShape: null,
        };
        theLines.push(lineOne, lineTwo);
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, 'Country');
        return theDiv;
    };

    function buildGroupsLegend(title, includeClickLine) {
        let theDiv = L.DomUtil.create('div', 'legend');
        let theLines = [];
        if (settings.globals.currentGroups.length > 1) {
            for (const aGroup of settings.globals.currentGroups) {
                let theGroupColor = settings.colors.groupColors.find((m) => m.groupID === aGroup.groupID).groupColor;
                theLines.push({
                    color: theGroupColor,
                    text: aGroup.shortName,
                    textPartTwo: ' ',
                    altShape: null,
                });
            }
        }
        if (includeClickLine) {
            let extraLine = {
                color: settings.circleMarkerStyle.color,
                text: 'click circles',
                textPartTwo: ' ',
                altShape: 'circle',
            };
            theLines.push(extraLine);
        }
        theDiv.innerHTML = htmlBuilder.buildLegend(theLines, title);
        return theDiv;
    }

    settings.globals.legendControls[settings.legendControlIndices.groupsIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.groupsIndex].onAdd = function (theMap) {
        return buildGroupsLegend('Groups', false);
    };
    settings.globals.legendControls[settings.legendControlIndices.conferencesIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.conferencesIndex].onAdd = function (theMap) {
        return buildGroupsLegend('Points By Team', true);
    };

    settings.globals.legendControls[settings.legendControlIndices.leagueScheduleIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.leagueScheduleIndex].onAdd = function (theMap) {
        return buildGroupsLegend('Schedule By Team', true);
    };

    settings.globals.legendControls[settings.legendControlIndices.groupTopTwoIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.groupTopTwoIndex].onAdd = function (theMap) {
        return buildGroupsLegend('Top two teams<br>(by points) from Group:', false);
    };

    settings.globals.legendControls[settings.legendControlIndices.groupLeadersIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.groupLeadersIndex].onAdd = function (theMap) {
        return buildGroupsLegend('Leader (by points)<br>from Group:', false);
    };

    function buildConfederationLegend(includeCounts, title) {
        let theConfedInfo = [];
        let theColorGroup = '';
        for (let j = 1; j < settings.confederations.length; j++) {
            // start at 1 because the 0th confederation is a dummy confederation
            theColorGroup = settings.colors.confederationColors.find(
                (k) => k.confederationID === settings.confederations[j].confederationID
            );
            if (theColorGroup === undefined)
                utilities.myErrorReport('error 309: confederationIDs ', settings.misc.majorError);
            else {
                let theText = settings.confederations[j].shortName;
                let theText2 = '';
                if (includeCounts)
                    theText2 += manageData.countQualifiedTeamsFromConfed(settings.confederations[j].confederationID);
                theConfedInfo.push({
                    color: theColorGroup.standOutColor,
                    text: theText,
                    textPartTwo: theText2,
                    altShape: null,
                });
            }
        }
        theConfedInfo.sort(function (a, b) {
            if (a.text < b.text) {
                return -1;
            }
            if (a.text > b.text) {
                return 1;
            }
            return 0;
        });
        let theDiv = L.DomUtil.create('div', 'legend');
        theDiv.innerHTML = htmlBuilder.buildLegend(theConfedInfo, title);
        return theDiv;
    }

    settings.globals.legendControls[settings.legendControlIndices.confederationIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.confederationIndex].onAdd = function (theMap) {
        return buildConfederationLegend(false, 'Confederation');
    };

    settings.globals.legendControls[settings.legendControlIndices.qualifyingIndex] = L.control({
        position: 'bottomleft',
    });
    settings.globals.legendControls[settings.legendControlIndices.qualifyingIndex].onAdd = function (theMap) {
        return buildConfederationLegend(true, 'Number of qualifying<br>teams from:');
    };
}

export { buildLegends };
