//import UsersDB from './users-db';
//import firebase from 'firebase/app';
//import "firebase/firestore";
//import * as firebaseui from 'firebaseui';
//import "firebase/auth";
import * as htmlBuilder from '../htmlBuilder';
import { getFunctions } from 'firebase/functions';
//import HtmlWebpackPlugin from 'html-webpack-plugin';

function mySignOut() {
    settings.globals.firebase.currentUserID = null;
    settings.globals.firebase.activeSubscription = false;
    if (settings.globals.firebase.firebaseApp) settings.globals.firebase.firebaseApp.auth().signOut();
}

function initFirebase() {
    // Initialize Firebase if need be
    if (!settings.globals.firebase.firebaseApp) {
        // Replace with your Firebase project config.
        const firebaseConfig = {
            apiKey: 'AIzaSyDhcSE0mm2w_y8AYr1GdANlX7lxMEaaRWk',
            authDomain: 'oneworld-futbol.firebaseapp.com',
            databaseURL: 'https://oneworld-futbol.firebaseio.com',
            projectId: 'oneworld-futbol',
            storageBucket: 'oneworld-futbol.appspot.com',
            messagingSenderId: '100814520643',
            appId: '1:100814520643:web:f894a098ea65d5b668aff6',
            measurementId: 'G-CT6HN3LW27',
        };
        settings.globals.firebase.firebaseApp = firebase.initializeApp(firebaseConfig); //firebase seems to be global
        settings.globals.firebase.UI = new firebaseui.auth.AuthUI(firebase.auth());
        settings.globals.firebase.db = settings.globals.firebase.firebaseApp.firestore();
        firebase.auth().onAuthStateChanged((firebaseUser) => {
            //this runs asynchronously
            if (firebaseUser) {
                console.log('firebaseUser set');
                // document.querySelector('#loader').style.display = 'none';
                settings.globals.firebase.currentUserID = firebaseUser.uid;
                checkStripeSubscription(false, false); //this has an async call in it
            } else {
                settings.globals.firebase.currentUserID = null;
                settings.globals.firebase.activeSubscription = false;
                htmlBuilder.replaceUserDependentMenu();
            }
        });
    }

    settings.globals.firebase.uiConfig = {
        //was const firebaseUiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                settings.globals.firebase.currentUserID = authResult.user.uid;
                document.querySelector('#myFirebaseUI-Container').style.display = 'none';
                // document.querySelector('#firebaseui-auth-container').style.display = 'none';
                htmlBuilder.switchModes(settings.layerIndices.subscribeLayerIndex); //serves as a callback
                return false; //false means do not redirect
            },
            signInFailure: function () {
                //
            },
            uiShown: function () {
                hideSpinner();
            },
        },
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID],
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        // Your terms of service url.
        tosUrl: 'https://OneWorld-Futbol.com/docs/termsOfUse.html',
        // Your privacy policy url.
        privacyPolicyUrl: 'https://OneWorld-Futbol.com/docs/privacyPolicy.html',
    };
}

function checkStripeSubscription(continueToSubscribe, callBack) {
    console.log('checkStripeSubscription');
    settings.globals.firebase.activeSubscription = false;
    if (settings.globals.firebase.currentUserID) {
        settings.globals.firebase.db
            .collection('users') //was customers
            .doc(settings.globals.firebase.currentUserID) //was .doc(currentUser)
            .collection('subscriptions')
            .where('status', 'in', ['active']) //was ['trialing', 'active'])
            .onSnapshot(async (snapshot) => {
                if (snapshot.empty) {
                    if (continueToSubscribe) startSubscriptionProcess();
                } else {
                    settings.globals.firebase.activeSubscription = true;
                    if (callBack) switchModes(settings.globals.firebase.callBackLayerIndex);
                }
                htmlBuilder.replaceUserDependentMenu(); //whatever the result of the async call, update the menu
            });
    }
}

function handleLogInAndSubscribe() {
    console.log('handleLogInAndSubscribe');
    /**
     * Firebase Authentication configuration
     */
    if (!settings.globals.firebase.currentUserID) {
        //case I not logged in
        document.querySelector('#myFirebaseUI-Container').style.display = 'block';
        settings.globals.firebase.UI.start('#firebaseui-auth-container', settings.globals.firebase.uiConfig);
    } else checkStripeSubscription(true, true);
}

function startSubscriptionProcess() {
    const prices = {};
    // Replace with your cloud functions location
    document.querySelector('#subscribe').style.display = 'block';
    // Get all our products and render them to the page
    const products = document.querySelector('.products'); //querySelector only returns one
    const template = document.querySelector('#product');
    settings.globals.firebase.db
        .collection('products')
        .where('active', '==', true)
        .get()
        .then(function (querySnapshot) {
            querySnapshot.forEach(async function (doc) {
                const priceSnap = await doc.ref
                    .collection('prices')
                    .where('active', '==', true)
                    .orderBy('unit_amount', 'asc')
                    .get();
                if (!('content' in document.createElement('template'))) {
                    return; //exit
                }
                const product = doc.data();
                let thePrice = null;
                // Prices dropdown
                priceSnap.docs.forEach((doc) => {
                    // even though the dropdown is disabled the content is still filled in
                    const container = template.content.cloneNode(true);
                    const priceId = doc.id;
                    const priceData = doc.data();
                    prices[priceId] = priceData;
                    let theText = '';
                    if (priceData.interval_count === 1) {
                        theText = `${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: priceData.currency,
                        }).format((priceData.unit_amount / 100).toFixed(2))} per ${priceData.interval ?? 'once'}`;
                    } else {
                        theText = `${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: priceData.currency,
                        }).format((priceData.unit_amount / 100).toFixed(2))} for ${priceData.interval_count}  ${priceData.interval
                            }s`;
                    }
                    container.querySelector('.description').innerText = theText;
                    const option = document.createElement('option');
                    option.value = priceId;
                    //option.appendChild(content);
                    container.querySelector('#price').appendChild(option);
                    const form = container.querySelector('form');
                    form.addEventListener('submit', subscribe);
                    products.appendChild(container);
                });
            });
        });

    /*
    Event listeners
    don't see the need for a signout button
    // Signout button
    document
      .getElementById('signout')
      .addEventListener('click', () => {
        firebase.auth().signOut();
      }
      ); */

    // Checkout handler
    async function subscribe(event) {
        showSpinner();
        event.preventDefault();
        document.querySelectorAll('button').forEach((b) => (b.disabled = true));
        const formData = new FormData(event.target);
        const selectedPrice = {
            price: formData.get('price'),
        };
        // For prices with metered billing we need to omit the quantity parameter.
        // For all other prices we set quantity to 1.
        if (prices[selectedPrice.price]?.recurring?.usage_type !== 'metered') selectedPrice.quantity = 1;
        const checkoutSession = {
            automatic_tax: true,
            tax_id_collection: true,
            collect_shipping_address: false,
            allow_promotion_codes: true,
            line_items: [selectedPrice],
            success_url: 'https://OneWorld-Futbol.com',
            cancel_url: 'https://OneWorld-Futbol.com',
            metadata: {
                key: 'value',
            },
        };
        // For one time payments set mode to payment.
        if (prices[selectedPrice.price]?.type === 'one_time') {
            checkoutSession.mode = 'payment';
            checkoutSession.payment_method_types = ['card', 'sepa_debit', 'sofort'];
        }

        const docRef = await settings.globals.firebase.db
            .collection('users') //was customers
            .doc(settings.globals.firebase.currentUserID) //was .doc(currentUser)
            .collection('checkout_sessions')
            .add(checkoutSession);
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
            //might need to be async
            const { url, error } = snap.data();
            if (error) {
                // Show an error to your customer and then inspect your function logs.
                alert(`An error occured: ${error.message}`);
                document.querySelectorAll('button').forEach((b) => (b.disabled = false));
            } else if (url) {
                hideSpinner();
                window.location.assign(url);
            }
        });
    }

    // Get custom claim role helper
    /*  async function getCustomClaimRole() {
         await firebase.auth().currentUser.getIdToken(true);
         const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
         return decodedToken.claims.stripeRole;
     } */
}

function showSpinner() {
    document.querySelector('html').setAttribute('data-spinnerNeeded', 'true');
}

function hideSpinner() {
    document.querySelector('html').setAttribute('data-spinnerNeeded', 'false');
}

/* async function manageAccount() {
   const functions = getFunctions(settings.globals.firebase.firebaseApp,"europe-west3");
    const functionRef = httpsCallable(functions, 'ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
 } */
async function manageAccount() {
    // Billing portal handler
    showSpinner();
    const functionLocation = 'us-west3';

    const functionRef = firebase
        .app()
        .functions(functionLocation)
        //.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink'); //this uses the old name
        .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    hideSpinner();
    window.location.assign(data.url);
}

export { handleLogInAndSubscribe, manageAccount, initFirebase, mySignOut, showSpinner, hideSpinner };
