import * as manageData from './manageData';
import * as manageLayerColors from './manageLayerColors';
import * as utilities from './utilities';
import * as htmlBuilder from './htmlBuilder';
import { keysIn } from 'lodash';

function buildLabels() {
    let inTournyLabels = [];
    let MEuroVideosLabels = [];
    let MWCVideosLabels = [];
    let WWCVideosLabels = [];
    let greatMPlayerHostLabels = [];
    let greatWPlayerHostLabels = [];
    let theMWCHost = '';
    let theWWCHost = '';
    let theMUEFAHost = '';
    let theGreatMPlayerHost = '';
    let theGreatWPlayerHost = '';
    for (let entity of settings.fifaEntities) {
        let aMarker = L.marker([entity.latitude, entity.longitude], {
            icon: L.divIcon({
                iconAnchor: [20, 10],
                className: 'mapLabels',
                html: '<span>' + manageData.getProperEntityName(entity.ID) + '</span>',
            }),
            interactive: false, // Post-0.7.3
            clickable: false, //      0.7.3
        });
        // add to correct label groups
        if ('Z' !== manageData.getEntityGroupID(entity.ID)) {
            //if in the tourny
            inTournyLabels.push(aMarker);
        }

        let theHostType = settings.hosts.find((k) => k.hostType === 'MWC');
        let theMWCHost = theHostType.hostYears.find((m) => m.hostID === entity.ID);
        if (theMWCHost === undefined) {
            // not an error, just not a host
        } else MWCVideosLabels.push(aMarker);

        theHostType = settings.hosts.find((k) => k.hostType === 'WWC');
        let theWWCHost = theHostType.hostYears.find((m) => m.hostID === entity.ID);
        if (theWWCHost === undefined) {
            // not an error, just not a host
        } else WWCVideosLabels.push(aMarker);

        theHostType = settings.hosts.find((k) => k.hostType === 'MEuro');
        let theMEuroHost = theHostType.hostYears.find((m) => m.hostID === entity.ID);
        if (theMEuroHost === undefined) {
            // not an error, just not a host
        } else MEuroVideosLabels.push(aMarker);

        theGreatMPlayerHost = settings.greatPlayers.find((k) => k.playedForID === entity.ID && k.gender === 'M');
        if (theGreatMPlayerHost === undefined) {
            // not an error, just not a host
        } else greatMPlayerHostLabels.push(aMarker);

        theGreatWPlayerHost = settings.greatPlayers.find((k) => k.playedForID === entity.ID && k.gender === 'W');
        if (theGreatWPlayerHost === undefined) {
            // not an error, just not a host
        } else greatWPlayerHostLabels.push(aMarker);
    }

    settings.globals.layers[settings.layerIndices.basicLabelsLayerIndex] = L.LayerGroup.collision({
        margin: 5,
        pane: 'paneInFront',
    });

    settings.globals.layers[settings.layerIndices.MWCVideosLabelsLayerIndex] = L.LayerGroup.collision({
        margin: 5,
        pane: 'paneInFront',
    });

    settings.globals.layers[settings.layerIndices.WWCVideosLabelsLayerIndex] = L.LayerGroup.collision({
        margin: 5,
        pane: 'paneInFront',
    });

    settings.globals.layers[settings.layerIndices.MEuroVideosLabelsLayerIndex] = L.LayerGroup.collision({
        margin: 5,
        pane: 'paneInFront',
    });

    settings.globals.layers[settings.layerIndices.greatMPlayersLabelsLayerIndex] = L.LayerGroup.collision({
        margin: 5,
        pane: 'paneInFront',
    });

    settings.globals.layers[settings.layerIndices.greatWPlayersLabelsLayerIndex] = L.LayerGroup.collision({
        margin: 5,
        pane: 'paneInFront',
    });

    for (let aMarker of inTournyLabels) {
        settings.globals.layers[settings.layerIndices.basicLabelsLayerIndex].addLayer(aMarker);
    }

    for (let aMarker of MWCVideosLabels) {
        settings.globals.layers[settings.layerIndices.MWCVideosLabelsLayerIndex].addLayer(aMarker);
    }

    for (let aMarker of WWCVideosLabels) {
        settings.globals.layers[settings.layerIndices.WWCVideosLabelsLayerIndex].addLayer(aMarker);
    }

    for (let aMarker of greatMPlayerHostLabels) {
        settings.globals.layers[settings.layerIndices.greatMPlayersLabelsLayerIndex].addLayer(aMarker);
    }

    for (let aMarker of greatWPlayerHostLabels) {
        settings.globals.layers[settings.layerIndices.greatWPlayersLabelsLayerIndex].addLayer(aMarker);
    }

    for (let aMarker of MEuroVideosLabels) {
        settings.globals.layers[settings.layerIndices.MEuroVideosLabelsLayerIndex].addLayer(aMarker);
    }
}

function getGroupClass(entityToBeColoredID) {
    let theGroupID = manageData.getEntityGroupID(entityToBeColoredID);
    let theGroup = settings.globals.currentGroups.find((k) => k.groupID === theGroupID);
    return theGroup.class;
}

function doFromColorToClass(entityToBeColoredID, baseEntityID) {
    //this is a bit of a mess related to the fact
    //that leaflet wants colors to draw polygons but the
    //markers require a class
    let returnText = '';
    let theColor = manageLayerColors.getDashboardLayerColor(entityToBeColoredID, baseEntityID, true); //show matchCompleted Only
    if (theColor === settings.colors.winColor) returnText = 'winMarkerColorClass';
    else if (theColor === settings.colors.loseColor) returnText = 'loseMarkerColorClass';
    else if (theColor === settings.colors.tieColor) returnText = 'tieMarkerColorClass';
    else if (theColor === settings.colors.haventPlayedYetColor) returnText = 'haventPlayedYetMarkerColorClass';
    else returnText = 'hollowMarkerColorClass';
    return returnText;
}

function getBasicSize() {
    return settings.leagueDivIconBasicSize;
}

function sizeByNumberOfLeaguePoints(entityToBeSizedID) {
    //check to see if the team has played any games yet
    let matches = settings.globals.currentMatches.filter(
        (m) => (m.teamOneID === entityToBeSizedID || m.teamTwoID === entityToBeSizedID) && m.matchCompleted
    );
    let theSizeRequired = settings.leagueDivIconBasicSize;
    if (matches.length > 0) {
        //at least one game has not been played by the team
        let maxPoints = 0;
        let minPoints = 100000000;
        let totalPointsDesiredTeam = 0;
        for (const aTeam of settings.globals.currentTeams) {
            let results = manageData.getPointsForASingleTeam(aTeam.ID);
            let nOfPoints = 3 * results.wins + results.ties;
            if (nOfPoints > maxPoints) maxPoints = nOfPoints;
            if (nOfPoints < minPoints) minPoints = nOfPoints;
            if (aTeam.ID === entityToBeSizedID) totalPointsDesiredTeam = nOfPoints;
        }
        let sizeDif = settings.leagueDivIconMaxSize[0] - settings.leagueDivIconMinSize[0];
        if (maxPoints > minPoints) {
            //just to avoid a divide by zero problem
            let theSizeIncrement = Math.floor(
                (sizeDif * (totalPointsDesiredTeam - minPoints)) / (maxPoints - minPoints)
            );
            let theSize = settings.leagueDivIconMinSize[0] + theSizeIncrement;
            theSizeRequired = [theSize, theSize];
        }
    }
    return theSizeRequired;
}


function getLeagueRoundColor(theEntityToBeColoredID, notUsed, searchingForID) {
    let theMatchOfInterest = null;
    if (settings.globals.currentTourny.hasRoundIDs) {
        theMatchOfInterest = settings.globals.currentMatches.find(
            (k) =>
                (k.teamOneID === theEntityToBeColoredID || k.teamTwoID === theEntityToBeColoredID) &&
                k.sportMonksRoundID === searchingForID
        );
    } else {
        theMatchOfInterest = settings.globals.currentMatches.find(
            (k) =>
                (k.teamOneID === theEntityToBeColoredID || k.teamTwoID === theEntityToBeColoredID) &&
                k.playDateRoundID === searchingForID
        );
    }
    let theClass = '';
    if (!theMatchOfInterest) {
        //noMatch by that team
        theClass = 'hollowMarkerColorClass';
    } else if (!theMatchOfInterest.matchCompleted) {
        theClass = 'haventPlayedYetMarkerColorClass';
    } else if (theMatchOfInterest.teamOneScore === theMatchOfInterest.teamTwoScore) theClass = 'tieMarkerColorClass';
    else if (theMatchOfInterest.teamOneID === theEntityToBeColoredID) {
        if (theMatchOfInterest.teamOneScore > theMatchOfInterest.teamTwoScore) theClass = 'winMarkerColorClass';
        else if (theMatchOfInterest.teamOneScore < theMatchOfInterest.teamTwoScore) theClass = 'loseMarkerColorClass';
    } else {
        if (theMatchOfInterest.teamOneScore < theMatchOfInterest.teamTwoScore) theClass = 'winMarkerColorClass';
        else if (theMatchOfInterest.teamOneScore > theMatchOfInterest.teamTwoScore) theClass = 'loseMarkerColorClass';
    }
    return theClass;
}

function buildLeagueLabels(clusterGroup, theSettings, baseEntityID, theRoundID) {
    let textFunc = theSettings.popUpTextFunc;
    let thePopUpFunc = theSettings.popUpFunc;
    let colorFunc = theSettings.colorFunc;
    let sizeFunc = theSettings.markerSizeFunc;
    let nOfSpaces = 7;
    //this function updates the clusterGroup with new Markers
    for (let aTeam of settings.globals.currentTeams) {
        let theEntity = settings.fifaEntities.find((k) => k.ID === aTeam.ID);
        let theIconSettings = settings.leagueDivIcon;
        if (sizeFunc) {
            let theSize = sizeFunc(theEntity.ID, baseEntityID);
            theIconSettings.iconSize = [theSize[0], theSize[1]];
            nOfSpaces = Math.floor(theSize[0] / 3);
        }
        theIconSettings.html = '<div  class="mapLabels mapLabelsLeague">' + '&nbsp'; //this and the following are a makeshift method to get the spacing right
        theIconSettings.html += '&nbsp'.repeat(nOfSpaces);
        theIconSettings.html +=
            /*  '<div class="universalCameraContainer">' +
            '<a href="#" class="universalCamera"' +
            '></a>' +
            '</div > ' + */
            manageData.getProperEntityName(theEntity.ID) + '</div>';
        theIconSettings.className = 'myMarkerCircle';
        if (colorFunc) {
            //
            theIconSettings.className += ' ' + colorFunc(theEntity.ID, baseEntityID, theRoundID); //adding the space is necessary
        }
        if (baseEntityID !== theEntity.ID) {
            //don't replace the base marker

            let aMarker = new L.marker([theEntity.latitude, theEntity.longitude], {
                //icon: customIcon
                icon: L.divIcon(theIconSettings),
            });

            let aPopUp = new L.popup(settings.generalPopUpStyle).setContent(
                textFunc(theEntity.ID, baseEntityID, theRoundID)
            );
            aPopUp.myOnOpenPopUpFunc = thePopUpFunc; //if a popup has this function it will get run when the popup opens see initialization
            aPopUp.entityID = theEntity.ID;
            aMarker.entityID = theEntity.ID;
            aMarker.bindPopup(aPopUp);
            clusterGroup.addLayer(aMarker);
        }
    } //force spidery on click
    clusterGroup.on('clusterclick', function (a) {
        setTimeout(() => {
            a.layer.spiderfy();
        }, 300); //zoomToBounds();
        /*      if (settings.globals.map.originalEvent) //this may not be doing anything useful, trying to stop extranous popup
            settings.globals.map.originalEvent.preventDefault();
 */
    });
    return clusterGroup;
}

function updateLeagueResultsMarkersAndLegend({ label, value, map, notUsed }) {
    value = Number(value); //type cast

    let myClusterGroup = settings.globals.layers[settings.layerIndices.leagueResultsLabelsLayerIndex];
    myClusterGroup.eachLayer(function (feature) {
        myClusterGroup.removeLayer(feature);
    });
    let theSettings = settings.globals.layerParameters[settings.layerIndices.leagueResultsLabelsLayerIndex];
    let theRoundID =
        settings.globals.sliderControls[settings.sliderControlIndices.leagueResultsSliderIndex].options
            .playDateRoundIDs[value - 1];
    settings.globals.layers[settings.layerIndices.leagueResultsLabelsLayerIndex] = buildLeagueLabels(
        settings.globals.layers[settings.layerIndices.leagueResultsLabelsLayerIndex],
        theSettings,
        null,
        theRoundID
    );
    let theMatches = null;
    let theString = '';
    let theDay = 0;
    let theMonth = 0;
    if (settings.globals.currentTourny.hasRoundIDs) {
        theMatches = settings.globals.currentMatches.filter((k) => k.sportMonksRoundID === theRoundID);
        utilities.sortMatchesChrono(theMatches, false);
        if (theMatches.length > 0) {
            for (let m = 0; m < theMatches.length; m++) {
                if (theDay !== theMatches[m].day || theMonth !== theMatches[m].month) {
                    let theMonthText = settings.months[theMatches[m].month].shortName;
                    theString += theMonthText + ' ' + theMatches[m].day.toString() + ', ';
                    theDay = theMatches[m].day;
                    theMonth = theMatches[m].month;
                }
            }
            // now remove the final comma
            theString = theString.substring(0, theString.length - 2);
        }
    } else {
        theMatches = settings.globals.currentMatches.filter((k) => k.playDateRoundID === theRoundID);
        theDay = theMatches[0].day; //all the matches found above share the same play date
        theMonth = settings.months[theMatches[0].month].shortName;
        theString = theMonth + ' ' + theDay.toString();
    }
    htmlBuilder.updateLegendDate(theString);
}

function updateLeagueSingleTeamResultsMarkers(theID) {
    let myClusterGroup = settings.globals.layers[settings.layerIndices.leagueSingleTeamResultsLabelsLayerIndex];
    settings.globals.currentLeagueTeamID = theID;
    myClusterGroup.eachLayer(function (feature) {
        if (feature.entityID !== theID) myClusterGroup.removeLayer(feature);
        else {
            let marker = feature;
            let icon = marker.options.icon;

            // Update icon class.
            icon.options.className = 'myMarkerCircle';

            // Force Marker redraw.
            marker.setIcon(icon);

            // Set parent clusters' icon dirty, all the way up.
            let parent = marker.__parent;
            while (parent) {
                parent._iconNeedsUpdate = true;
                parent = parent.__parent;
            }

            //feature._updateIcon();
        }
    });
    //need to use a deep clone here so that the original settings do not get modified.
    let theSettings = settings.globals.layerParameters[settings.layerIndices.leagueSingleTeamResultsLabelsLayerIndex];
    let theSettings2 = utilities.lodashDeepClone(theSettings);
    theSettings2.colorFunc = doFromColorToClass;
    settings.globals.layers[settings.layerIndices.leagueSingleTeamResultsLabelsLayerIndex] = buildLeagueLabels(
        settings.globals.layers[settings.layerIndices.leagueSingleTeamResultsLabelsLayerIndex],
        theSettings2,
        theID,
        null
    );
    htmlBuilder.updateLegendTitle(manageData.getProperEntityName(settings.globals.currentLeagueTeamID));
}

function autoScrollToBestDate(notUsed) {
    setTimeout(function () {
        document.getElementById('hiddenScrollLink').click();
    }, 250);
}

export {
    buildLabels,
    buildLeagueLabels,
    doFromColorToClass,
    getGroupClass,
    updateLeagueSingleTeamResultsMarkers,
    autoScrollToBestDate,
    sizeByNumberOfLeaguePoints,
    getBasicSize,
    getLeagueRoundColor,
    updateLeagueResultsMarkersAndLegend,
};
