import * as utilities from './utilities';
import * as manageData from './manageData';

/**
 * This dispatcher is only called on the first instance of the layer.  After that it is bypassed.
 * @param whichLayer
 * @param entityID is the country to be colored in
 * @returns color
 */
function getSolidColor() {
    return settings.colors.solidBackgroundColor;
}

function getVideoLayerColor(entityID, type) {
    let theHost = settings.hosts.find((k) => k.hostType === type);
    let theEntity = theHost.hostYears.find((m) => m.hostID === entityID);
    if (theEntity === undefined) return settings.colors.notTournyHostColor;
    else return settings.colors.tournyHostColor;
}
function getMWCVideosLayerColor(entityID) {
    return getVideoLayerColor(entityID, 'MWC');
}

function getWWCVideosLayerColor(entityID) {
    return getVideoLayerColor(entityID, 'WWC');
}

function getMEuroVideosLayerColor(entityID) {
    return getVideoLayerColor(entityID, 'MEuro');
}

function getGreatPlayerVideosLayerColor(entityID, gender) {
    let theEntity = settings.greatPlayers.find((m) => m.playedForID === entityID && m.gender === gender);
    if (theEntity === undefined) return settings.colors.notGreatPlayerEntityColor;
    else return settings.colors.greatPlayerEntityColor;
}

function getGreatMPlayerVideosLayerColor(entityID) {
    return getGreatPlayerVideosLayerColor(entityID, 'M');
}

function getGreatWPlayerVideosLayerColor(entityID) {
    return getGreatPlayerVideosLayerColor(entityID, 'W');
}

/**
 * Please describe the following function here
 * @param entityToBeColoredID
 * @param whichGame
 * @returns {string}
 */
function getBasicLayerColor(entityID) {
    if ('Z' === manageData.getEntityGroupID(entityID)) return settings.colors.notInTournyColor;
    else return settings.colors.inTournyColor;
}

/**
 * Please describe the following function here
 * @param e
 */
function highlightFeature(e) {
    e.target.setStyle({
        weight: settings.misc.generalHighlightWeight,
        color: settings.colors.generalHighlightLineColor,
    });
}

function getNoColorNeeded() {
    return '#000000';
}

function unHighlightFeature(e) {
    e.target.setStyle({
        weight: settings.misc.generalLineWeight,
        color: settings.colors.generalLineColor,
    });
}

function getGroupLeadersLayerColor(entityNeedingColorID) {
    let theReturnColor = settings.colors.notInTournyColor;
    let entityGroupID = manageData.getEntityGroupID(entityNeedingColorID);
    if (entityGroupID !== 'Z') {
        let teamsInGroup = settings.globals.currentTeams.filter((o) => o.groupID === entityGroupID); // not error checking here because this would be caught on start up
        teamsInGroup.sort((a, b) => (a.groupStagePoints < b.groupStagePoints ? 1 : -1));
        let isLeader = false;
        if (teamsInGroup[0].ID === entityNeedingColorID) isLeader = true;
        else if (
            teamsInGroup[1].ID === entityNeedingColorID &&
            teamsInGroup[1].groupStagePoints === teamsInGroup[0].groupStagePoints //tied for first
        )
            isLeader = true;
        else if (
            teamsInGroup[2].ID === entityNeedingColorID &&
            teamsInGroup[2].groupStagePoints === teamsInGroup[0].groupStagePoints //tied for first and second
        )
            isLeader = true;
        else if (
            teamsInGroup[3].ID === entityNeedingColorID &&
            teamsInGroup[3].groupStagePoints === teamsInGroup[0].groupStagePoints //tied for first, second and third
        )
            isLeader = true;
        if (isLeader) theReturnColor = settings.colors.groupColors.find((m) => m.groupID === entityGroupID).groupColor;
    }
    return theReturnColor;
}

function getGroupTopTwoLayerColor(entityNeedingColorID) {
    let theReturnColor = settings.colors.notInTournyColor;
    let entityGroupID = manageData.getEntityGroupID(entityNeedingColorID);
    if (entityGroupID !== 'Z') {
        let teamsInGroup = settings.globals.currentTeams.filter((o) => o.groupID === entityGroupID); // not error checking here because this would be caught on start up
        teamsInGroup.sort((a, b) => (a.groupStagePoints < b.groupStagePoints ? 1 : -1));
        let isLeader = false;
        if (teamsInGroup[0].ID === entityNeedingColorID || teamsInGroup[1].ID === entityNeedingColorID) isLeader = true;
        else if (
            teamsInGroup[2].ID === entityNeedingColorID &&
            teamsInGroup[2].groupStagePoints === teamsInGroup[1].groupStagePoints //tied for second
        )
            isLeader = true;
        else if (
            teamsInGroup[3].ID === entityNeedingColorID &&
            teamsInGroup[3].groupStagePoints === teamsInGroup[1].groupStagePoints //tied for second and tied for third
        )
            isLeader = true;
        if (isLeader) theReturnColor = settings.colors.groupColors.find((m) => m.groupID === entityGroupID).groupColor;
    }
    return theReturnColor;
}

function getGroupLayerColor(entityNeedingColorID) {
    let theReturnColor = settings.colors.notInTournyColor;
    let entityGroupID = manageData.getEntityGroupID(entityNeedingColorID);
    if (entityGroupID !== 'Z') {
        theReturnColor = settings.colors.groupColors.find((m) => m.groupID === entityGroupID).groupColor;
    }
    return theReturnColor;
}

function getGroupLayerOpacity(entityNeedingOpacityID, baseEntityID) {
    //always using full opacity
    let returnOpacity = settings.misc.groupFadedOpacity;
    if (manageData.getEntityGroupID(baseEntityID) === manageData.getEntityGroupID(entityNeedingOpacityID)) {
        returnOpacity = 1;
    } else if (manageData.getEntityGroupID(entityNeedingOpacityID) === 'Z') {
        returnOpacity = 1;
    }
    return returnOpacity;
}

function getConfederationColor(whichColor, whichConfederationID) {
    let theReturnColor = settings.colors.errorColor;
    let theConfed = settings.colors.confederationColors.find((z) => z.confederationID === whichConfederationID);
    if (theConfed === undefined) {
        utilities.myErrorReport(
            'location 230 ' + 'undefined confederation for ' + whichConfederationID,
            settings.misc.majorError
        );
    } else {
        theReturnColor = theConfed[whichColor];
    }
    return theReturnColor;
}

/**
 * Please describe the following function here
 * @param theEntityToBeFilled
 * @returns {string}
 */
function getQualifyingLayerColor(theEntityToBeFilledID) {
    let theColorToReturn = settings.colors.nonFIFAEntityColor; // use black to show problems
    let theConfed = manageData.getConfederationOfEntity(theEntityToBeFilledID);
    if (theConfed === undefined) {
        utilities.myErrorReport(
            'location 250 ' + 'error confederation undefined for entity: ' + theEntityToBeFilledID,
            settings.misc.majorError
        );
    } else if (!manageData.chkConfedInTourny(theConfed.confederationID)) {
        theColorToReturn = settings.colors.notInTournyColor;
    } else {
        if ('Z' === manageData.getEntityGroupID(theEntityToBeFilledID)) {
            theColorToReturn = getConfederationColor('basicColor', theConfed.confederationID);
        } else {
            theColorToReturn = getConfederationColor('standOutColor', theConfed.confederationID);
        }
    }
    return theColorToReturn;
}

/**
 * Please describe the following function here
 * @param match
 * @param entityID
 * @returns {string}
 */
function colorTheMatch(match, entityID) {
    if (!match.matchCompleted)
        //they are waiting to play
        return settings.colors.haventPlayedYetColor;
    else if (match.teamOneScore === match.teamTwoScore) {
        //a tie
        return settings.colors.tieColor;
    } else if (
        (match.teamOneID === entityID && match.teamOneScore > match.teamTwoScore) ||
        (match.teamTwoID === entityID && match.teamTwoScore > match.teamOneScore)
    ) {
        return settings.colors.winColor; //  when non clicked entity won
    } else {
        return settings.colors.loseColor; // when non clicked entity lost
    }
}

/**
 * Please describe the following function here
 * @param entityClickedID
 * @param entityToBeColoredID
 * @returns {string}
 */
function getDashboardLayerColor(entityToBeColoredID, entityClickedID, completedOnly) {
    if (undefined === settings.globals.currentTeams.find((o) => o.ID === entityToBeColoredID)) {
        //entity to be colored not found in tourny
        return settings.colors.notInTournyColor;
    } else if (entityClickedID === entityToBeColoredID) {
        // this is the entity of interest
        return settings.colors.generalHighlightFillColor;
    } else {
        // find the two countries of interest  CAREFUL HERE, THE TEAMS COULD MEET TWICE !!! (handled below by using the most recent result)
        let theMatchesFound = null;
        if (!completedOnly) {
            theMatchesFound = settings.globals.currentMatches.filter(
                (o) =>
                    (o.teamOneID === entityClickedID && entityToBeColoredID === o.teamTwoID) ||
                    (o.teamOneID === entityToBeColoredID && entityClickedID === o.teamTwoID)
            );
        } else {
            theMatchesFound = settings.globals.currentMatches.filter(
                (o) =>
                    ((o.teamOneID === entityClickedID && entityToBeColoredID === o.teamTwoID) ||
                        (o.teamOneID === entityToBeColoredID && entityClickedID === o.teamTwoID)) &&
                    o.matchCompleted
            );
        }
        if (theMatchesFound === undefined) {
            //this should only happen on start up when the user hasn't clicked yet
            return settings.colors.inTournyColor;
        } else {
            if (theMatchesFound.length === 0) {
                // the entities will not or did not play one another
                return settings.colors.inTournyColor;
            } else if (theMatchesFound.length === 1) return colorTheMatch(theMatchesFound[0], entityToBeColoredID);
            else {
                //they played
                //they did or will meet more than once, use the final result to color, as of 2020 this can only happen if they meet in the first and last rounds
                //need to do a quick sort to get the most recent game to be the one that is used to choose the color
                let tempArray = [];
                for (let j = 0; j < theMatchesFound.length; j++) {
                    let theMonth = settings.months.find((z) => z.number === theMatchesFound[j].month);
                    tempArray.push([theMatchesFound[j].year, theMonth.number, theMatchesFound[j].day, j]);
                }
                tempArray.sort((a, b) => utilities.mySort(b, a)); //largest to smallest sort
                return colorTheMatch(theMatchesFound[tempArray[0][3]], entityToBeColoredID);
            }
        }
    }
}

/**
 * Sets the colors for the three games of the group stage.
 * If they game is scheduled but has been played yet, the color is blue.
 * @param stageLayer
 * @param entityToBeFilled
 * @returns {color}
 */
function getColorsForPreliminaryRound(entityToBeFilled, stageLayer) {
    let returnColor = settings.colors.notInTournyColor;
    let continueOn = true;
    let theMatch = '';
    let roundOfInterest = 0;
    if (stageLayer === settings.layerIndices.groupStageRoundOneLayerIndex) roundOfInterest = 1.1;
    else if (stageLayer === settings.layerIndices.groupStageRoundTwoLayerIndex) roundOfInterest = 1.2;
    else roundOfInterest = 1.3;
    if ('Z' === manageData.getEntityGroupID(entityToBeFilled)) {
        // not in tourny
        continueOn = false;
    }
    if (continueOn) {
        theMatch = settings.globals.currentMatches.find(
            (o) => o.stage === roundOfInterest && (o.teamOneID === entityToBeFilled || o.teamTwoID === entityToBeFilled)
        );
        if (theMatch === undefined) {
            utilities.myErrorReport(
                'location 261 ' +
                    'match not found when drawing color by round for: ' +
                    roundOfInterest +
                    ' ' +
                    entityToBeFilled,
                settings.misc.dataNotFoundOK
            );
            continueOn = false;
        }
    }
    if (continueOn) {
        if (!theMatch.matchCompleted) returnColor = settings.colors.haventPlayedYetColor;
        else if (theMatch.teamOneScore === theMatch.teamTwoScore) {
            //they tied
            returnColor = settings.colors.tieColor;
        } else if (theMatch.teamOneID === entityToBeFilled) {
            if (theMatch.teamOneScore > theMatch.teamTwoScore) {
                returnColor = settings.colors.winColor;
            } else {
                returnColor = settings.colors.loseColor;
            }
        } else {
            if (theMatch.teamTwoScore > theMatch.teamOneScore) {
                returnColor = settings.colors.winColor;
            } else {
                returnColor = settings.colors.loseColor;
            }
        }
    }
    return returnColor;
}

/**
 * Since most of the major steps of the tourny are the same (r16, r8, r4, etc), this function gets the correct color for each
 * entity.  It basically looks at the data and sees if the entity progressed further than the level of interest.  If so, they must have won
 * and the color should be green.  If not, the color should be red (for loss).  There are no ties in these stages.
 * If they game is scheduled but has not been played yet, the color is blue.
 * @param roundOfInterest
 * @param entityToBeFilled
 * @returns {color}
 */
function getColorsForRound(entityToBeFilled, stageLayer) {
    let roundOfInterest = stageLayer - settings.layerIndices.r32LayerIndex + 1; // r32 is round 1, r16 is round 2 and so on
    if ('Z' === manageData.getEntityGroupID(entityToBeFilled)) {
        // not in tourny
        return settings.colors.notInTournyColor;
    } else {
        let theTeam = settings.globals.currentTeams.find((o) => o.ID === entityToBeFilled);
        if (theTeam === undefined) {
            utilities.myErrorReport(
                'location 260 ' + 'team not found when drawing color by round for: ' + entityToBeFilled,
                settings.misc.dataNotFoundOK
            );
        } else if (roundOfInterest + 0.5 === theTeam.numberOfRoundsPlayed) {
            //.5 indicates that the game hasn't been played yet
            return settings.colors.haventPlayedYetColor;
        } else if (roundOfInterest < theTeam.numberOfRoundsPlayed) {
            //means the team won in the round of interest
            return settings.colors.winColor;
        } else {
            switch (
                theTeam.numberOfRoundsPlayed //the following set up allows for a range of colors to be used
            ) {
                case roundOfInterest:
                    return settings.colors.loseColor; //lost in round of interest
                case roundOfInterest - 1:
                    return settings.colors.inTournyColor; // lost in previous roundloseColor
                case roundOfInterest - 2:
                    return settings.colors.inTournyColor; // lost in previous roundloseColor
                case roundOfInterest - 3:
                    return settings.colors.inTournyColor; // lost in previous roundloseColor
                default:
                    return settings.colors.inTournyColor; // lost in previous roundloseColor
            }
        }
    }
}

function getTalentFlowByConfederationColor(entityToBeColoredID) {
    let theConfederation = manageData.getConfederationOfEntity(entityToBeColoredID);
    if (theConfederation === undefined) {
        utilities.myErrorReport('location 270 ' + 'confederation not found: ', entityToBeColoredID);
        return settings.colors.notInTournyColor;
    } else {
        return getConfederationColor('standOutColor', theConfederation.confederationID);
    }
}

/**
 * Sets the color of the entity based on how many players play for clubs in that entity.
 * @param entityClickedID
 * @param entityToBeColoredID
 * @returns {string}
 */
function getTalentFlowToCountryLayerColor(entityToBeColoredID, entityClickedID) {
    if (entityToBeColoredID === entityClickedID) return settings.colors.generalHighlightFillColor;
    else {
        let theCount = manageData.countPlayersOnClubsInEntityFromOtherEntities(entityToBeColoredID);
        if (theCount === 0) return settings.colors.notInTournyColor;
        else if (theCount < settings.misc.talentFlowToCountryLevelOneThreshold)
            return settings.colors.lowLevelChoroplethColor;
        else if (theCount < settings.misc.talentFlowToCountryLevelTwoThreshold)
            return settings.colors.medLevelChoroplethColor;
        else return settings.colors.highLevelChoroplethColor;
    }
}

function getRankingsLayerColor(entityToBeColoredID) {
    //add back error report
    let theReturnColor = settings.colors.errorColor;
    if ('Z' === manageData.getEntityGroupID(entityToBeColoredID)) return settings.colors.notInTournyColor;
    else {
        let theRankIndex = manageData.getTournyRankingIndex(entityToBeColoredID);
        if (theRankIndex === undefined) {
            // utilities.myErrorReport('location 290 ' + 'could not find rank for ' + entityToBeColoredID,settings.misc.dataNotFoundO);
        } else if (theRankIndex <= 6) theReturnColor = settings.colors.oneLevelChoroplethColor;
        else if (theRankIndex <= 10) theReturnColor = settings.colors.medLevelChoroplethColor;
        else theReturnColor = settings.colors.lowLevelChoroplethColor;
    }
    return theReturnColor;
}

/*
 * @param entityToBeColoredID
 * @returns {color}
 * The country should be different level of choropleth depending on the number of fans.
 */
function getFanLayerColor(entityToBeColoredID) {
    let matchingCities = settings.fans.filter((v) => v.entityID === entityToBeColoredID);
    let totalFansInEntity = 0;
    if (matchingCities === undefined) {
        utilities.myErrorReport(
            'location 300 ' + 'no match found for: ' + entityToBeColoredID,
            settings.misc.majorError
        );
        return settings.colors.notInTournyColor;
    } else {
        for (let city of matchingCities) {
            totalFansInEntity = totalFansInEntity + city.numOfFans;
        }
        if (totalFansInEntity === 0) {
            return settings.colors.notInTournyColor;
        } else if (totalFansInEntity < 300) {
            return settings.colors.lowLevelChoroplethColor;
        } else if (totalFansInEntity < 600) {
            return settings.colors.medLevelChoroplethColor;
        } else return settings.colors.oneLevelChoroplethColor;
    }
}

function getTalentFlowFromCountryLayerColor(entityToBeColoredID, entityClickedID) {
    if (entityClickedID === entityToBeColoredID) return settings.colors.generalHighlightFillColor;
    else if ('Z' === manageData.getEntityGroupID(entityToBeColoredID)) return settings.colors.notInTournyColor;
    else {
        let theCount = manageData.countPlayersOnClubsInOtherEntities(entityToBeColoredID);
        if (theCount < settings.misc.talentFlowFromCountryLevelOneThreshold) {
            return settings.colors.lowLevelChoroplethColor;
        } else if (theCount <= settings.misc.talentFlowFromCountryLevelTwoThreshold) {
            return settings.colors.medLevelChoroplethColor;
        } else return settings.colors.highLevelChoroplethColor;
    }
}

export {
    highlightFeature,
    unHighlightFeature,
    getDashboardLayerColor,
    getTalentFlowByConfederationColor,
    getGroupLayerColor,
    getGroupLayerOpacity,
    getGroupLeadersLayerColor,
    getGroupTopTwoLayerColor,
    getFanLayerColor,
    getRankingsLayerColor,
    getSolidColor,
    getColorsForRound,
    getQualifyingLayerColor,
    getTalentFlowFromCountryLayerColor,
    getTalentFlowToCountryLayerColor,
    getColorsForPreliminaryRound,
    getNoColorNeeded,
    getMWCVideosLayerColor,
    getWWCVideosLayerColor,
    getMEuroVideosLayerColor,
    getGreatMPlayerVideosLayerColor,
    getGreatWPlayerVideosLayerColor,
    getBasicLayerColor,
};
