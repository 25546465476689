import * as manageLayerEvents from './manageLayerEvents';
import * as manageLayerColors from './manageLayerColors';
import * as manageLayerText from './manageLayerText';
import * as manageData from './manageData';
import * as rbush from 'rbush';
import * as manageLegends from './manageLegends';
import * as manageLabels from './manageLabels';
import * as manageSliders from './manageSliders';

// fix to globally set rbush (not an es6 module)
global.rbush = rbush;

import 'leaflet-swoopy';
import 'leaflet/dist/leaflet';
import * as utilities from './utilities';
import * as htmlBuilder from './htmlBuilder';
import 'Leaflet.LayerGroup.Collision';
import './leaflet.markercluster-src';

function createLayers() {
    settings.globals.layers = [];

    settings.globals.map.createPane('paneInFront');
    settings.globals.map.getPane('paneInFront').style.zIndex = settings.misc.zOfTopLayer - 10;
    settings.globals.map.createPane('popUpPane');
    settings.globals.map.getPane('popUpPane').style.zIndex = settings.misc.zOfTopLayer;

    for (let theLayerIndex = 1; theLayerIndex <= settings.layerIndices.lastMainLayerIndex; theLayerIndex++) {
        settings.globals.layers[theLayerIndex] = L.geoJSON(settings.polygons, {
            style: function (theFeature) {
                return {
                    fillColor: settings.globals.layerParameters[theLayerIndex].colorFunc(theFeature.id, theLayerIndex),
                    fillOpacity: settings.misc.generalFillOpacity,
                    color: settings.colors.generalLineColor, // Lines in between countries.
                    weight: settings.misc.generalLineWeight,
                };
            },
            onEachFeature: settings.globals.layerParameters[theLayerIndex].onEachFunc,
        });
        if (settings.globals.layerParameters[theLayerIndex].textFunc) {
            settings.globals.layers[theLayerIndex].bindPopup(function (theLayer) {
                return settings.globals.layerParameters[theLayerIndex].textFunc(theLayer.feature.id, theLayerIndex);
            }, settings.generalPopUpStyle);
        }
    }

    manageSliders.buildSliders();
    manageLegends.buildLegends();
    manageLabels.buildLabels();

    settings.globals.layers[settings.layerIndices.basicLabelsLayerIndex].addTo(settings.globals.map); //adds default labels to map

    //new layer for bracket Image (not the layer itself, just the image)
    settings.globals.layers[settings.layerIndices.bracketImageLayerIndex] = new L.imageOverlay(
        settings.globals.currentTourny.bracketImageURL,
        manageData.getRegionBounds(settings.layerIndices.bracketImageLayerIndex),
        {
            pane: 'paneInFront',
        }
    );

    doNewFromCountryMarkers(settings.globals.currentTourny.startEntityID); //start with some markers showing
    doNewToCountryMarkers(settings.globals.currentTourny.startEntityID);
    doNewTalentFlowFromConfederationMarkers(settings.misc.startFromConfedID); //start with some flow lines showing
    doNewTalentFlowToConfederationMarkers(settings.misc.startToConfedID);
    if (settings.globals.currentTourny.hasLeagueLabels) {
        let clusterGroupTwo = L.markerClusterGroup(settings.clusterOptions);
        settings.globals.layers[settings.layerIndices.leagueScheduleLabelsLayerIndex] = manageLabels.buildLeagueLabels(
            clusterGroupTwo,
            settings.globals.layerParameters[settings.layerIndices.leagueScheduleLabelsLayerIndex],
            null,
            null
        ); //the baseID is okay to be null. the round is irrelevant

        let clusterGroupThree = L.markerClusterGroup(settings.clusterOptions);
        settings.globals.layers[settings.layerIndices.leagueDashboardLabelsLayerIndex] = manageLabels.buildLeagueLabels(
            clusterGroupThree,
            settings.globals.layerParameters[settings.layerIndices.leagueDashboardLabelsLayerIndex],
            null,
            null
        ); // the baseID is okay to be null.the round is irrelevant

        let clusterGroupFour = L.markerClusterGroup(settings.clusterOptions);
        let theMatches = utilities.sortMatchesChrono(settings.globals.currentMatches);
        settings.globals.layers[settings.layerIndices.leagueResultsLabelsLayerIndex] = manageLabels.buildLeagueLabels(
            clusterGroupFour,
            settings.globals.layerParameters[settings.layerIndices.leagueResultsLabelsLayerIndex],
            null,
            theMatches[0].playDateRoundID //the baseID is okay to be null. //start by showing results of first game
        );
    }
    settings.globals.map.fitBounds(settings.map.regionBounds.worldBounds); // this extra zoom solves a redraw problem with the clusters
}

function doGroupsLayer(e) {
    settings.globals.layers[settings.layerIndices.groupsLayerIndex].eachLayer(function (layer) {
        layer.setStyle({ fillOpacity: manageLayerColors.getGroupLayerOpacity(layer.feature.id, e.target.feature.id) });
    });
}

/**
 * @param e
 * When a country gets clicked, must find the players and display them in the left side bar.
 */
function doDashboardLayer(e) {
    settings.globals.layers[settings.layerIndices.dashboardLayerIndex].eachLayer(function (layer) {
        layer.setStyle({
            fillColor: settings.globals.layerParameters[settings.layerIndices.dashboardLayerIndex].colorFunc(
                layer.feature.id,
                e.target.feature.id
            ),
        });
    });
    if ('Z' !== manageData.getEntityGroupID(e.target.feature.id)) {
        //if in the tourny, update the side bar
        settings.globals.currentTeamID = e.target.feature.id;
        settings.globals.layerParameters[settings.layerIndices.dashboardLayerIndex].leftBarFunction();
        htmlBuilder.updateLegendTitle(manageData.getProperEntityName(e.target.feature.id));
    }
}

function doWideDashboardLayer(e) {
    settings.globals.layers[settings.layerIndices.wideDashboardLayerIndex].eachLayer(function (layer) {
        layer.setStyle({
            fillColor: settings.globals.layerParameters[settings.layerIndices.wideDashboardLayerIndex].colorFunc(
                layer.feature.id,
                e.target.feature.id
            ),
        });
    });
    if ('Z' !== manageData.getEntityGroupID(e.target.feature.id)) {
        //there is no sidebar to update but the legend and current country still get updated
        settings.globals.currentTeamID = e.target.feature.id;
        htmlBuilder.updateLegendTitle(manageData.getProperEntityName(e.target.feature.id));
    }
}

/**
 * @param e
 * When a country gets clicked, must find the players and display them in the left side bar.
 */
function doTalentFlowFromCountry(e) {
    settings.globals.layers[settings.layerIndices.talentFlowFromCountryLayerIndex].eachLayer(function (layer) {
        layer.setStyle({
            fillColor: settings.globals.layerParameters[
                settings.layerIndices.talentFlowFromCountryLayerIndex
            ].colorFunc(layer.feature.id, e.target.feature.id), //country to be colored, country clicked
        });
    });
    settings.globals.map.removeLayer(settings.globals.layers[settings.layerIndices.fromCountryMarkersLayerIndex]);
    doNewFromCountryMarkers(e.target.feature.id);
    settings.globals.map.addLayer(settings.globals.layers[settings.layerIndices.fromCountryMarkersLayerIndex]);
    if ('Z' !== manageData.getEntityGroupID(e.target.feature.id)) {
        //if in the tourny}
        settings.globals.currentFromEntityID = e.target.feature.id;
        settings.globals.layerParameters[settings.layerIndices.talentFlowFromCountryLayerIndex].leftBarFunction();
        //htmlBuilder.buildSideBarPicsForCurrentFromCountry();
    }
}

/**
 * Please describe the following function here
 * @param e
 */
function doTalentFlowToCountry(e) {
    settings.globals.layers[settings.layerIndices.talentFlowToCountryLayerIndex].eachLayer(function (layer) {
        layer.setStyle({
            fillColor: settings.globals.layerParameters[settings.layerIndices.talentFlowToCountryLayerIndex].colorFunc(
                layer.feature.id,
                e.target.feature.id
            ), //country to be colored, country clicked
        });
    });
    settings.globals.map.removeLayer(
        //remove old markers
        settings.globals.layers[settings.layerIndices.toCountryMarkersLayerIndex]
    );
    doNewToCountryMarkers(e.target.feature.id);
    settings.globals.map.addLayer(
        //add new markers
        settings.globals.layers[settings.layerIndices.toCountryMarkersLayerIndex]
    );
}

function doTalentFlowFromConfederation(e) {
    settings.globals.layers[settings.layerIndices.talentFlowFromConfederationLayerIndex].eachLayer(function (layer) {
        layer.setStyle({
            fillColor: settings.globals.layerParameters[
                settings.layerIndices.talentFlowFromConfederationLayerIndex
            ].colorFunc(layer.feature.id),
        });
    });
    settings.globals.map.removeLayer(
        //remove old markers
        settings.globals.layers[settings.layerIndices.talentFlowFromConfederationMarkersLayerIndex]
    );
    doNewTalentFlowFromConfederationMarkers(e.target.feature.id);
    settings.globals.map.addLayer(
        //add new markers
        settings.globals.layers[settings.layerIndices.talentFlowFromConfederationMarkersLayerIndex]
    );
}

function doTalentFlowToConfederation(e) {
    settings.globals.layers[settings.layerIndices.talentFlowToConfederationLayerIndex].eachLayer(function (layer) {
        layer.setStyle({
            fillColor: settings.globals.layerParameters[
                settings.layerIndices.talentFlowToConfederationLayerIndex
            ].colorFunc(layer.feature.id),
        });
    });
    settings.globals.map.removeLayer(
        //remove old markers
        settings.globals.layers[settings.layerIndices.talentFlowToConfederationMarkersLayerIndex]
    );
    doNewTalentFlowToConfederationMarkers(e.target.feature.id);
    settings.globals.map.addLayer(
        //add new markers
        settings.globals.layers[settings.layerIndices.talentFlowToConfederationMarkersLayerIndex]
    );
}

function updateLeagueDashboardSideBarPics(theID) {
    settings.globals.currentTeamID = theID;
    settings.globals.layerParameters[settings.layerIndices.leagueDashboardLayerIndex].leftBarFunction();
}

function doNewTalentFlowToConfederationMarkers(baseEntityID) {
    let continueOn = true;
    let k = 0;
    let dotsAndSwoops = []; //actually no dots
    let theConfederation = manageData.getConfederationOfEntity(baseEntityID);
    if (theConfederation === undefined) {
        utilities.myErrorReport(
            'location 310 ' + 'error finding confederation for ' + baseEntityID,
            settings.misc.majorError
        );
        continueOn = false;
    }
    if (continueOn) {
        let enteringConfederationInfo = settings.globals.enteringConfederationSwoops.filter(
            (v) => v[0] == theConfederation.confederationID
        );
        k = 0;
        while (k < enteringConfederationInfo.length && continueOn) {
            let theFromEntity = settings.fifaEntities.find((m) => m.ID === enteringConfederationInfo[k][1]);
            let theToEntity = settings.fifaEntities.find((m) => m.ID === enteringConfederationInfo[k][2]);
            if (theFromEntity === undefined || theToEntity === undefined) {
                continueOn = false;
            }
            if (continueOn) {
                let theStyle = settings.swoopStyle;
                theStyle.color = settings.colors.talentFlowToArrrowColor;
                theStyle.hideArrowHead = false;
                dotsAndSwoops.push(
                    new L.swoopyArrow(
                        manageData.getEntityCentroid(theFromEntity.ID),
                        manageData.getEntityCentroid(theToEntity.ID),
                        //  [theFromEntity.latitude, theFromEntity.longitude],
                        //  [theToEntity.latitude, theToEntity.longitude],
                        theStyle
                    )
                );
            }
            k++;
        }
    }
    settings.globals.layers[settings.layerIndices.talentFlowToConfederationMarkersLayerIndex] = new L.layerGroup(
        dotsAndSwoops
    );
}

function doNewTalentFlowFromConfederationMarkers(baseEntityID) {
    let continueOn = true;
    let k = 0;
    let dotsAndSwoops = []; //actually no dots
    let theConfederation = manageData.getConfederationOfEntity(baseEntityID);
    if (theConfederation === undefined) {
        utilities.myErrorReport(
            'location 320 ' + 'error finding confederation for ' + baseEntityID,
            settings.misc.majorError
        );
        continueOn = false;
    }
    if (continueOn) {
        let leavingConfederationInfo = settings.globals.leavingConfederationSwoops.filter(
            (v) => v[0] == theConfederation.confederationID
        );
        while (k < leavingConfederationInfo.length && continueOn) {
            let theFromEntity = settings.fifaEntities.find((m) => m.ID === leavingConfederationInfo[k][1]);
            let theToEntity = settings.fifaEntities.find((m) => m.ID === leavingConfederationInfo[k][2]);
            if (theFromEntity === undefined || theToEntity === undefined) {
                continueOn = false;
            }
            if (continueOn) {
                let theStyle = settings.swoopStyle;
                theStyle.color = settings.colors.talentFlowFromArrowColor;
                theStyle.hideArrowHead = false;
                dotsAndSwoops.push(
                    new L.swoopyArrow(
                        manageData.getEntityCentroid(theFromEntity.ID),
                        manageData.getEntityCentroid(theToEntity.ID),
                        // [theFromEntity.latitude, theFromEntity.longitude],
                        // [theToEntity.latitude, theToEntity.longitude],
                        theStyle
                    )
                );
            }
            k++;
        }
    }
    settings.globals.layers[settings.layerIndices.talentFlowFromConfederationMarkersLayerIndex] = new L.layerGroup(
        dotsAndSwoops
    );
}

/**
 * Please describe the following function here
 * @param baseEntityID
 */

function doNewFanMarkers() {
    let marker = '';
    let dots = [];
    for (let theCity of settings.fans) {
        marker = new L.circleMarker([theCity.latitude, theCity.longitude], settings.circleMarkerStyle);
        marker.setRadius(1.5 * Math.round(theCity.numOfFans / 100));
        marker.bindPopup(
            manageLayerText.getFanMarkersText(theCity.entityID, theCity.cityID),
            settings.generalPopUpStyle
        );
        dots.push(marker);
    }
    settings.globals.layers[settings.layerIndices.fanMarkersLayerIndex] = new L.layerGroup(dots);
}

function doNewFromCountryMarkers(baseEntityID) {
    // find all the players
    let entitiesWithMarkers = [];
    let dots = [];
    let swoops = [];
    let theSwoopStyle = settings.swoopStyle;
    theSwoopStyle.color = settings.colors.talentFlowFromArrowColor;
    theSwoopStyle.hideArrowHead = true;
    let playersFound = settings.globals.currentPlayersInTourny.filter((o) => o.playsForID === baseEntityID);
    if (playersFound === undefined) {
        //this should never happen
        utilities.myErrorReport(
            'location 330 ' + 'no playersInTourny found matching baseEntity: ' + baseEntityID,
            settings.misc.dataNotFoundOK
        );
    } else if (playersFound.length === 0) {
        //not an error just no players from that country
    } else {
        // get the entity for each player's club
        for (let k = 0; k < playersFound.length; k++) {
            let theClub = settings.globals.clubs.find((m) => m.clubID === playersFound[k].clubID);
            if (theClub === undefined) {
                // some players may not have a club (especially) women
            } else entitiesWithMarkers.push(theClub.clubLocationID); //add the entity id to the list
        }
        if (entitiesWithMarkers.length === 0) {
            //this should never happen
            utilities.myErrorReport(
                'location 360 ' + 'no club locations to mark for ' + baseEntityID,
                settings.misc.dataNotFoundOK
            );
        } else {
            entitiesWithMarkers.sort((a, b) => utilities.mySort(a, b));
            let circleRadius = settings.misc.radiusIncrement;
            let uniqueMarkers = [];
            let lastID = '';
            for (let m = 0; m < entitiesWithMarkers.length; m++) {
                if (lastID !== entitiesWithMarkers[m]) {
                    lastID = entitiesWithMarkers[m];
                    uniqueMarkers.push({
                        ID: lastID,
                        markerRadius: settings.misc.radiusIncrement,
                    });
                    circleRadius = settings.misc.radiusIncrement;
                } else {
                    circleRadius += settings.misc.radiusIncrement;
                    uniqueMarkers[uniqueMarkers.length - 1].markerRadius = circleRadius;
                }
            }
            let fifaBaseEntity = settings.fifaEntities.find((o) => o.ID === baseEntityID);
            if (fifaBaseEntity === undefined)
                //this should not happen
                utilities.myErrorReport(
                    'location 370 ' + 'undefined base ' + baseEntityID,
                    settings.misc.dataNotFoundOK
                );
            else if (fifaBaseEntity.latitude === null || fifaBaseEntity.longitude === null)
                utilities.myErrorReport(
                    'location 380 ' + 'lat or long for base: ' + baseEntityID + ' is null',
                    settings.misc.dataNotFoundOK
                );
            else
                for (let q = 0; q < uniqueMarkers.length; q++) {
                    let fifaEntity = settings.fifaEntities.find((o) => o.ID === uniqueMarkers[q].ID);
                    if (fifaEntity === undefined) {
                        //this shouldn't happen
                        utilities.myErrorReport(
                            'location 390 ' +
                                "no fifa entity found matching a players' club location: " +
                                uniqueMarkers[q].ID,
                            settings.misc.dataNotFoundOK
                        );
                    } else if (fifaEntity.latitude === null || fifaEntity.longitude === null)
                        utilities.myErrorReport(
                            'location 40 ' + 'lat or long for base: ',
                            fifaEntity.ID,
                            ' is null',
                            settings.misc.dataNotFoundOK
                        );
                    else {
                        let marker = new L.circleMarker(
                            manageData.getEntityCentroid(fifaEntity.ID),
                            //  [fifaEntity.latitude, fifaEntity.longitude],
                            settings.circleMarkerStyle
                        );
                        marker.setRadius(Math.round(Math.sqrt(uniqueMarkers[q].markerRadius))); //make area proportional to number not radius proportional to number of players
                        marker.bindPopup(function () {
                            htmlBuilder.addPicsGroupActiveClass(baseEntityID, fifaEntity.ID);
                            return manageLayerText.getFromCountryMarkersText(baseEntityID, fifaEntity.ID);
                        }, settings.generalPopUpStyle);
                        dots.push(marker);
                        if (fifaEntity.ID !== fifaBaseEntity.ID) {
                            //don't want a swoop from an entity back to itself
                            swoops.push(
                                new L.swoopyArrow(
                                    [fifaBaseEntity.latitude, fifaBaseEntity.longitude],
                                    [fifaEntity.latitude, fifaEntity.longitude],
                                    theSwoopStyle
                                )
                            );
                        }
                    }
                }
        }
    }
    let dotsAndSwoops = swoops.concat(dots);
    settings.globals.layers[settings.layerIndices.fromCountryMarkersLayerIndex] = new L.layerGroup(dotsAndSwoops);
}

/**
 * Please describe the following function here
 * @param baseEntityID
 */

function doNewToCountryMarkers(baseEntityID) {
    // find all the players
    let playersInEntity = [];
    let dots = [];
    let swoops = [];
    let theSwoopStyle = settings.swoopStyle;
    theSwoopStyle.color = settings.colors.talentFlowToArrrowColor;
    theSwoopStyle.hideArrowHead = false;
    let theClubs = settings.globals.clubs.filter((p) => p.clubLocationID === baseEntityID);
    if (theClubs === undefined) {
        utilities.myErrorReport(
            'location 409 ' + 'undefined when looking for a club in ' + baseEntityID,
            settings.misc.majorError
        );
    } else {
        let thePlayers = '';
        for (let k = 0; k < theClubs.length; k++) {
            // for each club find the players in the current Tourny
            thePlayers = settings.globals.currentPlayersInTourny.filter((m) => m.clubID === theClubs[k].clubID);
            if (thePlayers === undefined) {
                utilities.myErrorReport(
                    'location 410 ' + 'undefined when searching for players in club: ' + theClubs[k.clubID],
                    settings.misc.majorError
                );
            } else {
                for (let aPlayer of thePlayers) {
                    playersInEntity.push([theClubs[k].clubID, aPlayer.playsForID, aPlayer.playerID]); //  id of his club, entity where he plays for tourny, and his player id
                }
            }
        }
        if (playersInEntity.length === 0) {
            //this could happen, no clubs with players in the clicked country
        } else {
            playersInEntity.sort((a, b) => utilities.mySort(a, b));
            let circleRadius = settings.misc.radiusIncrement;
            let lastID = '';
            let uniqueMarkers = [];
            for (let m = 0; m < playersInEntity.length; m++) {
                if (lastID !== playersInEntity[m][1]) {
                    lastID = playersInEntity[m][1];
                    uniqueMarkers.push({
                        ID: lastID,
                        markerRadius: settings.misc.radiusIncrement,
                    });
                    circleRadius = settings.misc.radiusIncrement;
                } else {
                    uniqueMarkers[uniqueMarkers.length - 1].markerRadius = circleRadius;
                }
                circleRadius += settings.misc.radiusIncrement;
            }
            let fifaBaseEntity = settings.fifaEntities.find((o) => o.ID === baseEntityID);
            if (fifaBaseEntity === undefined)
                utilities.myErrorReport(
                    'location 420 ' + 'searching for fifa entity yielded undefined: ' + baseEntityID,
                    settings.misc.majorError
                );
            else {
                for (let q = 0; q < uniqueMarkers.length; q++) {
                    let fifaEntity = settings.fifaEntities.find((o) => o.ID === uniqueMarkers[q].ID);
                    if (fifaEntity === undefined) {
                        //this shouldn't happen
                        utilities.myErrorReport(
                            'location 430 ' +
                                "no fifa entity found matching a players' club location: " +
                                uniqueMarkers[q].ID,
                            settings.misc.dataNotFoundOK
                        );
                    } else if (fifaEntity.latitude === null || fifaEntity.longitude === null)
                        utilities.myErrorReport(
                            'location 440 ' + 'lat or long for base: ',
                            fifaEntity.ID,
                            ' is null',
                            settings.misc.dataNotFoundOK
                        );
                    else {
                        let tempMarker = new L.circleMarker(
                            manageData.getEntityCentroid(fifaEntity.ID),
                            // [fifaEntity.latitude, fifaEntity.longitude],
                            settings.circleMarkerStyle
                        );
                        tempMarker.setRadius(Math.round(Math.sqrt(uniqueMarkers[q].markerRadius))); //this makes the area proportional to number (instead of radius proportional to number)
                        tempMarker.bindPopup(
                            manageLayerText.getToCountryMarkersText(baseEntityID, fifaEntity.ID),
                            settings.generalPopUpStyle
                        );
                        dots.push(tempMarker);
                        if (fifaEntity.ID !== baseEntityID) {
                            swoops.push(
                                new L.swoopyArrow(
                                    [fifaEntity.latitude, fifaEntity.longitude],
                                    [fifaBaseEntity.latitude, fifaBaseEntity.longitude],
                                    theSwoopStyle
                                )
                            );
                        }
                    }
                }
            }
        }
    }
    let dotsAndSwoops = swoops.concat(dots);
    settings.globals.layers[settings.layerIndices.toCountryMarkersLayerIndex] = new L.layerGroup(dotsAndSwoops);
}
/**
 * Please describe the following function here
 */

export {
    createLayers,
    doDashboardLayer,
    doWideDashboardLayer,
    doGroupsLayer,
    doTalentFlowFromCountry,
    doTalentFlowToCountry,
    doTalentFlowFromConfederation,
    doTalentFlowToConfederation,
    updateLeagueDashboardSideBarPics,
};
